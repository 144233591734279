import React from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { useTranslate, useListContext, List, Edit, Datagrid, SimpleForm, TextField,
  EditButton, TextInput, DateInput, Filter }  from 'react-admin';
import BulkActionButtons from '../../lib/BulkActionButtons';
import BooleanRedField from '../../fields/BooleanRedField';
import CloseToolbar from '../../lib/CloseToolbar';
import SpecialdayCopy from '../../lib/set/SpecialdayCopy';

const CEForm = ({ classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
        <TextField source="datum" />
        <TextInput source="bemerkung" />
      {bwh_sprachen.map((sp)=>(
        <TextInput multiline fullWidth source={"text_" + sp} label={translate("bw.set.specialday.text") + ' ' + sp} />
      ))}
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <DateInput source="datum" alwaysOn label="bw.set.specialday.searchDate" />
        <TextInput source="bemerkung" alwaysOn label="bw.set.specialday.searchDesc" resettable/>
    </Filter>
);

const DayGrid = () => {
  const { setFilters, total } = useListContext();
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  
  if (total===-1) {
    setFilters({datum: new Date().toISOString().substr(0,10)});
  }
  
  return (
    <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons />} >
      <TextField source="datum" />
      <TextField source="bemerkung" />
      {bwh_sprachen.map((sp)=>(<BooleanRedField label={sp.toUpperCase()} source={"complete_" + sp} sortable={false} key={sp} />))}
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
      <EditButton label="" />
      <SpecialdayCopy />
    </Datagrid>
  )
}

const ListForm = (props) => {
  const {...rest} = props;
  return (
    <List { ...rest} title="bw.set.specialday.title" filters={<ListFilter />} sort={{ field: 'datum', order: 'DESC' }} exporter={isHauptadmin() && props.exporter} >
      <DayGrid />
    </List>
  )
}
const Specialday = {
  list: ({ classes, ...props }) => (
    <ListForm classes={classes} {...props} />
  ),

  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.specialday.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Specialday