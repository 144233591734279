import React, { Component } from 'react';
import { getGlobalData, isHauptadmin, textileConvert, splitText } from '../../lib/globalData';
import { apiPath } from '../../lib/isTest';
import { useTranslate, useRecordContext, List, Filter, Create, Edit, Datagrid, SimpleForm, FormDataConsumer, 
  TextField, ReferenceField , EditButton, SelectInput, NumberInput, 
  TextInput, AutocompleteInput, ReferenceInput } from 'react-admin';
import BooleanRedField from '../../fields/BooleanRedField';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import TranslateField from '../../fields/TranslateField';
import ConditionalDeleteButton from '../../fields/ConditionalDeleteButton';
import Grid from '@mui/material/Grid';
  
const artEnumFilter = [
  'bwh.hostel.bedingung1',
  'bwh.hostel.bedingung2',
  'bwh.hostel.bedingung3',
  'bwh.hostel.zahlunggelesen',
  'bwh.hostel.zahlunggeleseninfo',
  'bwh.hostel.confirmtext',
  'bwh.hostel.showbookingtext',
  'bwh.hostel.confirminfotext',
  'bwh.hostel.cancelreservationtext',
  'bwh.hostel.privacy',
  'bwh.hostel.contact',
  'bwh.hostel.resultfound',
  'bwh.hostel.noresultfound',
  'bwh.hostel.arrivaltimeoptions',
  'bwh.hostel.booknow',
  'bwh.hostel.booknowweiter',
  'bwh.hostel.booknowfinish',
];

const artEnumCreate = [
  'bwh.hostel.confirminfotext',
  'bwh.hostel.zahlunggeleseninfo',
];

const postDefaultValue = { agent_id: 0, id_element: 1 };
  
const PostPanel = ({ id, record, resource }) => (
    <div dangerouslySetInnerHTML={{ __html: '<img src="/preview/'+record.art+'.jpg" style="float:right;" />' }} />
);
  
const asyncValidate = async (values, dispatch, props, field) => {
  return new Promise(async (resolve, reject) => {
    if(typeof values.id_element === 'undefined') return resolve()
    var token = getGlobalData('token');
    const res = await fetch(apiPath+'v1/bwhText/verify', {
      method: 'PUT',
      body: JSON.stringify({ values: values }),
      headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
    })
    const json = await res.json();
    if (json.ok) return resolve()
    resolve(json.errors)

    resolve({})
  })
}

const TextileDisplayInput = ({formData, source, ...rest}) => {
  if (!formData){
    return null;
  }
  var t = splitText(formData[source]);
  t = '<div style="font-size:1.45em;">'+textileConvert(t.titel) + '</div>' + textileConvert(t.text);
    return (
    <div style={{padding:'1em', backgroundColor:'#F5F5F5'}}>
    <div dangerouslySetInnerHTML={{ __html: t }} />
    </div>
    )
};

const Aside = ({...rest }) => {
  const translate = useTranslate();
  var val = '';
  var description = '<br><div><h2 style="margin-bottom: 0.5em;">'+translate('bw.bwh.text.formatBlockTitle')+'</h2>';
  description += '<h3 style="margin-top:0.5em; margin-bottom: 0em;">'+translate('bw.bwh.text.formatTitle')+'</h3>';
  description += translate('bw.bwh.text.formatBold');
  description += '</br>'+translate('bw.bwh.text.formatItalic');
  description += '</br>'+translate('bw.bwh.text.formatHeading');
  description += '</br>'+translate('bw.bwh.text.formatSubheading');
  description += '<h3 style="margin-top:0.5em; margin-bottom: 0em;">'+translate('bw.bwh.text.ulTitle')+'</h3>';
  description += translate('bw.bwh.text.ulOne');
  description += '</br>'+translate('bw.bwh.text.ulTwo');
  description += '</br>'+translate('bw.bwh.text.ulThree');
  description += '<h3 style="margin-top:0.5em; margin-bottom: 0em;">'+translate('bw.bwh.text.olTitle')+'</h3>';
  description += translate('bw.bwh.text.olOne');
  description += '</br>'+translate('bw.bwh.text.olTwo');
  description += '</br>'+translate('bw.bwh.text.olThree');
  description += '</div>';
  if(typeof rest.record !== 'undefined' && typeof rest.record.art !== 'undefined') val = rest.record.art;
  return (
    <div dangerouslySetInnerHTML={{ __html: '<img src="/preview/'+val+'.jpg" style="float:right;" />'+description }} />
)};

class ListForm extends Component {
  render() {
    const {...rest} = this.props;
    const bwh_sprachen = getGlobalData('info').bwh_sprachen;
    return (
      <List {...rest} title={('bw.bwh.text.title')} sort={{ field: 'art', order: 'ASC' }} 
        exporter={isHauptadmin() && this.props.exporter} pagination={<NoPagination />} perPage={1000} >
        <Datagrid style={{ width: 'auto' }} rowClick='expand' expand={<PostPanel />} bulkActionButtons={false} >
          <TranslateField source="art_lbl" sortable={false} />
          {bwh_sprachen.map((sp)=>(<BooleanRedField label={sp.toUpperCase()} source={"complete_" + sp} sortable={false} key={sp} />))}
          <TextField source="id_element" />
          <ReferenceField label="Agent" source="id_agent" reference="bwhTextagent" >
            <TextField source="firma" />
          </ReferenceField >
          {isHauptadmin() &&
            <TextField source="id" textAlign="right" />
          }
          <EditButton label="" />
          <ConditionalDeleteButton source="editable" label="" />
        </Datagrid>
      </List>
    )
  }
}

const CForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  const artList = artEnumCreate.map((v)=>{return{id:v,name:'bw.'+v}});
  return (
    <SimpleForm toolbar={<CloseToolbar />} validate={asyncValidate} defaultValues={postDefaultValue} {...props}> 
      <Grid container style={{width:'100%'}} >
        <Grid container style={{width:'70%'}} >
          <Grid item xs={12} md={1}>
            <p style={{marginTop:'1.1em', marginBottom:0}}>{translate('bw.bwh.text.art')}</p>
          </Grid>
          <Grid item xs={12} md={11}>
            <SelectInput source="art" choices={artList} label="" defaultValue="bwh.hostel.confirminfotext" />
          </Grid>
          <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => formData && (typeof formData.art != 'undefined' && (formData.art === 'bwh.hostel.confirminfotext'))?
            (
            <React.Fragment>
              <Grid item xs={12} md={1}>
                <p style={{marginTop:'1.1em', marginBottom:0}}>{translate('bw.bwh.text.agent')}</p>
              </Grid>
              <Grid item xs={12} md={11}>
                <ReferenceInput source="id_agent" reference="bwhTextagent" >
                  <SelectInput optionText="firma" label="" fullWidth />
                </ReferenceInput>
              </Grid>
            </React.Fragment>)
          :null}
          </FormDataConsumer>
          <Grid item xs={12} md={1}>
            <p style={{marginTop:'1.1em', marginBottom:0}}>{translate('bw.bwh.text.pos')}</p>
          </Grid>
          <Grid item xs={12} md={11}>
            <NumberInput source="id_element" label="" inputProps={{ min: 1, max: 10 }} />
          </Grid>
          {bwh_sprachen.map((sp)=>(
            <React.Fragment key={sp}>
            <Grid item xs={12} md={1}>
              <p style={{marginTop:'2.1em', marginBottom:0}}>{sp.toUpperCase()}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput multiline fullWidth source={"text_" + sp}  />
            </Grid>
            <Grid item xs={12} md={5}>
              <FormDataConsumer>
                {({ formData, ...props }) => (<TextileDisplayInput source={"text_" + sp} formData={formData} {...props} />)}
              </FormDataConsumer>
            </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Grid container style={{width:'30%'}} >
          <Grid item xs={12} md={12}>
            <FormDataConsumer>
              {({ formData, ...props }) => (<img src={"/preview/"+formData.art+".jpg"} alt="preview" />)}
            </FormDataConsumer>
            <br /><h2 style={{marginBottom: '0.5em'}}>{translate('bw.bwh.text.formatBlockTitle')}</h2>
            <h3 style={{marginTop: '0.5em', marginBottom: 0}}>{translate('bw.bwh.text.formatTitle')}</h3>
            <span>{translate('bw.bwh.text.formatBold')}
            <br />{translate('bw.bwh.text.formatItalic')}
            <br />{translate('bw.bwh.text.formatHeading')}
            <br />{translate('bw.bwh.text.formatSubheading')}</span>
            <h3 style={{marginTop: '0.5em', marginBottom: 0}}>{translate('bw.bwh.text.ulTitle')}</h3>
            <span>{translate('bw.bwh.text.ulOne')}
            <br />{translate('bw.bwh.text.ulTwo')}
            <br />{translate('bw.bwh.text.ulThree')}</span>
            <h3 style={{marginTop: '0.5em', marginBottom: 0}}>{translate('bw.bwh.text.olTitle')}</h3>
            <span>{translate('bw.bwh.text.olOne')}
            <br />{translate('bw.bwh.text.olTwo')}
            <br />{translate('bw.bwh.text.olThree')}</span>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  )
};

const EForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete={!record.editable} />} validate={asyncValidate} {...props}> 
      <TranslateField source="art_lbl" />
      <Grid container style={{width:'100%'}} spacing={2}>
        <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => formData && (typeof formData.art != 'undefined' && (formData.art === 'bwh.hostel.confirminfotext'))?
          (
          <React.Fragment>
            <Grid item xs={12} md={1}>
              <p style={{marginTop:'1.1em', marginBottom:0}}>{translate('bw.bwh.text.agent')}</p>
            </Grid>
            <Grid item xs={12} md={11}>
              <ReferenceInput source="id_agent" reference="bwhTextagent" >
                <SelectInput optionText="firma" label="" fullWidth />
              </ReferenceInput>
            </Grid>
          </React.Fragment>)
        :null}
        </FormDataConsumer>
        <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => formData && (typeof formData.art != 'undefined' && artEnumCreate.indexOf(formData.art)> -1)?
          (
          <React.Fragment>
            <Grid item xs={12} md={1}>
              <p style={{marginTop:'1.1em', marginBottom:0}}>{translate('bw.bwh.text.pos')}</p>
            </Grid>
            <Grid item xs={12} md={11}>
              <NumberInput source="id_element" label="" />
            </Grid>
          </React.Fragment>)
        :null}
        </FormDataConsumer>
        {bwh_sprachen.map((sp)=>(
          <React.Fragment key={sp}>
          <Grid item xs={12} md={1}>
            <p style={{marginTop:'2.1em', marginBottom:0}}>{sp.toUpperCase()}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput multiline fullWidth source={"text_" + sp}  />
          </Grid>
          <Grid item xs={12} md={5}>
            <FormDataConsumer>
              {({ formData, ...props }) => (<TextileDisplayInput source={"text_" + sp} formData={formData} {...props} />)}
            </FormDataConsumer>
          </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </SimpleForm>
  )
};

const ListFilter = (props) => {
  const artList = artEnumFilter.map((v)=>{return{id:v,name:'bw.'+v}});
  return (
  <Filter {...props} >
    <AutocompleteInput source="art" choices={artList} alwaysOn emptyText='All' />
  </Filter>
)};

const Text = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({ classes, ...props }) => (
    <Create title='bw.bwh.text.titleCreate' redirect="list" {...props}>
      <CForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit aside={<Aside {...props} />} title='bw.bwh.text.titleEdit' mutationMode="pessimistic" {...props}>
      <EForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Text 