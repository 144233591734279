import React, { useState } from 'react';
import { isHauptadmin, CheckRecht } from '../../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, SelectField,
        EditButton, TextInput, AutocompleteInput } from 'react-admin';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import ConditionalTextField from '../../fields/ConditionalTextField';
import ConditionalRichTextField from '../../fields/ConditionalRichTextField';
import MultilineComment from '../../fields/MultilineComment';

const deftypes=[
    { id:  0, name: 'glob.deftypes.one' },
    { id:  1, name: 'glob.deftypes.num_nights' },
    { id:  2, name: 'glob.deftypes.num_person' },
    { id:  3, name: 'glob.deftypes.num_night_person' },
    { id:  4, name: 'glob.deftypes.net_price' },
    { id:  5, name: 'glob.deftypes.price_per_night' },
  ];

const onlinetypes=[
    { id:  0, name: 'glob.onlinetypes.not_online' },
    { id:  1, name: 'glob.onlinetypes.checkbox' },
    { id:  2, name: 'glob.onlinetypes.num_selectable' },
    { id:  3, name: 'glob.onlinetypes.fix' },
  ];

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} pagination={<NoPagination />} perPage={1000} title="bw.set.service.title"  sort={{ field: 'pos', order: 'ASC' }} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <TextField source="pos" textAlign="right" />
        <Move getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setService" />
        <TextField source="bezeichnung" />
        <TextField source="kuerzel" label="" />
        <TextField source="def_anz" />
        <SelectField source="def_type" choices={deftypes} />
        <SelectField source="online_type" choices={onlinetypes} />
        <ConditionalRichTextField dispSource="anz_modifier_br" condition="has_modifier" dispLabel="bw.set.service.anz_modifier"/>
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, permissions, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}> 
      <TextInput source="bezeichnung" />
      <TextInput source="kuerzel" />
      <TextInput source="def_anz" />
      <AutocompleteInput source="def_type" choices={deftypes} />
      <AutocompleteInput source="online_type" choices={onlinetypes} />
      <CheckRecht route="setService" level={9}>
        <TextInput multiline source="anz_modifier" helperText={false} fullWidth />
        <MultilineComment text="bw.set.service.anz_modifier_comment" />
      </CheckRecht>
      <CheckRecht route="setService" level={9} invert>
        <ConditionalTextField multiline dispSource="anz_modifier" condition="anz_modifier" dispLabel="bw.set.service.anz_modifier"  />
      </CheckRecht>
    </SimpleForm>
  )
};

const Service ={
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.service.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.service.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Service
