import React from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, List, Filter, Edit, Datagrid, SimpleForm, 
  EditButton, TextField, SelectField, SelectInput, 
  TextInput } from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import Grid from '@mui/material/Grid';

const dispChoices = [
  { id: 1, name: 'bw.bwh.guestfield.noShow' },
  { id: 2, name: 'bw.bwh.guestfield.show' },
  { id: 3, name: 'bw.bwh.guestfield.req' },
  { id: 4, name: 'bw.bwh.guestfield.reqWoCc' },
];

const checkinChoices = [
  { id: 0, name: 'bw.bwh.guestfield.noCheckin' },
  { id: 1, name: 'bw.bwh.guestfield.mainOpt' },
  { id: 2, name: 'bw.bwh.guestfield.allOpt' },
//  { id: 3, name: 'bw.bwh.guestfield.mainReq' },
//  { id: 4, name: 'bw.bwh.guestfield.allReq' },
];
const checkinChoices2 = [
  { id: 0, name: 'bw.bwh.guestfield.noCheckin' },
  { id: 1, name: 'bw.bwh.guestfield.mainOpt' },
//  { id: 3, name: 'bw.bwh.guestfield.mainReq' },
];

const reqField = [ 1,2,3,16,17 ];

const ListForm = (props) => {
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <List {...props} title={('bw.bwh.guestfield.title')} exporter={isHauptadmin() && props.exporter} 
      filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }}  bulkActionButtons={false}>
        <TextField source="orig_text" />
        {bwh_sprachen.map((sp)=>(
            <TextField label={sp.toUpperCase()} source={"text_"+sp} sortable={false}  key={sp} />
        ))}
        <SelectField source="disp_opts" choices={dispChoices} sortable={false} />
        <SelectField source="checkin" choices={checkinChoices} sortable={false} />
	      <TextField source="validate" sortable={false} />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}>
      <TextField source="orig_text" />
      {reqField.indexOf(record.id) < 0 ?
        <SelectInput source="disp_opts" choices={dispChoices} />
      :
        <SelectField source="disp_opts" choices={dispChoices} />
      }
      <SelectInput source="checkin" label="bw.bwh.guestfield.checkinlabel" choices={record.id===7?checkinChoices2:checkinChoices} />
      <TextInput source="validate" helperText={translate('bw.bwh.guestfield.validatedesc')}/>
      <Grid container style={{width:'100%'}}>
      {bwh_sprachen.map((sp)=>(
        <React.Fragment key={sp}>
        <Grid item xs={12} md={1}>
          <p style={{marginTop:'2.1em', marginBottom:0}}>{sp.toUpperCase()}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source={"text_" + sp} label="" fullWidth />
        </Grid>
        <Grid item xs={12} md={1} style={{textAlign:'right', paddingRight: '1em'}} >
          <p style={{marginTop:'2.1em', marginBottom:0}}>{translate('bw.bwh.guestfield.options')+'*'}</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source={"options_" + sp} label="" fullWidth multiline />
        </Grid>
        </React.Fragment>
      ))}
        <Grid item xs={12} md={6}>
        </Grid>
        <Grid item xs={12} md={6}>
          <p>{'* '+translate('bw.bwh.guestfield.optionsdesc')}</p>
        </Grid>
      </Grid>
    </SimpleForm>
  )
};
const ListFilter = (props) => (
  <Filter {...props} >
    <TextInput source="q" alwaysOn />
  </Filter>
);

const Guestfield = {
  list: ({ ...props }) => (
    <ListForm {...props} />
  ),
  
  edit: ({ ...props }) => (
    <Edit title="bw.bwh.guestfield.titleEdit" mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Guestfield