import React from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import Timeline from '@mui/icons-material/Timeline';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Language from '@mui/icons-material/Language';
//import ShoppingCart from '@mui/icons-material/ShoppingCart';

import FileCompareIcon from 'mdi-react/FileCompareIcon';
import History from '@mui/icons-material/History';
import Face from '@mui/icons-material/Face';
import CallMade from '@mui/icons-material/CallMade';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Send from '@mui/icons-material/Send';
import Email from '@mui/icons-material/Email';
import ListAlt from '@mui/icons-material/ListAlt';

import SettingsIcon from '@mui/icons-material/Settings';
import RecordVoiceOver from '@mui/icons-material/RecordVoiceOver';
import SaleIcon from 'mdi-react/SaleIcon';
import BunkBedIcon from 'mdi-react/BunkBedIcon';
import RoomServiceIcon from 'mdi-react/RoomServiceIcon';
import BadgeOutlineIcon from 'mdi-react/BadgeAccountHorizontalOutlineIcon';
import Translate from '@mui/icons-material/Translate';
import Cloud from '@mui/icons-material/Cloud';
import ViewHeadline from '@mui/icons-material/ViewHeadline';

import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import Hotel from '@mui/icons-material/Hotel';
import KingBed from '@mui/icons-material/KingBed';
import Image from '@mui/icons-material/Wallpaper';
//import CalendarIcon from '@mui/icons-material/CalendarToday';
import Place from '@mui/icons-material/Place';
import CreditCard from '@mui/icons-material/CreditCard';
import Group from '@mui/icons-material/Group';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import LineStyle from '@mui/icons-material/LineStyle';
import Apps from '@mui/icons-material/Apps';
import Person from '@mui/icons-material/Person';

import WrenchIcon from 'mdi-react/WrenchIcon'
import RayStartArrow from 'mdi-react/RayStartArrowIcon'
import ResponsiveIcon from 'mdi-react/ResponsiveIcon'

import CalendarStarIcon from 'mdi-react/CalendarStarIcon';
import HumanMaleBoyIcon from 'mdi-react/HumanMaleBoyIcon';
import TableIcon from 'mdi-react/TableIcon';
import FormatListChecksIcon from 'mdi-react/FormatListChecksIcon';
import TextSubjectIcon from 'mdi-react/TextSubjectIcon';
import LockIcon from 'mdi-react/LockIcon';



// https://material.io/tools/icons/?style=baseline
// https://materialdesignicons.com

const Menuicons = {

  contact: <MailOutlineIcon />,
  infoarchiv: <InfoIcon />,
  ereignisarchiv: <WarningIcon />,

  calYield: <Timeline />,
  calPricegr: <AttachMoney />,
  calOnline: <Language />,
  calService: <FormatListChecksIcon />,

  repBookingcom: <FileCompareIcon />,
  rep_res_history: <History />,
  guests: <Face />,
    repGuestexport: <CallMade />,
    guest_history: <History />,
    repGdpr: <VisibilityOff />,
  auto: <Send />,
    auto_email: <Email />,
    auto_report: <ListAlt />,
    auto_bfs: <Send />,
    auto_joy: <Send />,

  bwh: <ResponsiveIcon />,
    bwhSettings: <SettingsIcon />,
    bwhText: <TextSubjectIcon />,
    bwhImage: <Image />,
    bwhRoomcategory: <BunkBedIcon />,
    bwhService: <RoomServiceIcon />,
    bwhAgent: <RecordVoiceOver />,
    bwhGuestfield: <BadgeOutlineIcon />,
    bwhDiscount: <SaleIcon />,
    bwhTranslation: <Translate />,
  ota: <Cloud />,
    ota_booking: <Cloud />,
    ota_hostelworld: <Cloud />,
  dorm: <Language />,
    dorm_settings: <SettingsIcon />,
    dorm_blocks: <ViewHeadline />,
    dorm_online_text: <Language />,
    dorm_email_text: <Email />,
    dorm_zimmer_kat: <BunkBedIcon />,
    dorm_zusatzleistungen: <RoomServiceIcon />,
    
  s_sheets: <FormatAlignLeft />,
    setRoomcattext: <BunkBedIcon />,
    setServicetext: <RoomServiceIcon />,
  setService: <RoomServiceIcon />,
  setRoom: <Hotel />,
    setBed: <KingBed />,
    setRoomcategory: <BunkBedIcon />,
  setAgentcontract: <RecordVoiceOver />,
    setAgent: <RecordVoiceOver />,
  s_tag: <TableIcon />,
    setSpecialday: <CalendarStarIcon />,
    setAgecategory: <HumanMaleBoyIcon />,
    setPrefcountry: <Place />,
    setPaymenttype: <CreditCard />,
    setGuestcategory: <Group />,
    setGuestdetail: <FormatListBulleted />,
  s_dormbook: <Apps />,
  setAppopt: <Apps />,
    setPwdyubi: <LockIcon />,
    leer121: <Person />,
    setDbuser: <Person />,
  s_payment: <CreditCard />,
  s_maint: <WrenchIcon />,
    setTransfer: <RayStartArrow />,
    s_maint_guest: <Face />,
    s_maint_res: <LineStyle />,

  glob_find_res: <Send />,
  globHostel: <Send />,
    glob_map: <Send />,
  glob_access: <Send />,
    glob_versions: <Send />,
  glob_users: <Send />,
    glob_dbusers: <Send />,
  glob_payments: <Send />,
  glob_tables: <Send />,
    glob_agent: <RecordVoiceOver />,
    glob_country: <Send />,
    glob_report: <Send />,
    glob_error: <Send />,
    glob_portalinfo: <Send />,
    glob_logsched: <Send />,
  glob_server: <Send />,
  globServer: <Send />,


}
export default Menuicons;
