import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import { useRecordContext, List, Edit, Create, Datagrid, SimpleForm, 
  TextField, DateField, SelectField, EditButton, BooleanInput, 
  TextInput, DateInput, DateTimeInput, SelectInput, ReferenceInput, AutocompleteInput, 
  Filter }  from 'react-admin';
import BulkActionButtons from '../lib/BulkActionButtons';
import CloseToolbar from '../lib/CloseToolbar';

const typchoices=[
  { id:  1, name: 'bw.dashboard.incident_edit.incident' },
  { id:  2, name: 'bw.dashboard.incident_edit.maintenance' },
  { id:  3, name: 'bw.dashboard.incident_edit.breakdown' },
  { id:  9, name: 'bw.dashboard.incident_edit.other' },
  { id:  10, name: 'bw.dashboard.incident_edit.update', not_available: true },
];


const CEForm = ({ classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <ReferenceInput source="id_parent" reference="dashboardHomeinfo" filter={{ edit: 1, id_parent: 0 }} perPage={999} >
        <AutocompleteInput optionText="name" emptyValue={0} emptyText="kein Parent" fullWidth />
      </ReferenceInput>
      <SelectInput source="typ" label="Typ" choices={typchoices} sortable={false} disableValue="not_available" />
      <DateTimeInput source="startdatum" alwaysOn label="bw.dashboard.incident_edit.startDate" />
      <BooleanInput source="noenddate" label="End Date empty" />
      <DateTimeInput source="enddatum" alwaysOn label="bw.dashboard.incident_edit.endDate" />
      <TextInput multiline fullWidth source="text_de" label='Text Deutsch' />
      <TextInput multiline fullWidth source="text_en" label='Text English' />
    </SimpleForm>
  )
};

const InfoText = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div dangerouslySetInnerHTML={{__html: record.text}} />
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="typ1" label="Typ" choices={typchoices} sortable={false} alwaysOn resettable/>
    <DateInput source="startdatum" label="bw.dashboard.incident_edit.startDate" />
    <DateInput source="enddatum" label="bw.dashboard.incident_edit.endDate" />
  </Filter>
);

const ListForm = (props) => {
  const {...rest} = props;
  return (
    <List { ...rest} title="bw.dashboard.incident_edit.title" filter={{ edit: 1 }} filters={<ListFilter />} sort={{ field: 'enddatum', order: 'DESC' }} exporter={isHauptadmin() && props.exporter} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons />} >
        <DateField source="startdatum" showTime locales="de-CH" />
        <DateField source="enddatum" showTime locales="de-CH" />
        <SelectField source="typ1" label="Typ" choices={typchoices} sortable={false}/>
        <InfoText source="text" />
        <TextField source="id" textAlign="right" />
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const Ereignisedit = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.dashboard.incident_edit.title" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit {...props} resource="dashboardHomeinfo" title="bw.dashboard.incident_edit.title" mutationMode="pessimistic">
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Ereignisedit