import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS  } from 'react-admin';
import { apiPath } from './isTest';
import { setGlobalData, getGlobalData } from './globalData';

const Provider = (type, params) => {
    if (type === AUTH_LOGIN) {
        const request = new Request(apiPath+'v1/authToken', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, info, menu, switchhostels }) => {
                setGlobalData({token:token, info:info, menu:menu})
                localStorage.setItem('token', token);
                localStorage.setItem('info', JSON.stringify(info));
                localStorage.setItem('menu', JSON.stringify(menu));
            });
    }
    if (type === AUTH_LOGOUT) {
        setGlobalData({token:false, info:false, menu:false})
        localStorage.removeItem('token');
        localStorage.removeItem('lastmenu');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401) {
          setGlobalData({token:false, info:false, menu:false})
          localStorage.removeItem('token');
          localStorage.removeItem('lastmenu');
          window.location.hash = '/login';
          return Promise.reject();
        }
        return Promise.resolve();      
    }
    if (type === AUTH_CHECK) {
        var token = getGlobalData('token');
        if (!token) {
          token = localStorage.getItem('token')
        }
        return token
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        //const menu = JSON.parse(localStorage.getItem('menu'));
        const menu = getGlobalData('menu');
        return menu ? Promise.resolve(menu) : Promise.reject();
    }
    return Promise.reject('Unkown method');
};
export default Provider;