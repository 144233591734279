import React, { useEffect, useState, useRef } from 'react';
import { mapApi } from '../../lib/isTest'
import { getRecht } from '../../lib/globalData';
import { useTranslate, usePermissions, useRecordContext, useChoicesContext, Edit, TabbedForm, FormTab,
  TextInput, NumberInput, SelectInput,
  ReferenceInput,
  FormDataConsumer  }  from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { ConditionalSelectInput } from '../../fields/ConditionalInput'
import StayEditToolbar from '../../lib/StayEditToolbar';
import Stack from '@mui/material/Stack';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import PlaceIcon from '@mui/icons-material/Place';

const SelectInputCurrency = (props) => {
  const { getValues, watch } = useFormContext();
  const { allChoices, selectedChoices } = useChoicesContext();
  watch('currency0');
  if (!allChoices) return null;
  var ht = "";
  var myrate = 0;
  var mainrate = 0;
  var mainval = getValues('currency0');
  const mycurrency = allChoices.filter(e => selectedChoices[0] && e['id'] === selectedChoices[0].id)
  const maincurrency = allChoices.filter(e => e['id'] === mainval)
  if(mycurrency[0])myrate = mycurrency[0].factor;
  if(maincurrency[0])mainrate = maincurrency[0].factor;
  if(myrate && mainrate){
    ht = selectedChoices[0].id+" => "+mainval+" = "+(myrate/mainrate).toFixed(4);
  }
  return (
    <SelectInput {...props} helperText={ht} />
  )
}

const render = (status)=> {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const { permissions } = usePermissions();
  const record = useRecordContext();

  const Map = ({center, zoom}) => {
    const ref = useRef(null);
    const [map, setMap] = useState();
    const { setValue } = useFormContext();

    useEffect(() => {
      if (ref.current && !map) {
        setMap(new window.google.maps.Map(ref.current, { center, zoom }));
      }
    }, [ref, map, center, zoom]);  

    useEffect(() => {
      if (map) {
        map.addListener("center_changed", () => {
          setValue('latitude', map.center.lat(), { shouldDirty: true });
          setValue('longitude', map.center.lng(), { shouldDirty: true });
        })
      }
    }, [map, setValue]);  
    
    return <>
      <div ref={ref} id="map" style={{height:"100%"}} />
          <NumberInput source="latitude" disabled sx={{position: 'absolute', top: '4em', left:'1em'}}/>
          <NumberInput source="longitude" disabled sx={{position: 'absolute', top: '8em', left:'1em'}}/>
      </>
    
  }
  const Cost = (props) => {
    const CalcPreis = (p, kurs) => {
      var p2 = parseFloat(p);
      const eur = parseInt(record.kunde.eur);
      if (!eur) {
        p2 *= parseFloat(kurs);
      }

      return (eur?'€\u00A0':'CHF\u00A0') + p2.toFixed(2);
    }
    const CalcPreis2 = (p, kurs) => {
      var p2 = parseFloat(p);
      const eur = parseInt(record.kunde.eur);
      if (eur) {
        p2 /= parseFloat(kurs);
      }

      return (eur?'€\u00A0':'CHF\u00A0') + p2.toFixed(2);
    }
    const tr = useTranslate();
    return (
      <>
        <h2>{tr('bw.set.hostel.monthly')}</h2>
        <table>
          <tbody>
      {record && record.cost && record.cost.map((bereich, j)=> (
        <React.Fragment key={j}>
            <tr>
              <td colSpan={3}><b>{bereich.bezeichnung}</b></td>
            </tr>
        {bereich.details.map((detail, i)=> (
            <tr style={{verticalAlign:"top"}} key={i}>
              <td dangerouslySetInnerHTML={{ __html: (''+detail[0]).replaceAll('\n', '<br/>') }} />
              <td dangerouslySetInnerHTML={{ __html: (''+detail[3]).replaceAll('\n', '<br/>') }} style={{padding:'0 2em'}} />
              <td style={{textAlign:'right'}}>{CalcPreis(detail[4], bereich.euro)}</td>
            </tr>
        ))}
            <tr style={{verticalAlign:"top"}}>
              <td>{bereich.bezeichnung}</td>
              <td style={{padding:'0 2em'}}>Total</td>
              <td style={{textAlign:'right'}}><b>{CalcPreis(bereich.total, bereich.euro)}</b></td>
            </tr>
            <tr><td>&nbsp;</td></tr>
        </React.Fragment>
      ))}
          </tbody>
        </table>
        <h2>{tr('bw.set.hostel.balancerep')}</h2>
        <table>
          <thead  style={{textAlign:'left'}}>
            <tr>
              <th>{tr('bw.set.hostel.date')}</th>
              <th style={{padding:'0 1em'}}>Text</th>
              <th style={{textAlign:'right'}}>{tr('bw.set.hostel.amount')}</th>
              <th style={{textAlign:'right', paddingLeft:'1em'}}>{tr('bw.set.hostel.balance')}</th>
            </tr>
          </thead>
          <tbody>
      {record && record.accountlist && record.accountlist.map((acc, j)=> (
            <tr key={j}>
             <td>{acc.datum}</td>
             <td style={{padding:'0 1em'}}>{acc.text}</td>
             <td style={{textAlign:'right'}}>{CalcPreis2(acc.betrag, acc.kurs)}</td>
             <td style={{textAlign:'right', paddingLeft:'1em'}}>{CalcPreis2(parseInt(record.kunde.eur)?acc.kontofremd:acc.konto, 1)}</td>
            </tr>
      ))}
          </tbody>
        </table>
      </>
  )}
  
  const noEdit = getRecht(permissions, 'setHostel')<10;

  return (
    <TabbedForm toolbar={<StayEditToolbar />} {...props}>
      <FormTab label="bw.set.hostel.property"  size={1} >
        <Stack direction="row" spacing={2}>
          <TextInput source="hostel" label="bw.set.hostel.hostel" />
          <TextInput source="name" label="bw.set.hostel.name" />
          <TextInput disabled source="login_name" label="bw.set.hostel.login_name" />
          <TextInput source="cc_text" label="bw.set.hostel.cc_text" disabled />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextInput source="strasse" label="bw.set.hostel.strasse" />
          <TextInput source="plz" label="bw.set.hostel.plz" />
          <TextInput source="ort" label="bw.set.hostel.ort" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextInput source="land" label="bw.set.hostel.land" />
          <TextInput source="region" label="bw.set.hostel.region" />
          <TextInput source="url" label="bw.set.hostel.url" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextInput source="telefon" label="bw.set.hostel.telefon" />
          <TextInput source="email" label="bw.set.hostel.email" />
          <TextInput source="res_email" label="bw.set.hostel.res_email" helperText="bw.set.hostel.res_email_desc" />
        </Stack>
      </FormTab>
      <FormTab label="bw.set.hostel.config"  size={1} >
        <Stack direction="row" spacing={2}>
          <ReferenceInput source="time_zone" reference="setTimezone" perPage={1000} >
            <SelectInput optionText="name" label="bw.set.hostel.timeZone" helperText="bw.set.hostel.timeZoneInfo" />
          </ReferenceInput>
        </Stack>
        <Stack direction="row" spacing={2}>
          <ReferenceInput source="currency0" reference="setCurrency" perPage={1000} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInputCurrency optionText="name" label="bw.set.hostel.currency0" record={record} disabled={noEdit}/>
          </ReferenceInput>
        </Stack>
        <Stack direction="row" spacing={2}>
          <ReferenceInput source="currency1" reference="setCurrency" perPage={1000} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInputCurrency optionText="name" label="bw.set.hostel.currency1" record={record} />
          </ReferenceInput>
          <TextInput source="currencyRate1" label="bw.set.hostel.currencyRate1" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ReferenceInput source="currency2" reference="setCurrency" perPage={1000} sort={{ field: 'name', order: 'ASC' }} >
            <SelectInputCurrency optionText="name" label="bw.set.hostel.currency2" record={record} />
          </ReferenceInput>
          <TextInput source="currencyRate2" label="bw.set.hostel.currencyRate2" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ConditionalSelectInput {...props} source="splitVAT" label="bw.set.hostel.splitVat" helperText="bw.set.hostel.splitVatInfo" choices={[
              { id: '0', name: 'bw.set.hostel.noSplitVat' },
              { id: '1', name: 'bw.set.hostel.splitVat' },
            ]} condition="splitVATenabled" />
          <ConditionalSelectInput {...props} source="noVAT" label="bw.set.hostel.noVat" helperText="bw.set.hostel.noVatInfo" choices={[
              { id: '0', name: 'bw.set.hostel.normalVat' },
              { id: '1', name: 'bw.set.hostel.noVatPaid' },
              { id: '2', name: 'bw.set.hostel.noVatAdd' },
            ]} condition="noVATenabled" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ConditionalSelectInput {...props} source="pluginOccHistory" label="bw.set.hostel.pluginOccHistory" helperText="bw.set.hostel.pluginOccHistoryInfo" choices={[
              { id: '0', name: 'bw.set.hostel.pluginOccHistoryState_0' },
              { id: '1', name: 'bw.set.hostel.pluginOccHistoryState_1' },
            ]} condition="pluginOccHistoryenabled" />
        </Stack>
      </FormTab>
      <FormTab label="bw.set.hostel.map"  size={1} >
        <div style={{width:'100%', height:'500px', position:'relative'}}>
          <div style={{position:'absolute', top:'50%', left:'50%', zIndex:100}}>
            <PlaceIcon style={{position: 'relative', top: '-2em', left: '-1em', width: 'auto', height: '2em'}} />
          </div>
          <FormDataConsumer>{({ formData, ...rest }) =>
            <Wrapper apiKey={mapApi} render={render}>
              <Map center={{ lat: formData.latitude, lng: formData.longitude }} zoom={18}/>
            </Wrapper>
            }
          </FormDataConsumer>
        </div>
      </FormTab>
      <FormTab label="bw.set.hostel.cost"  size={1} >
        <Cost />
      </FormTab>
    {getRecht(permissions, 'setHostel')>=9?
      <FormTab label="bw.set.hostel.invoice"  size={1} >
        <Stack direction="row" spacing={2}>
          <NumberInput source="max_dormbook" label="bw.set.hostel.max_dormbook" disabled={noEdit} />
          <NumberInput source="interfacemin" label="bw.set.hostel.interfacemin" disabled={noEdit} />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ReferenceInput source="preisplan" reference="globConstant/preisplan" perPage={1000} disabled={noEdit}>
            <SelectInput optionText="bez" label="bw.set.hostel.preisplan" />
          </ReferenceInput>
          <ReferenceInput source="id_reseller" reference="globConstant/reseller" perPage={1000} disabled={noEdit}>
            <SelectInput optionText="bez" label="bw.set.hostel.id_reseller" />
          </ReferenceInput>
          <ReferenceInput source="id_2supporter" reference="globUser" filter={{id_fw_gruppe:2}}  perPage={1000} disabled={noEdit}>
            <SelectInput optionText="supportbez" label="bw.set.hostel.id_2supporter" />
          </ReferenceInput>
          <ReferenceInput source="id_supporter" reference="globUser" filter={{id_fw_gruppe:'2,3'}}  perPage={1000} disabled={noEdit} >
            <SelectInput optionText="supportbez" label="bw.set.hostel.id_supporter" />
          </ReferenceInput>
        </Stack>
        <Stack direction="row" spacing={2}>
          <NumberInput source="id_kundeabrechreseller" label="bw.set.hostel.id_kundeabrechreseller" disabled={noEdit}/>
          <NumberInput source="id_kundeabrechnung" label="bw.set.hostel.id_kundeabrechnung" disabled={noEdit}/>
          <FormDataConsumer>{({ formData, ...rest }) =>
            <ReferenceInput source="setupfee" reference="globConstant/alllifecycle"  perPage={1000} {...rest}
              disabledX={!formData.enableLifecycle} disabled={noEdit}>
              <SelectInput optionText="bez" label="Life cycle" />
            </ReferenceInput>
          }
          </FormDataConsumer>
          <ReferenceInput source="id_promotinghostel" reference="globHostel" perPage={1000} disabled={noEdit}>
            <SelectInput optionText="hostel" label="bw.set.hostel.id_promotinghostel" />
          </ReferenceInput>
        </Stack>
        <Stack direction="row" spacing={2}>
          <NumberInput source="rabatt" label="bw.set.hostel.rabatt" disabled={noEdit} />
          <ReferenceInput source="rabattart" reference="globConstant/rabattart" filter={{id_fw_gruppe:'2,3'}}  perPage={1000} disabled={noEdit}>
            <SelectInput optionText="bez" label="bw.set.hostel.rabattart" />
          </ReferenceInput>
          <ReferenceInput source="credituse" reference="globConstant/credituse" filter={{id_fw_gruppe:'2,3'}}  perPage={1000} disabled={noEdit}>
            <SelectInput optionText="bez" label="bw.set.hostel.credituse" />
          </ReferenceInput>
        </Stack>
        <TextInput source="resellerremark" multiline fullWidth label="bw.set.hostel.resellerremark" disabled={noEdit} />
      </FormTab>
    :null}
    </TabbedForm>
  )
};

const Hostel = {
  list: null,
  edit: (props) => (
    <Edit title="bw.set.hostel.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}
export default Hostel