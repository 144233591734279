import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { getGlobalData } from './globalData';
import { apiPath } from './isTest';

const httpClient = (url, options = {}) => {
    if (!options.headers || !options.headers.set) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    var token = getGlobalData('token');
    if (!token) {
      token = localStorage.getItem('token')
    }
    options.headers.set('Authorization', token);
    return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider(apiPath+'v1', httpClient);
const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        const uploadEPs = {bwhAgent:true, bwhImage:true, 
        bwhRoomcategory:true, bwhService:true, setRoomcategory:true };
        if (!uploadEPs[resource]) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        var newFiles = [];
        for (var k in params.data) {
          var v = params.data[k];
          if (v && v.rawFile) {
            v.name = k;
            newFiles.push(v);
          }
          if (v && Array.isArray(v)) {
            for (var i=0;i<v.length;i++) {
              var v2 = v[i];
              if (v2 && v2.rawFile) {
                v2.name = k;
                newFiles.push(v2);
              }
            }  
          }
        }
        return Promise.all(newFiles.map(convertFileToBase64))
            .then(base64File => base64File.map(file64 => ({
                src: file64
            })))
            .then((transformedNewFile) => dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    filesdata: [...transformedNewFile],
                    fileinfo: [...newFiles]
                },
            }));
    },
    create: (resource, params) => {
        const uploadEPs = {bwhImage:true, setRoomcategory:true };
        if (!uploadEPs[resource]) {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
        var newFiles = [];
        for (var k in params.data) {
          var v = params.data[k];
          if (v && v.rawFile) {
            v.name = k;
            newFiles.push(v);
          }
        }
        return Promise.all(newFiles.map(convertFileToBase64))
            .then(base64File => base64File.map(file64 => ({
                src: file64
            })))
            .then((transformedNewFile) => dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    filesdata: [...transformedNewFile],
                    fileinfo: [...newFiles]
                },
            }));
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

export default myDataProvider;
