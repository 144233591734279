import React, { cloneElement, Component } from 'react';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import NestableMenuList from './NestableMenuList';

export class NestableMenuItem extends Component {
    state = {
      open: false
    };
    handleMenuTap = () => {
      this.props.onClick && this.props.onClick();
    };

    handleExpand = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState(state => ({ open: !state.open }));
    };

    render() {
        const {
            primaryText,
            children,
            onClick,
            dense,
            sub,
            menuct,
            leftIcon,
            staticContext,
            to,
            ...props
        } = this.props;
        var res = [];

        res.push(
            <ListItem
                key={1}
                style={{color:'#000'}}
                component={NavLink}
                onClick={to.substring(0,5)==='/leer'?this.handleExpand:this.handleMenuTap}
                to={to+((to==='/bwhSettings' || to==='/setPwdyubi' || to==='/setAppopt')?'/1':'')}
                {...props}
            >
                {leftIcon && (
                    <ListItemIcon>
                        {cloneElement(leftIcon, { titleaccess: primaryText })}
                    </ListItemIcon>
                )}
                <ListItemText inset primary={primaryText} style={{paddingLeft:'18px'}} />
            {children && children.length
              ?(this.state.open ? (
                <ExpandLess onClick={this.handleExpand} />
              ) : (
                <ExpandMore onClick={this.handleExpand} />
              ))
              : null
            }
            </ListItem>
        );
        if (children && children.length) {
          res.push(
        <Collapse key={2} in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ paddingLeft:2}}>
            <NestableMenuList list={children} menuct={menuct} onMenuClick={onClick} dense={dense} sub={true} />
          </List>
        </Collapse>
          );
        }
        return res;
    }
}

export default NestableMenuItem;
