import React from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, List, Create, Edit, Datagrid, SimpleForm, 
  TextField, BooleanField, SelectField,
  EditButton, NumberInput, TextInput, BooleanInput, SelectInput
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import FormatTimeArrival from '../../lib/FormatTimeArrival';

const zeitart = [
  { id: 'buchung', name: 'bw.set.payrequest.buchung' },
  { id: 'nachbuchung', name: 'bw.set.payrequest.nachbuchung' },
  { id: 'vorankunft', name: 'bw.set.payrequest.vorankunft' },
  { id: 'checkin', name: 'bw.set.payrequest.checkin' },
];
const betragart = [
  { id: 'prozent', name: 'bw.set.payrequest.prozent' },
  { id: 'fix', name: 'bw.set.payrequest.fix' },
  { id: 'naechte', name: 'bw.set.payrequest.naechte' },
  { id: 'rest', name: 'bw.set.payrequest.rest' },
];

const PayZeit = ({source, zeitart}) => {
  const { getValues, watch } = useFormContext();
  watch(source)
  watch(zeitart)
  const record = getValues();
  const translate = useTranslate();
  var sub1 = '';
  if (record && (record.zeitart === "vorankunft" || record.zeitart === "nachbuchung")) {
    sub1 = FormatTimeArrival(record[source], 'd', translate);
  }
  return (
    <NumberInput source="zeit" helperText={sub1} />
  )
}

const ListForm = (props) => {
  return (
    <List {...props} title='bw.set.payrequest.title' 
      sort={{ field: 'zeitart', order: 'ASC' }} exporter={isHauptadmin() && props.exporter} 
      pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <TextField source="zeit" textAlign="right" />
        <SelectField source="zeitart" choices={zeitart} />
        <TextField source="betrag" textAlign="right" />
        <SelectField source="betragart" choices={betragart} />
        <BooleanField source="active" />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
        <PayZeit source="zeit" {...props} />
        <SelectInput source="zeitart" choices={zeitart} />
        <TextInput source="betrag"  />
        <SelectInput source="betragart" choices={betragart} />
        <BooleanInput source="active" defaultValue={true}  />
    </SimpleForm>
  )
};

const Payrequest = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.payrequest.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.payrequest.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Payrequest