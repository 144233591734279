import React, { useState, useEffect } from 'react';
import { isHauptadmin, getRecht } from '../../lib/globalData';
import { usePermissions, useRecordContext, useRedirect, useEditContext, useCreateContext, useTranslate, useListContext, 
  List, Create, Edit, Datagrid, DatagridConfigurable, TabbedForm, FormTab, SimpleForm, Toolbar, DeleteButton, 
  TextField, WrapperField, 
  EditButton, CreateButton, ExportButton, SelectColumnsButton, Button, SaveButton, FilterButton, 
  TextInput, ReferenceInput, SelectInput, AutocompleteInput, NumberInput, BooleanInput, 
  Filter, TopToolbar } from 'react-admin';
import dataProvider from '../../lib/dataProvider';
import Grid from '@mui/material/Grid';
import BackspaceIcon from '@mui/icons-material/Backspace';
import IconClose from '@mui/icons-material/Close';
import { Link, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const CloseToolbar = ({redir, noDelete, noBack}) => {
  const redirect = useRedirect();
  let { resource } = useEditContext();
  const createcontext = useCreateContext();
  if(!resource)resource = createcontext.resource;

  const clickBack = () => {
    redirect('list', redir?redir:resource);
  }

  return(
    <Toolbar>
        <SaveButton label="Save" mutationOptions={{
          onSuccess: () => {
            redirect('list', redir?redir:resource);
          }}
        }/>
      {!noBack &&  
        <Button label="ra.action.back" onClick={clickBack} variant="contained" size="medium" sx={{marginLeft:'1em'}}>
          <BackspaceIcon />
        </Button>
      }
      {!noDelete &&
        <DeleteButton style={{marginLeft: 'auto'}} mutationMode="pessimistic" />
      }
    </Toolbar>
  );
};

const stopPropagation = e => e.stopPropagation();

const EmailField = (props) => {
    const record = useRecordContext();
    if (!record?.[props?.source])return null;
    return (
        <Link href={`mailto:${record?.[props?.source]}`} onClick={stopPropagation} variant="body2">email</Link>
    );
};

const ListActions = ({tAside, dCopy, ...props}) => (
  <TopToolbar>
    <FilterButton {...props} />
    <Button onClick={tAside}>
    Price plans
    </Button>
    <Button onClick={dCopy}>
    Copy Hostel
    </Button>
    <SelectColumnsButton />
    <CreateButton/>
    <ExportButton/>
  </TopToolbar>
);

const Aside = () => {
  const [emails, setEmails] = useState("");
  const {filterValues} = useListContext();
  useEffect(() => {
    dataProvider.getManyReference('globHostel/getemails', {
      id: 0,
      filter: filterValues ,
      sort: { },
      pagination: { page: 1, perPage: 100 },
  })
    .then(response => {
      setEmails(response?.data?.emails);
    })
  }, [filterValues]);
  return (
    <div style={{fontSize: 'medium', marginTop:'4.85em', marginLeft: '0.2em'}}>
      <div>Price plans:</div>
      <table border="1" style={{borderStyle: 'solid', borderCollapse: 'collapse', borderColor: 'lightgrey'}} cellPadding="2" cellSpacing="0">
        <tr><td align="center" width="50">T</td><td>(0) Traditional [T4: 1-40 Beds, T9: 41-99 Beds, Tx: 100+ Beds]</td></tr>
        <tr><td align="center">cb1</td>         <td>(1) Booking fee (included, cashback, 1month)</td></tr>
        <tr><td align="center">top</td>         <td>(2) Booking fee (on top, no cashback)</td></tr>
        <tr><td align="center">cb3</td>         <td>(3) Booking fee (on top, cashback, 3month)</td></tr>
        <tr><td align="center">cb6</td>         <td>(4) Booking fee (on top, cashback, 6month)</td></tr>
        <tr><td align="center">2L1A</td>        <td>(5) 2 Licences, 1 Agent (on top)</td></tr>
        <tr><td align="center">2L2A</td>        <td>(6) 2 Licences, 2 Agents (on top)</td></tr>
        <tr><td align="center">2L</td>          <td>(7) 2 Licences, no Agent (on top)</td></tr>
        <tr><td align="center">2L3A</td>        <td>(8) 2 Licences, 3 Agents (on top)</td></tr>
        <tr><td align="center">2L4A</td>        <td>(9) 2 Licences, 4 Agents (on top)</td></tr>
        <tr><td align="center">b25</td>         <td>(10) based on beds, credits: 0.25 per booking</td></tr>
        <tr><td align="center">b20</td>         <td>(11) based on beds, credits: 0.20 per booking</td></tr>
        <tr><td align="center">b15</td>         <td>(12) based on beds, credits: 0.15 per booking</td></tr>
        <tr><td align="center">b10</td>         <td>(13) based on beds, credits: 0.10 per booking</td></tr>
        <tr><td align="center">b05</td>         <td>(14) based on beds, credits: 0.05 per booking</td></tr>
        <tr><td align="center">b00</td>         <td>(15) based on beds, no credits</td></tr>
        <tr><td align="center">to0%5</td>       <td>(16) based on turnover, 0.5%, no support</td></tr>
        <tr><td align="center">pn25</td>        <td>(17) automatic processed nights, no support</td></tr>
        <tr><td align="center">tospez</td>      <td>(18) based on turnover, special, no support</td></tr>
      </table>
      <div>Emails:</div>
      <div>{emails}</div>
    </div>
  )
}

const ListForm = (props) => {
  const [asideOpen, setAsideOpen] = useState(false);
  const [dispCopy, setDispCopy] = useState(false);
  const translate = useTranslate();
  const handleToggleAside = () => {
    setAsideOpen(!asideOpen);
  };
  const doCopy = (data) => {
    dataProvider.update('globHostel/copyhostel', { id: data.id_copy, data:data })
      .then(({ data }) => {
        //setShowDialog(false);
        //refresh();
        //redirect(`/calPrice/${record.id}`)
        //setChecks(false);
      })
      .catch(error => {
        //notify(error.message, 'error');
      })
  };       
  const {classes, ...rest} = props;
  return (
    <div>
      <List {...rest} filters={listFilter} title="bw.set.paymenttype.title" perPage={100} 
        sort={{ field: 'hostel', order: 'ASC' }} exporter={isHauptadmin() && props.exporter} 
        actions={<ListActions tAside={handleToggleAside} dCopy={setDispCopy} />} 
        aside={asideOpen && <Aside />}>
        <DatagridConfigurable style={{ width: 'auto' }} bulkActionButtons={false} omit={[ 'email', 'res_email', 'preisplan', 'id_reseller', 'name', 'strasse', 'ort', 'plz', 'land', 'region', 'latitude', 'longitude', 'telefon', 'fax', 'url', 'text_de', 'text_en', 'text_fr', 'hostel_nummer', 'interfacemin', 'rabatt', 'rabattart', 'credituse', 'resellerremark' ]} >
          <TextField source="hostel" />
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="strasse" />
          <TextField source="ort" />
          <TextField source="plz" />
          <TextField source="land" />
          <TextField source="region" />
          <TextField source="latitude" />
          <TextField source="longitude" />
          <TextField source="telefon" />
          <TextField source="fax" />
          <TextField source="url" />
          <TextField source="text_de" />
          <TextField source="text_en" />
          <TextField source="text_fr" />
          <TextField source="hostel_nummer" />
          <TextField source="login_name" label="Login" />
          <EmailField source="emailLink" label="eMail" />
          <TextField source="email" label="eMail" />
          <EmailField source="emailLink2" label="eMail2" />
          <TextField source="res_email" label="eMail2" />
          <TextField source="max_dormbook" label="DB#" textAlign="right" />
          <TextField source="pp" label="P" />
          <TextField source="preisplan" label="P" />
          <TextField source="reseller" label="R" />
          <TextField source="id_reseller" label="R" />
          {isHauptadmin() &&
            <TextField source="id_2supporter" label="S2" />
          }
          {isHauptadmin() &&
            <TextField source="id_supporter" label="S" />
          }
          {isHauptadmin() &&
            <TextField source="setupfee" label="LC" />
          }
          {isHauptadmin() &&
            <TextField source="stripe_connect" label="S" />
          }
          <TextField source="id_kundeabrechnung" label="invoice" />
          <TextField source="id_kundeabrechreseller" label="a3r" />
          <TextField source="interfacemin" />
          <TextField source="rabatt2" label="Red." textAlign="right" />
          <TextField source="rabatt" />
          <TextField source="rabattart" />
          <TextField source="credituse" />
          <TextField source="resellerremark" />
          {isHauptadmin() &&
            <TextField source="obe" sortable={false} />
          }
          {isHauptadmin() &&
            <TextField source="pay" sortable={false} />
          }
          <WrapperField label="">
            <EditButton />
          </WrapperField>
        </DatagridConfigurable>
      </List>
      <Dialog fullWidth maxWidth="xl" open={dispCopy} onClose={()=>setDispCopy(false)} aria-label="Embed">
        <DialogActions style={{position:'absolute', right:0}}>
          <Button size='large' label="" onClick={()=>setDispCopy(false)}><IconClose /></Button>
        </DialogActions>
        <DialogTitle>{translate('bw.glob.hostel.copytitle')}</DialogTitle>
        <DialogContent>
          <span>{translate('bw.glob.hostel.copyhostel_info')}</span>
          <SimpleForm resource="globHostel/copyhostel" onSubmit={doCopy} validate={validateEdit} toolbar={false} >
            <ReferenceInput source="id_copy" reference="globHostel/getcopyhostel" >
              <SelectInput optionText="hostel" emptyValue={0} helperText={false} sx={{width:'100%'}} />
            </ReferenceInput>
            <Grid container style={{width:'100%'}} spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="login_name" label="Login" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="hostel" label="Hostel" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="cc_text" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="name" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="strasse" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="ort" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="plz" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="land" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="region" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="telefon" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="fax" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="email" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="res_email" helperText="bw.glob.hostel.res_email_help" sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="url" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextInput source="max_dormbook" helperText={false} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <ReferenceInput source="preisplan" reference="globConstant/preisplan">
                  <SelectInput optionText="bez" emptyValue={0} helperText={false} sx={{width:'100%'}} />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <ReferenceInput source="id_promotinghostel" reference="globHostel/promohostel" >
                  <AutocompleteInput optionText="hostel" emptyValue={0} helperText={false} sx={{width:'100%'}} />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <span>Passwords/Logins</span>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="addAdminRec" defaultValue={true} helperText={false} label="create additional login for Reception" sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="addAdminReport" defaultValue={true} helperText={false} label="create additional login for Reports" sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <span>Options</span>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="guestdetail" defaultValue={true} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="alterskat" defaultValue={true} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="zahlungsart" defaultValue={true} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <BooleanInput source="zusatzleistung" defaultValue={true} sx={{width:'100%'}} />
              </Grid>
              <Grid item xs={12} md={12} lg={12} textAlign="right" >
                <SaveButton label="Hostel kopieren (definitiv)" />
              </Grid>
            </Grid>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </div>
  )
}


  const validateEdit = (values) => {
    const errors = {};
    const rlogin_name = new RegExp('^[^\\[a-z][a-z_0-9]*\\]*$');
    if(values.login_name && rlogin_name.test(values.login_name)){
      errors.login_name = 'bw.glob.hostel.validate_login_name';
    }
    const rcc_text = new RegExp('[^A-Z0-9 ]|.{23,}');
    if(values.cc_text && rcc_text.test(values.cc_text)){
      errors.cc_text = 'bw.glob.hostel.validate_cc_text';
    }
    return errors
  };

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const { permissions } = usePermissions();

  return (
    <TabbedForm toolbar={<CloseToolbar />} validate={validateEdit} {...props}>
      <FormTab label="bw.glob.hostel.basis"  size={1} >
        <Grid container style={{width:'100%'}} spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="id" label="Hostel ID" disabled sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="login_name" label="Login" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="hostel" label="Hostel" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="cc_text" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="name" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="strasse" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="ort" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="plz" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="land" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="region" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="latitude" helperText="bw.glob.hostel.koordinaten_help" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="longitude" helperText="bw.glob.hostel.koordinaten_help" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="telefon" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="fax" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="email" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="res_email" helperText="bw.glob.hostel.res_email_help" sx={{width:'100%'}} />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextInput source="url" sx={{width:'100%'}} />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Dormonline"  size={1} >
        <TextInput source="text_de" multiline fullWidth/>
        <TextInput source="text_en" multiline fullWidth/>
        <TextInput source="text_fr" multiline fullWidth/>
      </FormTab>
      <FormTab label="bw.glob.hostel.abrechnung"  size={1} >
        <Grid container spacing={1}>
          <Grid container style={{width:'100%'}} spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <NumberInput source="max_dormbook" helperText="bw.glob.hostel.max_dormbook_help" sx={{width:'100%'}} />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="interfacemin" reference="globConstant/interfacemin">
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container style={{width:'100%'}} spacing={2}>
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="preisplan" reference="globConstant/preisplan">
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
          { getRecht(permissions, 'globHostel')>=9 &&
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="id_reseller" reference="globConstant/reseller">
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
          }
          { getRecht(permissions, 'globHostel')>=9 &&
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="id_2supporter" reference="globUser" filter={{id_fw_gruppe:2}}>
                <SelectInput optionText="supportbez" emptyValue={0} sx={{width:'100%'}} helperText="(3) alixon; (384) hosteloffice" />
              </ReferenceInput>
            </Grid>
          }
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="id_supporter" reference="globUser" filter={{id_fw_gruppe:'2,3'}}>
                <SelectInput optionText="supportbez" emptyValue={0} sx={{width:'100%'}} helperText="(3) alixon; (384) hosteloffice; (856) konzeptlabor" />
              </ReferenceInput>
            </Grid>
          { getRecht(permissions, 'globHostel')>=9 &&
            <Grid item xs={12} md={4} lg={3}>
              <NumberInput source="id_kundeabrechreseller" helperText="(3) alixon; (384) hosteloffice" sx={{width:'100%'}} />
            </Grid>
          }
          { getRecht(permissions, 'globHostel')>=9 &&
            <Grid item xs={12} md={4} lg={3}>
              <NumberInput source="id_kundeabrechnung" sx={{width:'100%'}} />
            </Grid>
          }
          { getRecht(permissions, 'globHostel')>=9 &&
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="setupfee" reference="globConstant/alllifecycle" >
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
          }
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="id_promotinghostel" reference="globHostel/promohostel" >
                <SelectInput optionText="hostel" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <NumberInput source="rabatt" sx={{width:'100%'}} />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="rabattart" reference="globConstant/rabattart" >
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <ReferenceInput source="credituse" reference="globConstant/credituse" >
                <SelectInput optionText="bez" emptyValue={0} sx={{width:'100%'}} />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container style={{width:'100%'}} spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput source="resellerremark" multiline fullWidth/>
            </Grid>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  )
};

const listFilter = [
  <ReferenceInput source="active" reference="globHostel/getfilter" alwaysOn>
    <SelectInput optionText="name" label="active" emptyValue={-2} />
  </ReferenceInput>,
  <TextInput source="q" alwaysOn />,
  <TextInput source="hostel" />,
  <TextInput source="id" />,
  <TextInput source="login_name" />,
  <TextInput source="name" />,
  <TextInput source="strasse" />,
  <TextInput source="ort" />,
  <TextInput source="plz" />,
  <TextInput source="land" />,
  <TextInput source="region" />,
  <TextInput source="latitude" />,
  <TextInput source="longitude" />,
  <TextInput source="telefon" />,
  <TextInput source="fax" />,
  <TextInput source="url" />,
  <TextInput source="text_de" />,
  <TextInput source="text_en" />,
  <TextInput source="text_fr" />,
  <TextInput source="hostel_nummer" />,
  <TextInput source="email" />,
  <TextInput source="res_email" />,
  <TextInput source="max_dormbook" textAlign="right" />,
  <TextInput source="preisplan" />,
  <TextInput source="id_reseller" />,
  <TextInput source="id_2supporter" />,
  <TextInput source="id_supporter" />,
  <TextInput source="setupfee" />,
  <TextInput source="id_kundeabrechnung" />,
  <TextInput source="id_kundeabrechreseller" />,
  <TextInput source="interfacemin" />,
  <TextInput source="rabatt" />,
  <TextInput source="rabattart" />,
  <TextInput source="credituse" />,
  <TextInput source="resellerremark" />,
];

const globHostel = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.paymenttype.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.paymenttype.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default globHostel
