import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTranslate } from 'react-admin';
import Button from '@mui/material/Button';
import NotificationImportant from '@mui/icons-material/NotificationImportant';
import Timeline from '@mui/icons-material/Timeline';
import WarningIcon from '@mui/icons-material/Warning';
import HourglassIcon from '@mui/icons-material/HourglassFull';
import InfoIcon from '@mui/icons-material/Info';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area } from 'recharts';
import { useMediaQuery } from '@mui/material';

import dataProvider from '../lib/dataProvider';
import CardIcon from '../lib/CardIcon';
import CheckInIcon from 'mdi-react/ArrowBottomRightIcon'
import CheckOutIcon from 'mdi-react/ArrowTopRightIcon'
import GuestsIcon from 'mdi-react/AccountSupervisorIcon'


const styles = {
    flex: { display: 'flex' },
    flexTop: { display: 'flex', marginTop:'1em' },
    flexMargin: { display: 'flex', marginBottom: "1em" },
    flexSpace: { display: 'flex', justifyContent: 'space-between' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    topButton: { marginRight: "1em" },
    cardWithIcon: { 
       padding: "16px 0", overflow: "inherit", minHeight:'5em', marginBottom:'3em', 
       "& h3": { marginBlockStart: 0}
    },
    homInfoMain: { borderTop: "1px solid #aaa"},
    tab: { marginLeft: '16px', },
    tab1: { minWidth: '90px', marginRight:'6px'},
};

const Notification = ({ homeinfo }) => {
  const translate = useTranslate();  
  return (
    <div>
      <CardIcon Icon={NotificationImportant} bgColor="#FF9800" />
      <Card sx={styles.cardWithIcon}>
        <List>
        {homeinfo.map((rec, index) => {
          const end = parseInt(rec.enddatum) > 0;
          const col = end?"#66C266":rec.typ===2?"#FFB300":"#FF0000";
          var t = "<span>"+rec.startdatum.substr(0,16)+"</span>";
          if (end) {
            t += translate('bw.dashboard.resolved') + rec.enddatum.substr(0,16);
          }
          t += '<br />'+rec.text;
          return (
            <div key={rec.id} >
              <ListItem style={index>0?styles.homInfoMain:null}> 
                {rec.typ===1? ( <WarningIcon style={{color:col, fontSize:"2em" }} />) : null}
                {rec.typ===2? ( <HourglassIcon style={{color:col, fontSize:"2em" }} />) : null}
                {rec.typ>2? ( <WarningIcon style={{color:col, fontSize:"2em" }} />) : null}
                {rec.typ===0? ( <InfoIcon  style={{color:"#66C266", fontSize:"2em" }} />) : null}
                <div dangerouslySetInnerHTML={{__html: t}} style={{marginLeft:"1em"}} />
              </ListItem>
              {rec.children && rec.children.map(el => (
                <ListItem key={el.id} style={styles.homInfoSub}> 
                  <div dangerouslySetInnerHTML={{__html: "<span>"+el.startdatum.substr(0,16)+"</span><br />"+el.text}} style={{marginLeft:"5em"}} />
                </ListItem>
                ))
              }
            </div>
        )})}
        </List>              
      </Card>
    </div>
  );
}

const Dashboard = ({permissions}) => {
  const translate = useTranslate();  
  const [start, setStart] = useState(1);
  const [duration, setDuration] = useState(3);
  const [stats, setStats] = useState({});
  const [dispData, setDispData] = useState({zimmerkats: [], belegung: []});
  const [homeinfo, setHomeinfo] = useState([]);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    
  useEffect(() => {
    dataProvider.getList('dashboardStats', {
        filter: { date_gte: true },
        sort: false,
        pagination: { page: 1, perPage: 20 },
    })
      .then(response => {
        if (response.data) {
          setStats(response.data);
        }
      });
    dataProvider.getList('dashboardHomeinfo', {
        filter: { date_gte: true },
        sort: false,
        pagination: { page: 1, perPage: 20 },
    })
      .then(response => {
        if (response.data && response.data.length) {
          setHomeinfo(response.data);
        }
      });
  }, []);

  useEffect(() => {
    dataProvider.getList('dashboardDisplay', {
        id: 0,
        filter: { start: start, duration: duration },
        sort: { field: 'date', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    })
      .then(response => {
        if (response.data && response.data.belegung) {
          setDispData(response.data);
        }
      });
  }, [start, duration]);


  const handleButton = (v) => ev => {
    if (v==='p') {
      setStart(0);
    }
    else if (v==='f') {
      setStart(1);
    }
    else {
      setDuration(parseInt(v));
    }
  }
  
  if (!permissions) {
    return null;
  }
  if (permissions[0] && permissions[0].recht>0) {

    if (dispData.zimmerkats.length<1) {
      return null;
    }
    const intervalle = {1:6,3:13,6:27,12:60};
    return (  
      <div>
        <div style={styles.flexTop}>
          <div style={styles.leftCol}>
              {stats.checkin && (
              <div style={styles.flex}>
                 <div style={styles.leftCol}>
                    <CardIcon Icon={CheckInIcon} bgColor="#34A953" />
                    <Card sx={styles.cardWithIcon}>
                      <h3>Check In</h3>
                      <table style={styles.tab}><tbody>
                        <tr><td style={styles.tab1}>Today</td><td>{stats.checkin[0]}</td></tr>
                        <tr><td>Tomorrow</td><td>{stats.checkin[1]}</td></tr>
                      </tbody></table>
                    </Card>
                </div>            
                 <div style={styles.rightCol}>
                    <CardIcon Icon={CheckOutIcon} bgColor="#E26746" />
                    <Card sx={styles.cardWithIcon}>
                      <h3>Check Out</h3>
                      <table style={styles.tab}><tbody>
                        <tr><td style={styles.tab1}>Today</td><td>{stats.checkout[0]}</td></tr>
                        <tr><td>Tomorrow</td><td>{stats.checkout[1]}</td></tr>
                      </tbody></table>
                    </Card>
                </div>            
                 <div style={styles.rightCol}>
                    <CardIcon Icon={GuestsIcon} bgColor="#3A5697" />
                    <Card sx={styles.cardWithIcon}>
                      <h3>Guests</h3>
                      <table style={styles.tab}><tbody>
                        <tr><td style={styles.tab1}>Today</td><td>{stats.guests[0]}</td></tr>
                        <tr><td>Tomorrow</td><td>{stats.guests[1]}</td></tr>
                      </tbody></table>
                    </Card>
                </div>           
              </div>
                )} 
            <CardIcon Icon={Timeline} bgColor="#31708F" />
            <Card sx={styles.cardWithIcon}>
              <CardContent sx={{paddingTop:0}}>
                <h3>Occupation</h3>
                <div style={styles.flexMargin}>
                  <Button variant={start===0?"contained":"outlined"} color={start===0?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('p')}>{translate('bw.dashboard.past')}</Button>
                  <Button variant={start===1?"contained":"outlined"} color={start===1?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('f')}>{translate('bw.dashboard.future')}</Button>
                  <Button variant={duration===1?"contained":"outlined"} color={duration===1?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('1')}>{translate('bw.dashboard.month', {smart_count:1})}</Button>
                  <Button variant={duration===3?"contained":"outlined"} color={duration===3?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('3')}>{translate('bw.dashboard.month', {smart_count:3})}</Button>
                  <Button variant={duration===6?"contained":"outlined"} color={duration===6?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('6')}>{translate('bw.dashboard.month', {smart_count:6})}</Button>
                  <Button variant={duration===12?"contained":"outlined"} color={duration===12?"primary":"inherit"} sx={styles.topButton} onClick={handleButton('12')}>{translate('bw.dashboard.month', {smart_count:12})}</Button>
                </div>  
                <ResponsiveContainer width="100%" aspect={2} >
                  <AreaChart data={dispData.belegung}
                        margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="datum" interval={intervalle[duration]}/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend />
                    {dispData.zimmerkats.map(el => (
                      <Area type='linear' dataKey={el.preiskat_id} key={el.preiskat_id} stackId="1" stroke={el.farbe} fill={el.farbe} activeDot name={el.bezeichnung} />
                      )
                    )}
                  </AreaChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </div>
          {!isSmall && <div style={styles.rightCol}><Notification translate={translate} homeinfo={homeinfo} /></div>}
        </div>        
      {isSmall && <div style={styles.leftCol}><Notification homeinfo={homeinfo} /></div>}
      </div>        
    );
  }
  return (
    <div>
      <CardIcon Icon={WarningIcon} bgColor="#FFB300" />
      <Card sx={styles.cardWithIcon}>
        <CardContent>
          <div dangerouslySetInnerHTML={{__html: translate('bw.dashboard.restricted_text')}} />
        </CardContent>
      </Card>
    </div>
  )
}
export default Dashboard;
