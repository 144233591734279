import React, { useState } from 'react';
import { isHauptadmin, formatImg } from '../../lib/globalData';
import { useRecordContext, List, Filter, Create, Edit, Datagrid, SimpleForm, 
  TextField, ImageField, EditButton, SelectInput, 
  ImageInput, AutocompleteInput } from 'react-admin';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import TranslateField from '../../fields/TranslateField';
  
const artEnumFilter = [
  'bwh.hostel.bild',
  'bwh.hostel.map',
  'bwh.hostel.zusatzbild',
  'bwh.hostel.favicon',
];

const artEnumCreate = [
  'bwh.hostel.bild',
  'bwh.hostel.zusatzbild',
];

const postDefaultValue = { art: 'bwh.hostel.bild', id_element: 1 };

const ListForm = (props) => {
  const MoveCond = (props) => {
    const record = useRecordContext();
    return record && record.art==='bwh.hostel.bild' ? <Move {...props} /> :null;
  };

  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  return (
    <List {...props} title={('bw.bwh.image.title')}  sort={{ field: 'art', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <TranslateField source="art_lbl" />
        <TextField source="id_element" />
        <MoveCond {...props} getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="bwhImage" />
        <ImageField source="text" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const artList = artEnumCreate.map((v)=>{return{id:v,name:'bw.'+v}});
  return (
    <SimpleForm toolbar={<CloseToolbar />} defaultValues={postDefaultValue} {...props}> 
      <SelectInput source="art" choices={artList} defaultValue="bwh.hostel.bild" />
      <ImageField source="text" />
      <ImageInput source="text" accept="image/*" format={formatImg} >
        <ImageField source="src" title="text" />
      </ImageInput>
    </SimpleForm>
  )
};

const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <TranslateField source="art_lbl" target="art_lbl_tr" noOutput />
      <TextField source="art_lbl_tr" />
      <TextField source="id_element" />
      <ImageField source="text" />
      <ImageInput source="text" accept="image/*" format={formatImg}>
        <ImageField source="src" title="text" />
      </ImageInput>
    </SimpleForm>
  )
};

const ListFilter = (props) => {
  const artList = artEnumFilter.map((v)=>{return{id:v,name:'bw.'+v}});
  return (
  <Filter {...props} >
    <AutocompleteInput source="art" choices={artList} alwaysOn emptyText='All' />
  </Filter>
)};

const Image = {
  list: (props) => (
    <ListForm {...props} filters={<ListFilter />} />
  ),
  
  create: (props) => (
    <Create title='bw.bwh.image.titleCreate' redirect="list"{...props}>
      <CForm {...props} />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title='bw.bwh.image.titleEdit' mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Image