import React, { useState } from 'react';
import { isHauptadmin, getInfoValue } from '../../lib/globalData';
import { useRecordContext, useListContext, List, Show, Datagrid, SimpleShowLayout, 
  TextField, DateField, CheckboxGroupInput, Button, 
  TextInput, ReferenceInput, AutocompleteInput, SelectInput, ShowButton, Filter }  from 'react-admin';
import { Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import dataProvider from '../../lib/dataProvider';

const TextDisplay = ({ source, ...props }) => {
  const record = useRecordContext();
  return (
    <div style={{ fontSize: 'small' }} dangerouslySetInnerHTML={{ __html: record?.[source] }}></div>
  )
}

const ShowMessage = ({ classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleShowLayout {...props}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <span style={{ fontSize: 'small', color: 'grey' }}>Zeit: </span>
          <DateField {...props} source="zeit" showTime locales="de-CH" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <span style={{ fontSize: 'small', color: 'grey' }}>Hostel: </span>
          <TextField {...props} source="id_hostel" label="Hostel" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
        {isHauptadmin() &&
          <div>
            <span style={{ fontSize: 'small', color: 'grey' }}>Id: </span>
            <TextField source="id" textAlign="right" label="Id" />
          </div>
        }
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <span style={{ fontSize: 'small', color: 'grey' }}>Betreff: </span>
          <TextField source="betreff"  label="Betreff" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <span style={{ fontSize: 'small', color: 'grey' }}>Receiver: </span>
          <TextField source="receiver" label="Receiver" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <span style={{ fontSize: 'small', color: 'grey' }}>Type: </span>
          <TextField source="type" label="Type" />
        </Grid>
      </Grid>
      <TextDisplay source="text"  label="Text:" />
    </SimpleShowLayout>
  )
};

const ListFilter = (props) => {
  const id_hostel = getInfoValue('id_hostel')
  const hostelname = getInfoValue('hostelname')
  const {filterValues} = useListContext();
  const [filter, setFilter] = useState(filterValues);
  const rChoices = [
    { id: '1', name: 'H_Manager (1)', en: true },
    { id: '2', name: 'H_Staff (2)', en: true },
    { id: '4', name: 'H_Info (4)', en: true },
    { id: '8', name: 'A_DB (8)', en: isHauptadmin() },
    { id: '16', name: 'A_Support (16)', en: isHauptadmin() },
    { id: '32', name: 'A_Progr (32)', en: isHauptadmin() },
    { id: '64', name: 'Blocked (64)', en: true },
  ]

  const handleSetFilter = (ev, src) => {
    if (Array.isArray(ev)) {
      setFilter({...filter, [src]: ev});
    } else {
      setTimeout(() => {
        setFilter({...filter, [src]: ev?.target?.value});
      });
    }
    //ev.target.onChange(ev);
  }

  return (
    <Filter {...props}>
    {isHauptadmin() &&
      <SelectInput source="id_hostel" choices={[{ id: -1, name: "all Hostels" }, { id: 0, name: "[0] noHostel" }, { id: id_hostel, name: hostelname }]} onChange={(ev) => handleSetFilter(ev, 'id_hostel')} alwaysOn />
    }
    {isHauptadmin() &&
      <ReferenceInput source="tab" reference="repMessages/tables" perPage={1000} alwaysOn >
        <AutocompleteInput size="small" label="Table" onChange={(ev) => handleSetFilter(ev, 'tab')} />
      </ReferenceInput>
    }
      <ReferenceInput source="type" reference="repMessages/typelist" perPage={1000} filter={filter} alwaysOn >
        <AutocompleteInput size="small" label="Type" optionText="type" sx={{minWidth: '20em'}} />
      </ReferenceInput>
      <TextInput source="type2" alwaysOn label="Type" onChange={(ev) => handleSetFilter(ev, 'type2')} resettable/>
      <TextInput source="betreff" alwaysOn onChange={(ev) => handleSetFilter(ev, 'betreff')} resettable/>
      <CheckboxGroupInput source="receiver" choices={rChoices.filter(c => c.en === true)} onChange={(ev) => handleSetFilter(ev, 'receiver')} />
    </Filter>
  )
};

const ListForm = (props) => {

  const handleSendEmail = (ev, rec) => {
    console.log([ev, rec]);
    dataProvider.update('repMessages/sendmail', {
        id: rec?.id,
        data: {tab: rec?.tab}
    })
      .then(response => {
        console.log(response)
      })
      .catch(response => {
        console.log(response)
      });
  }

  const EmailButton = () => {
    const record = useRecordContext();
    if(!record?.email)return

    return (
      <Button onClick={(ev) => handleSendEmail(ev, record)} label="">
        <EmailIcon />
      </Button>
    )
  }

  return (
    <List title="bw.set.specialday.title" filters={<ListFilter />} sort={{ field: 'zeit', order: 'DESC' }} exporter={isHauptadmin() && props.exporter} empty={false} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <DateField source="zeit" showTime locales="de-CH" />
        <TextField source="id_hostel" label="Hostel" />
        <TextField source="receiver" label="R" />
        <TextField source="type" />
        <TextField source="betreff" />
      {isHauptadmin() &&
        <TextField source="id" textAlign="right" />
      }
        <ShowButton label="" />
        <EmailButton />
      </Datagrid>
    </List>
  )
}

const RepMessages = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} />
  ),

  show: ({ classes, ...props }) => (
    <Show title="bw.set.specialday.titleEdit" {...props}>
      <ShowMessage {...props} classes={classes} />
    </Show>
  )
}
export default RepMessages