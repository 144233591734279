import React, { useState } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, List, Create, Edit, Datagrid, SimpleForm, TextField, 
  EditButton, TextInput } from 'react-admin';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import Grid from '@mui/material/Grid';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const {classes, ...rest} = props;
  return (
    <List {...rest} title="bw.set.guestdetail.title" sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <TextField source="pos" textAlign="right" />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setGuestdetail" />
        <TextField source="titel_de" />
        <TextField source="titel_en" />
        <TextField source="id" textAlign="right" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const nums = [1,2,3,4,5,6];
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete/>} {...props}> 
      <TextInput source="titel_de" />
      <TextInput source="titel_en" />
      <Grid container style={{width:'100%'}}>
        <Grid item xs={12} md={3}>
          EN
        </Grid>
        <Grid item xs={12} md={3}>
          DE
        </Grid>
        <Grid item xs={12} md={3}>
          {translate('bw.set.guestdetail.options')+' EN*'}
        </Grid>
        <Grid item xs={12} md={3}>
          {translate('bw.set.guestdetail.options')+' DE*'}
        </Grid>
    {nums.map((i)=>(
        <React.Fragment key={i}>
          <Grid item xs={12} md={3} style={{paddingRight:'1em'}}>
            <TextInput source={"bez"+i+"_en"} fullWidth multiline label={translate('bw.set.guestdetail.desc') + ' '+i + ' EN'} />
          </Grid>
          <Grid item xs={12} md={3} style={{paddingRight:'1em'}}>
            <TextInput source={"bez"+i+"_de"} fullWidth multiline label={translate('bw.set.guestdetail.desc') + ' '+i + ' DE'} />
          </Grid>
          <Grid item xs={12} md={3} style={{paddingRight:'1em'}}>
            <TextInput source={"options"+i+"_en"} fullWidth multiline label={translate('bw.set.guestdetail.options') + ' '+i + ' EN'} />
          </Grid>
          <Grid item xs={12} md={3} style={{paddingRight:'1em'}}>
            <TextInput source={"options"+i+"_de"} fullWidth multiline label={translate('bw.set.guestdetail.options') + ' '+i + ' DE'} />
          </Grid>
        </React.Fragment>
    ))}
        <Grid item xs={12} md={6}>
        </Grid>
        <Grid item xs={12} md={6}>
          <p>{'* '+translate('bw.bwh.guestfield.optionsdesc')}</p>
        </Grid>
      </Grid>
    </SimpleForm>
  )
};

const GuestDetail = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.guestdetail.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.guestdetail.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default GuestDetail 