const langEN =
{
  bw: {
    bwh: {
      agent: {
        title: 'Display settings for each agent',
        NetPrice: 'Net price (excl. bookingfee)',
        NetPriceFee: 'Net price incl. bookingfee',
        NetPriceService: 'Net price + fixed services',
        GrossPrice: 'Gross price',
        NoResizer: 'No Resize',
        avPreview: 'Availability and price preview',
        PriceNoComm: 'Price on first Page',
        showavgprice: 'Show average price instead of total price',
        showtotservice: 'Show total price for services',
        resize: 'iframe resizer',
        css: 'Custom CSS',
        cssupload: 'Upload CSS file',
        cssremove: 'remove',
        redirect: 'Redirect to URL',
        avPreviewK: 'Preview',
        showavgpriceK: 'Day price',
        resizeK: 'Resizer',
        cssK: 'CSS',
        redirectK: 'Redirect',
        intLink: 'Website integration with Link',
        intWidget: 'Website integration with Widget',
        intIFrame: 'Website integration with iFrame',
        embedtitle: 'Embed BookWorldOnline into your website',
        language: 'Language',
        book: 'Booked by agent',
        noheadimg: 'No header image(s)',
        embed: 'Embed this code into your website',
        link: 'Place a link to this URL on your website',
      },
      discount: {
        title: 'Discount Codes',
        titleCreate: 'Create Discount Codes',
        titleEdit: 'Edit Discount Codes',
        prozent: 'percent',
        einmalig: 'onetime',
        nacht: 'per night',
        person: 'per person',
        person_nacht: 'per person and night',
        neu: 'New',
        benutzt: 'Used',
        inaktiv: 'Inactive',
        ankunft: 'Arrival',
        dauer: 'Nights',
        personen: 'Guests',
        ab: 'from',
        bisdatum: 'until',
        bis: 'to',
        force_agent: 'Switch agent to',
        dontchangeangent: 'no switch',
        error: {
          code: 'Only small letter, numbers and underline allowed.',
          codematch: 'For code <%{code}> discount has to be <%{rabatt}> and type has to be <%{typ}>.',
        },
      },
      guestfield: {
        title: 'Guest fields',
        titleEdit: 'Edit guest field',
        disp_opts: 'Display options',
        checkin: 'Check In',
        checkinlabel: 'Online Check-in',
        noShow: '-',
        orig_text: 'Original text',
        show: 'Show',
        req: 'Required',
        reqWoCc: 'Required, if no cc is necessary',
        noCheckin: '-',
        mainOpt: 'Main guest',
        allOpt: 'All guests',
        mainReq: 'Main (req)',
        allReq: 'All (req)',
        options: 'Select options',
        optionsdesc: 'One Option per line (store key as prefix with =)',
        validatedesc: 'email or regex',
      },
      hostel: {
        bedingung1: 'Condition 1',
        bedingung2: 'Condition 2',
        bedingung3: 'Condition 3',
        zahlunggelesen: 'Payment-Form-Checkbox',
        zahlunggeleseninfo: 'Payment-Form-Checkbox-Info',
        confirmtext: 'Booking-confirm',
        showbookingtext: 'Booking-display',
        confirminfotext: 'Confirmation information',
        cancelreservationtext: 'Cancellation confirmation',
        privacy: 'Privacy statement',
        contact: 'Impressum-Legal notice',
        resultfound: 'Offer found',
        noresultfound: 'No offer found',
        arrivaltimeoptions: 'Arrival time options',
        booknow: 'Button book',
        booknowweiter: 'Button book continue',
        booknowfinish: 'Button finalize booking',

        bild: 'Header image',
        map: 'Hostel Map',
        zusatzbild: 'Additional Image',
        favicon: 'Favicon',
      },
      image: {
        title: 'Hostel images',
        titleEdit: 'Edit image',
        titleCreate: 'Create image',
        art: 'Image type',
        pos: 'Position',

        error: {
          invalidPos: 'This position already exists. Please use another position.',
        },
      },
      rate: {
        titelField:'Title',
      },
      roomcategory: {
        title: 'Room categories',
        titleEdit: 'Edit room',
        all: 'All',
        bezeichnung: 'Category',
        titel: 'Title',
        kurzbeschr: 'Short Description',
        beschr: 'Description',
        onbwh: 'Online',
        associated_rooms: 'Associated rooms',
        units: 'Units (e.g. "bed/beds/person/persons")',
      },
      service: {
        title: 'Service',
        titleEdit: 'Edit service',
        bezeichnung: 'Service',
        titel: 'Title',
        kurzbeschr: 'Short Description',
        onbwh: 'Online',
        free_cancel_list: 'Free Cancel',
        free_cancel: 'Free Cancelation',
        anz_modifier: 'Condition',
        deactivate: 'Set title to "NotActive" to deactivated service for that language.',
      },
      settings: {
        titleEdit:'Edit BookWorldOnline settings',
        grund: 'Base',
        konfig: 'Configuration',
        konfig2: 'Configuration 2',
        raten: 'Rates',
        inaktiv:'inactive',
        test:'Test',
        aktiv:'Active',
        haupt:'Main system',

        MixedOnly: 'Mixed rooms only',
        MixedOnlyAndFemale: 'Mixed and Female',
        FemaleOnly: 'Female only',
        AllPossibilities: 'All possibilities',
        NoGenderFilter: 'No gender filter',
        NoDorm: 'No dorms',
        NoFilter: 'No accomodation filter',

        No: 'No',
        Optional: 'Optional',
        Mandatory: 'Mandatory',
        identDesc: 'With the BookWorldOnline system you automatically get the Internet Address (URL) <a href="https://%{bwh_ident}.%{bwh_domain}" target="_blank">%{bwh_ident}.%{bwh_domain}</a>.',
        bwh_def_personen: 'Default number of persons',
        bwh_anz_personen: 'Maximum number of persons',
        bwh_childalterskat: 'Age category for child prices',
        bwh_childalterskat_helpertext: 'Which age categorie to be used for children',
        bwh_childinprivat: 'Children in private rooms allowed',
        bwh_childinprivat_helpertext: 'Children in private rooms allowed',
        bwh_childindorm: 'Children in dorm rooms allowed',
        bwh_childindorm_helpertext: 'Children in dorm rooms allowed',
        bwh_anz_naechte: 'Maximum number of nights',
        bwh_genderchoice: 'Gender / accomodation filter',
        lastNewRes: 'Last new reservation',
        lastNewRes_helpertext: 'until x h before 24:00 of arrival day',
        lastChange: 'Last change/Cancellation',
        lastChange_helpertext: 'until x h before 24:00 of arrival day',
        bwh_gastfeldstorelan: 'Store booking language in guest field',
        bwh_gastfeldstorelan_helpertext: 'Select a guest field to store the booking language',
        bwh_gelesenhacken: 'Display checkbox to tick before booking',
        bwh_gelesenhacken_helpertext: 'You can configure checkboxes e.g. to show privacy terms.',
        bwh_hideprivatmarker: 'Hide private room marker',
        bwh_hideprivatmarker_helpertext: 'Hides the marker of private rooms in result list',
        bwh_usearrivaltime: 'Arrival time',
        bwh_checkintime: 'Earliest check-in time',
        bwh_checkouttime: 'Latest check-out time',
        bwh_usearrivaltime_helpertext: 'Should guest set an arrival time?',
        bwh_child_min_age: 'Minimal child age',
        bwh_child_max_age: 'Maximal child age',
        bwh_maplink: 'Special Google map Link',
        bwh_mapapikey: 'Special Google map API key',
        bwh_analyticskey: 'Google Analytics key',

        error: {
          DefaultLang: 'Default language must be a part of the list of languages.',
          NoLang: 'There must be at least one language in the list of languages.',
          UseOnlyLetters: 'Not a valid host name. Use only lowercase letters, digits and hyphen (-).',
          TheAddressAlreadyExists: 'The address already exists.',
        },

        google: 'Google options',

        BookingFee: 'Booking fee',
        noFee: 'No booking fee',
        worldPay: 'WorldPay',
        stripe: 'Stripe',
        storePaymentInfo: 'Store payment information',
        noneExternal: 'No storage/external',
        local: 'local storage',
        cvv: 'CVV Number',
        noEntry: 'No entry',
        optional: 'Optional',
        required: 'Required',
        prefAddGast: 'Never update guests',
        update: 'update existing guest if match',
        alwaysNew: 'always add a new guest',
        ccTypes: 'Credit Cards',
        visa: 'Visa',
        master: 'Master',
        amex: 'AmEx',
        diners: 'Diners',


        MultiRoom: 'Multi-Room',
        SplitGroupInto2:  'Allow splitting in 2 Rooms',
        Split2Ex : 'z.B. 4,3 / 3,3',
        SplitGroupInto3:  'Allow splitting in 3 Rooms',
        Split3Ex : 'z.B. 4,3,2 / 3,2,2 / 2,2,2',
        SplitGroupIntoMoreThan3: 'Allow splitting in more than 3 Rooms',
        SplitMoreEx : 'z.B. 3,2,2,2 / 4,3,2,2',
        anzsingle : 'Max. Number of single rooms',
        disInfo1: 'Multi-Room allows booking of more than one private room with a single booking.',
        disInfo2: 'This feature is currently disabled for you. To have it enabled please contact',

        roomchange: 'Room-change',
        roomchange2: 'Allow room change during stay',
      },
      text: {
        title: 'Hostel texts',
        titleEdit: 'Edit hostel text',
        titleCreate: 'Create hostel text',
        imgTitle: 'Illustration',
        art: 'Text type',
        pos: 'Position',
        agent: 'Text for Agent',

        formatBlockTitle: 'Format description',
        formatTitle: 'Formatting',
        formatBold: '*bold*',
        formatItalic: '_italic_',
        formatHeading: 'h1. Heading',
        formatSubheading: 'h2. Subheading',
        ulTitle: 'Unordered List',
        ulOne: '* Item 1',
        ulTwo: '* Item 2',
        ulThree: '* Item 3',
        olTitle: 'Ordered List',
        olOne: '# Item 1',
        olTwo: '# Item 2',
        olThree: '# Item 3',
        allEntries: 'Show all',

        error: {
          wrongType: 'You can only delete text of types: "Confirmation information" or "Payment-Form-Checkbox-Info"',
          invalidPos: 'This position already exists. Please use another position.',
        },
      },
    },
    cal: {
      mwst: {
        title: 'Split VAT: ',
        titleCreate: 'Add entry',
        titleEdit: 'Edit entry',
        info: 'List of items which are included in the general price of a night, but have another VAT rate. The general defined VAT rate is displayed above.',
        empty: 'Empty bed',
        all: 'All',

        preisgr: 'Price group',
        preiskat: 'Room category',
        alterskat: 'Age category',
        text_de: 'Text German',
        text_en: 'Text English',
        text_fr: 'Text French',
        betrag: 'Price',
        type: 'Price type',
        mwst: 'VAT',

        createInfo: 'For each of these field combinations an entry will be created, if not already existing.',

        fix: ' (Fix)',
        fixSep: ' (Fix), separated',
        brut: '% (gross)',
        brutSep: '% (gross), separated',
      },
      online: {
        title: 'Online groups',
        titleCreate: 'add entry',
        titleEdit: 'edit entry',
        showall: 'Show All',
        compare: 'Compare',
        onlinegroup: 'Online group',

        copytitle: 'Copy online group',
        copyinfo: 'The entries of the selected online group will be copied. Please provide a name:',

        error: {
          deleteFailed: 'Could not delete online group. It is still assigned to date between %{1} and %{2}.',
        },
      },
      onlinecompare: {
        details: 'Details (id)',
        details2: 'Details (description)',
        strasse: 'Portal',
        pkbez: 'Room category',
        showall: 'Show All',
        zimmerb: 'Room',
      },
      oportal: {
        title: 'Online portals',
        titleCreate: 'add entry',
        titleEdit: 'edit entry',
        firma: 'Portal',
        id_agent: 'Portal',
        id_preiskat: 'Room category',
        id_online_gr: 'Online group',
        farbe: 'Color',
        copyTitle: 'New name',
        kontingent: 'Contingent / Bed(s)',
        freihalten: 'Keep free / Bed(s)',
        min_nacht: 'Minimum duration',
        max_nacht: 'Maximum duration',
        noarrivalwd: 'No arrival',
        nodeparturewd: 'No departure',
        firstres: 'First new reservation',
        lastnewres: 'Last new reservation',
        lastchange: 'Last change',
        lastminstart: 'Start',
        lastminend: 'End',
        room: 'Room',
        online: 'Online',
        bed: 'Bed',
        sperr_belegung: 'Occupation*',
        sperr_belegung_info: '* Occupations (separated by \',\') in the edit field at the right are not available online for that room.',
        emptyPortal: 'All portals',
        emptyRoomcat: 'All Roomcategories',
        ankunftstag:'arrival day',
        tag:'day',
        tage:'days',
        woche:'week',
        wochen:'weeks',
        cleanup: 'Remove assignment',
        cleanupInfo: 'The following room assignment will be removed:  |||| The following %{smart_count} room assignments will be removed: ',
        multiedit: 'Multiedit',
        multicopy: 'Copy to online group',
        copyinfo: 'Change the field',
        copyinfo2: 'for all selected rows and set the value to',
        copyinfo3: 'All selected rows will be copied to:',
        MBookingInfo: '<b>Main booking period</b><br>In days before 24:00 of arrival day (e.g. 0.75 => 06:00 AM of arrival day)<br>A value of -1 in both fields corresponds to the global settings.',
        SBookingInfo: '<b>Secondary booking period</b> (Last Minute)<br>In hours before 24:00 of arrival day (e.g. 18 => 06:00 AM of arrival day)<br>A value of -1 in both fields corresponds to the global settings.',
        meField: 'Field',
        meValue: 'New value',
        me: {
          kontingent: 'Contingent',
          freihalten: 'Keep free',
          min_nacht: '*Minimum duration',
          max_nacht: '*Maximum duration',
          noarrivalwd: '*no arrival',
          nodeparturewd: '*no departure',
          firstres: 'Main booking period -> first new reservation',
          lastnewres: 'Main booking period -> last new reservation',
        },
        error: {
          copyMessage:'Some rows are already in the target group. They were not copied.',
          duplicateEntry: 'Duplicate entry: This combination of agent and room category does already exist.',
          validateSperrBelegung: "Invalid occupation: Only numbers, 'm', 'f', 'x' and ',' allowed",
          validateFirstLastres: "the fields 'First new reservation' and 'Last new reservation' either both or none should be set to -1.",
          validateLastminStartEnd: "the fields 'Start' and 'End' either both or none should be set to -1.",
          missConfig: "Missconfiguration detected - ",
          missConfig2: "The following room is assigned to the wrong roomtype:  |||| The following %{smart_count} rooms are assigned to the wrong roomtype: ",
        },
      },
      pricegr: {
        title: 'Price',
        titleCreate: 'Add entry',
        titleEdit: 'Prices',
        editMwst: 'VAT',
        editRate: 'Rates',
        filterTitle: 'List filter:',
        showall: 'Show All',
        compare: 'Compare',
        compareRate: 'Compare (Rate)',
        compareVAT: 'Compare (VAT)',
        createInfo: 'For each of these field combinations an entry will be created, if not already existing.',

        copytitle: 'Copy price definition',
        copyinfo: 'The entries of the selected definition will be copied. Please provide a name:',

        error: {
          deleteFailed: 'Could not delete price definition. It is still assigned to date between %{1} and %{2}.',
        },
      },
      pricegrcompare: {
        bezeichnung: 'Description',
        alterskat: 'Age category',
        belegung: 'Occupation',
        filterTitle: 'List filter:',
        showall: 'Show All',
      },
      pricegrcomparerate: {
        rate: 'Rate',
      },
      rate: {
        title: 'Rates',
        preisgr: 'Price group',
        titleCreate: 'Add entry',
        titleEdit: 'Edit entry',
        info: 'Activate and adjust prices for rates of connected system (interfaces)',
        filterInfo: 'Show rooms/price based on online group: ',

        name: 'Description',
        rooms: 'Rooms',
        price: 'Base price',
        empty: '** Undefined **',
        copy: 'copy to all',
      },
      portal: {
        firma: 'Portal',
        kontingent: 'Cont.',
        freihalten: 'keep free',
        id_preiskat: 'Room category',
        zimmer: 'Room',
        min_nacht: 'Min',
        max_nacht: 'Max',
        noarrivalwd: 'n.Arr.',
        nodeparturewd: 'n.Dep.',
        firstres: 'First',
        lastnewres: 'New',
        lastchange: 'LM Change',
        lastminstart: 'LM Start',
        lastminend: 'LM End',
      },
      service: {
        title: 'Services',
        titleCreate: 'add services',
        titleEdit: 'edit service',
        showall: 'Show All',
        servicegroup: 'Service group',

        copytitle: 'Copy service group',
        copyinfo: 'The price entries of the selected group will be copied to the new group. Please provide a name for the new group:',

        error: {
          deleteFailed: 'Could not delete service definition. It is still assigned to date between %{1} and %{2}.',
        },
      },
      serviceprice: {
        title: 'Service prices',
        titleCreate: 'add service price',
        titleEdit: 'edit service price',
      },
      yield: {
        confirmTitel:'Unsaved changes',
        confirmText:'Discard all changes?',
        save:'Save',
        priceDisplay:'Price display',
        def:'Default',
        det:'Details',
        avg:'Average booked',
        limits:'Limits',
        days:'Days',
        date:'Date',
        empty:'Do not count empty beds',
        groups:'Groups',
        occ:'Occupation',
      }
    },
    dashboard: {
      past: 'Past',
      future: 'Future',
      month:'1 Month |||| %{smart_count} Months',
      restricted_text:'<h2>Access restricted</h2>There is an access restriction.<br>All system work in the background as usual.<br>Please contact us immediately:<br><br>alixon (Andreas/Hansj&uuml;rg):<br>+41-62-511-22-34<br><a href="mailto:support@book.world">support@book.world</a><br><br>',
      resolved:' resolved since ',
      contact: {
        title:'Send your Request',
        error:'Error! Message could not be sent.',
        property:'Property',
        user:'User',
        email: 'Email',
        subject: 'Subject',
        text: 'Text',
        sent: 'Message has been sent',
        send: 'Send Request',
      },
      info_archive: {
        title: 'Information archive'
      },
      incident_archive: {
        title: 'Archive of incidents', 
        incident: 'Incident: ',
        resolved: 'Resolved since '
      },
      incident_edit: {
        title: 'Edit incidents', 
        startDate: 'Start Date', 
        endDate: 'End Date', 
        incident: 'Incident',
        maintenance: 'Maintenance',
        breakdown: 'Breakdown',
        other: 'Other',
        update: 'Update',
        resolved: 'Resolved since '
      }
    },
    if: {
      list: {
        titleAdditional: 'Additional available interfaces',
      },
      portal: {
        tab1: 'Availabilities',
        tab2: 'Actions',
        tab3: 'Settings',
        UpdateRoomtypesText: 'room types from portal',
        UpdateRoomtypesButton: 'Start import',
        chLoginname:'change Loginname',
        changePwd: 'change Password',
        ReservationHolen: 'import Reservation from portal',
        importRes: 'The reservation is scheduled to be imported.',
        importRoomTypes: 'The room types are schedules to be imported. An e-mail will be sent upon finish.',
        importRoomTypesHTML: 'The room types are imported.',
        GlobalRoomAssignment: 'Room type assignment',
        chGlobalRoomAssignment: 'save assignment',
        ManualRoomInformation: 'Room details',
        chManualRoomInformation: 'Save room informations',
        roomsize: 'Room size',
        roomcnt: 'number of rooms',
        defaultsize: 'minimum occupancy',
        prtAvailShow: 'show portal availabilities (number of beds/rooms)',
        Einrichten: 'Configure',
        findHB_hostelnumber: 'search a booking and take the number before the "-" (eg Booking number 39424-2980406, so the Hostelnumber is 39424) '+
                              'or search the Hostel on hostelbookers.com take the Link to the detail information and '+
                              'look for the enclosed value "intpropertyid".',
        InterfaceUp: 'Interface running',
        InterfaceDown: 'Interface stopped',
        changeUp: 'start Interface',
        changeDown: 'stop Interface',
        updateAvailText: 'Availabilities/Prices',
        updateAvailButton: 'set all',
        updateAvailConfirm: 're-send of all availabilities and prices is scheduled.',
        resetCacheText: 'Portal cache',
        resetCacheButton: 'Reset',
        resetCacheConfirm: 'reset of portal cache done.',
        FirstErrorResetText: 'FirstError',
        FirstErrorResetButton: 'Reset (FirstError)',
        FirstErrorResetConfirm: 'Reset of FirstError flag is scheduled.',
        save_config_fields: 'save values',
        corruptTitel: 'Not configured.',
        corruptText : 'The interface is not configured correctly. Please contact support.',
        bed:'Bed',
        beds:'Beds',
        room:'room',
        rooms:'rooms',
        days: 'days',
        ratelegende:'Rates: Price (rounded)',
        c_cribPrice:'crib price',
        c_meals:'meals',
        c_advance:'last new reservation (days)',
        c_guarantee:'guarantee',
        inboxPasswort:'Inbox user',
        inboxUser:'Inbox password',

        roomdetails:'Room details',
        saveroomdetails:'Save Room details',
        gender0:'Dormitory female',
        gender1:'Dormitory male',
        gender2:'Dormitory mixed',
        gender4:'Private room',
        gender:'Gender',

        timeSync:'Synchronisation period:',
        timeNow: 'Current Time at hostel (timezone):',
        timeLast: 'last new reservation for arrival today:',
        timeSet: 'set',
      }
    },
    glob: {
      hostel:{
        basis: 'base',
        abrechnung: 'Accounting',
        res_email_help: 'This e-mail address is used for reservation confirmations (DormOnline and Interfaces)',
        koordinaten_help: 'Example: -5.214569',
        max_dormbook_help: '(for each additional licence EURO 10.- per month will be charged)',
        validate_cc_text: 'Only capital letters A-Z, digits 0-9 or spaces. Max 22 character.',
        validate_login_name: 'Invalid login name. Only small letters, numbers and \'_\' allowed. The login has to start with a letter',
        validate_max_dormbook: 'The number of licences must be a number.',
        copytitle: 'Copy Hostel',
        copyhostel_info: 'To copy a hostel use # echo {hostel_id} >/var/log/alixon/copyhostel',
      },
    },
    rep: {
      bookingcom: {
        noFile: 'no file selected',
        WarnText: 'Attention: The csv the file is only accepted if it was generated in the extranet with English language settings!',
        UploadText: 'Upload Statement from booking.com in CSV Format',
        StartCheck: 'Start Check',
        StartExcel: 'Start Excel',

        resStatement: 'Reservation Statement',
        resOptions: 'Options',
        resCompareNetto: 'Compare amount with netto (price excluding services)',
        resComparePayment: 'Compare amount with payments (all registered payments)',
        payoutStatement: 'Payout Statement',
      },
      documents: {
        download: 'download',
        show: 'show',
      },
      gdpr: {
        searchtitle: 'E-Mail or guest id',
        searchbutton: 'Search',
        clearmailtxtbutton: 'clear mail text',
        guestremarktitle: 'Guest remark',
        General: 'General',
        reservations: 'Reservations',
        resno: 'Res #',
        status: 'Status',
        arrival: 'Arrival',
        departure: 'Departure',
        remark: 'Remark',
        erasereference: 'Erase request reference',
        erasebutton: 'Erase all personal information',
        wronghostel: 'wrong hostel id',
      },
      guestexport: {
        datafields: 'Datafield selection',
        email: 'E-mail address',
        address: 'Full address',
        allfields: 'All fields',
        gastcats: 'Guest categories',
        allGuests: 'All guests',
        resfilter: 'additional reservation filter',
        allres: 'All reservations',
        arrival: 'Arrival',
        stay: 'Stay',
        departure: 'Departure',
        startdate: 'Start date',
        enddate: 'End date',
        agents: 'Additional agent filter',
        allAgents: 'All Agents',
        Export: 'Excel Export',
        dummy:''
      }
    },
    set: {
      agecategory: {
        title: 'Age Categories',
        titleCreate: 'Create Age Category',
        titleEdit: 'Edit Age Category',
        description: 'Online description',
        help:'Singular/plural (e.g. Child/Children)',
        help2:'Categories with identical descriptions will be grouped together in the booking process and in confirmations.',
      },
      agent: {
        title: 'Agent',
        titleCreate: 'Create Agent',
        titleEdit: 'Edit Agent',
        id_agent: 'Agent',
        id: 'Id',
        firma: 'Organisation',
        plz_ort: 'Zip / City',
        code: 'Code',
        kontakt: 'Contact',
        strasse: 'Address',
        land: 'Country',
        tel: 'Phone',
        fax: 'Fax',
        email: 'Email',
        emailtype: 'Send confirmation e-mails to',
        bank: 'Bank name',
        postkonto: 'Post Account',
        bankclearing: 'Bank clearing',
        kontonummer: 'Account Number',
        mwst: "VAT No.",
        error: {
          deletefailed: 'There are contracts assigned to this agent. Delete refused.',
          maxlength: 'Maximum length cannot be greater than %{max}',
          regex: 'invalid Code: only numbers (0-9), letters(a-zA-Z) and \'_\' allowed',
        },
      },
      agentcontract: {
        title: 'Agent contracts',
        titleCreate: 'Create Agent contract',
        titleEdit: 'Edit Agent contract',
        id_agent: 'Agent',
        color: 'Color',
        kom_buchung: 'Commission booking',
        kom_buchung_help: '%, negative Values: Service Charge in Main Currency',
        kom_buchung_unit: 'Unit',
        id_zahlungsart_react: 'Down payment',
        bwo: 'BookWorldOnline',
        ohne_kk: 'Booking without credit card',
        aufpreis: 'Commission on top of price',
        bookingfee: 'CC Booking fee',
        kom_link: 'Commission link',
        kom_link_help: 'Only used for reports',
        bruttopreisbuchen: 'Book gross price',
        nettopreisbuchen: 'Book net price',
        bwoNo: 'No BWO',
        bwoNR: 'No Rates',
        bwoMR: 'Use Rates',
        pricePaymentSettings: 'Price / Payment settings',
        buchungAgentWebsite: 'Booked on agents website',
        bookingChannelInternet: '(Booking Channel: Internet)',
        buchungDurchAgent: 'Booked by Agent',
        bookingChannelAgent: '(Booking Channel: Agent) [param: book]',
        error: {
          deletefailed: 'An agent contract can not be deleted after setting up an automatic interface!',
          posReordered: 'position re-ordered!',
          kom_buchung: 'Commission booking must be smaller than 101',
          kom_buchung_min_kom: 'The price plan requires a minimum commission of %{value}',
          kom_buchung_fix: 'Commission booking must be %{value}',
          kom_buchung_unit: 'The unit must be on currency',
          kom_link: 'Commission link must be smaller than 100',
          onTopNull: 'Commission on top of price needs to be deactivated',
          setNoRates: "BookWorldOnline needs to be set to 'No Rates'",
          setZaBrutto: "Down payment needs to be set to 'Book gross price'",
          bookingfeeOn: "Buchungsgebühr KK needs to be aktivated",
          id_agent: "Please select an agent",
        },
      },
      bed: {
        title: 'Beds',
        titleCreate: 'add bed',
        titleEdit: 'edit bed',
        selectroom: 'Select room',
        errnodata: 'Bed could not be deleted as there are still bookings found',
      },
      bookingsheet: {
        title: 'Booking sheets',
        titleCreate: 'add booking sheet',
        titleEdit: 'edit booking sheet',
        helptitle: '[Title];[E-mail subject];[mark] (e.g.: CheckOut E-Mail;Thanks for staying with us;@)',
        helppdfconf: '[only available for Supporters]<br>'+
                     'border: all[mm] <br>'+
                     'border: topbottom[mm], rightleft[mm] <br>'+
                     'boder: top[mm], right[mm], bottom[mm], left[mm] <br>'+
                     'border-left: left[mm] <br>'+
                     'papersize: <b>A4</b> | Letter | width[mm], height[mm] <br>'+
                     'textfont: courier | <b>helvetica</b> | monospace | sans | serif | times <br>',
      },
      dbuser: {
        title: 'Users',
        titleCreate: 'Create User',
        titleEdit: 'Edit User',
        active: 'Active',
        activate: 'Aktivate / Set passwort',
        locked: 'Locked',
        unused: 'Unused',
        inactive: 'Inactive',
        loginname: 'Username',
        name: 'Name',
        email: 'Email',
        id_db_loginlevel: 'user group',
        id_fw_gruppe: 'user group admin',
        newpwd: 'Set new password',
        activatetitle: 'Activate User / Set new password',
        newPasswordInfo: 'New password for user ',
        yubico: 'Yubico',
        yubicohelper: 'If you don\'t have a yubico key, leave this field empty. Else simply trigger your yubico key in the field.',
        error: {
          invalidChars: 'Invalid characters in login name. Allowed: a-z, A-Z, 0-9!',
          reactivateUser: 'There is a deactivated user with this username - to reactivate click the linkk below!',
          doubleEntry: 'Duplicate entry: This loginname does already exist.',
          invalidEmail: 'Invalid email address',
        },
      },
      guestcategory: {
        title: 'Guest Categories',
        titleCreate: 'Create Guest Category',
        titleEdit: 'Edit Guest Category',
      },
      guestdetail: {
        title: 'Guest Fields',
        titleCreate: 'Guest Field erstellen',
        titleEdit: 'Guest Field bearbeiten',
        options: 'Select options',
        desc:'Description',
      },
      hostel: {
        titleEdit: 'Property Settings',
        property: 'Property',
        map: 'Map',
        invoice: 'Invoicing',
        login_name: 'Log-in name',
        hostel: 'Name',
        name: 'Contact person',
        cc_text: 'CC reference',
        strasse: 'Address',
        plz: 'ZIP',
        ort: 'City',
        land: 'Country',
        region: 'State/Prefecture',
        telefon: 'Phone',
        email: 'Email',
        res_email: 'Email 2',
        res_email_desc: 'This e-mail address is used for reservation confirmations (BWO and Interfaces)',
        url: 'Website',
        mapdesc:'Move the map to place the pin at your location.',
        max_dormbook: 'DormBook licences',
        interfacemin: 'min invoiced interfaces',
        preisplan: 'Price plan',
        id_reseller: 'Reseller',
        id_2supporter: '2nd Level Support',
        id_supporter: 'Supporter',
        id_kundeabrechreseller: 'Invoicing',
        id_kundeabrechnung: 'Customer number',
        setupfee: 'Setupfee charged',
        id_promotinghostel: 'Promotion reduction for hostel',
        rabatt: 'Reduction',
        rabattart: 'Reduction type',
        credituse: 'Use of credits',
        resellerremark: 'Remarks: from reseller (e.g. pricing, special deals)',
        cost:'Costs',
        monthly:'Monthly costs',
        balancerep:'Balance report',
        date:'Date',
        amount:'Amount',
        balance:'Balance',
        config:'Configuration',
        timeZone:'Time zone',
        timeZoneInfo:'Please select the time zone the hostel is in.',
        currency0:'Main currency',
        currency0Info:'Please use currency codes according to the ISO specification: http://en.wikipedia.org/wiki/ISO_4217',
        currency1:'Currency 1',
        currency1Info:"needs to be '&#8364; %01.2f' for the GOMIO-interface",
        currencyRate1:'Exchange rate 1',
        currency2:'Currency 2',
        currencyRate2:'Exchange rate 2',
        noSplitVat:'No Split VAT',
        splitVat:'Split VAT',
        splitVatInfo:'Split the amount for the stay into partial amounts with different VAT rates',
        noVat:'Without VAT',
        normalVat:'Publish prices with Vat',
        noVatPaid:'Assume tax of agent commission as paid',
        noVatAdd:'Tax of agent commission added to the due amount',
        noVatInfo:'Publish the prices in DormOnline and all the Interfaces without VAT',
        pluginOccHistory:'Plugin: occupation History',
        pluginOccHistoryInfo:'Regularly save the availabilities',
        pluginOccHistoryState_0:'not active',
        pluginOccHistoryState_1:'active',
      },
      paymenttype: {
        title: 'Payment types',
        titleCreate: 'Create Payment Type',
        titleEdit: 'Edit Payment Type',
      },
      payrequest: {
        title: 'Payment time ',
        prozent: '%',
        fix: 'fix',
        naechte: 'Nights',
        rest: 'remaining amount',
        buchung: '@ booking',
        nachbuchung: 'days after booking',
        vorankunft: 'days before arrival',
        checkin: '@ check-in',
      },
      prefcountry: {
        title: 'Main Countries',
        titleCreate: 'Add Country',
        titleEdit: 'Edit Country',
      },
      pwdyubi: {
        titleEdit: 'Change password',
        pwdtitle: 'Change password for: ',
        pwdinfo: 'This is to change the password for your account in admin.book.world, resp. secure.dormproject.ch.',
        pwdinfo2: 'The password for your user in the Book.World Desktop-App (DormBook) can be changed in the app.',
        passwort: 'New Password',
        passwort2: 'Password retype',
        pwdErr: 'Password and retype don\'t match.',
        yubititle: 'Yubico',
        yubiinfo: 'The yubico key %{yubi} is assigned to your user.',
        yubico: 'USB key for access control',
        yubicohelp: 'simply trigger your yubico key in the field.',
        yubicodel: 'clear yubico key',
      },
      rate: {
        title: 'Rates',
        titleEdit: 'Edit Payment Options',
        cancelpoints:'Cancelation restrictions',
        actionNo:'No Action',
        actionCancel:'Cancel reservation',
        id_payrequest: 'Payment points',
        id_storno: 'Cancelation restrictions',
      },
      room: {
        title: 'Edit rooms',
        titleCreate: 'Add Room',
        titleEdit: 'Edit Room',
        editBeds: 'Edit beds',
        bedcount: 'Number of beds',
        errnodata: 'Room could not be deleted as there are still beds defined.',
        bezeichnungInfo: 'For each line in this field, a room will be created with the parameters below.',
        dokuLink: '<a href="https://docs.dormproject.ch/en/konfiguration---dormonline/zimmer/zimmer-online.html" target="_blank" rel="noopener noreferrer" >Documentation</a>',
      },
      roomcategory: {
        title: 'Room Category',
        titleCreate: 'Add Room Category',
        titleEdit: 'Edit Room Category',
        rooms: 'Assigned rooms',

        in_bfs: 'Stat',
        in_daily_list: 'Day',
        in_report: 'Rep',
        room_cnt: 'Rooms',

        dormbookSetting: 'DormBook Setting',
        dormonlineSetting: 'DormOnline Setting',
        reportSetting: 'Report Setting',
        units: 'Units',
        units_info: 'Dormitory and private rooms (dormitory singluar/plural/private singular/plural)',
        bwoSetting: 'BookWorldOnline Setting',
        roomSetting: 'Room Setting',
        
        errRoomNotEmpty: "Room category can't be deleted, since there are still rooms assigned. First reassign the rooms to new categories.",
      },
      roomcattext: {
        textBWO: 'BWO Roomcategory Texts',
      },
      service: {
        title: 'Additional services',
        titleCreate: 'Add additional services',
        titleEdit: 'Edit additional services',
        anz_modifier: 'Condition',
        anz_modifier_comment: "separate values by | or newline<br><b>options/example:</b><br>LimitNightsMax=7<br>MinNights=8<br>MaxNights=2<br>ConnectedRoomtypes={id},{id}<br>ConnectedAgecategories={id},{id}<br>ConnectedAgent={id},{id}<br>ChildService={id},{id}<br>UnsetService={id},{id}",
      },
      servicetext: {
        textBWO: 'BWO Additional services Texts',
      },
      specialday: {
        title: 'Special days',
        titleCreate: 'Add Day',
        titleEdit: 'Edit Day',
        searchDate:'Filter date',
        searchDesc:'Search for description',
        text: 'Online text',
        copytitle: 'Copy date information',
        copyinfo: 'The settings of the selected date are copied to all dates between (and including) first and last date.',
        overwrite: 'All current settings are overriden without confirmation.',
        errnodata: 'Date is not defined. Please set it at «Calendar».',
      },
    },
  },
  glob: {
    actualPicture: 'uploaded picture',
    confirm: 'Confirm',
    copy: 'Copy',
    imgremove: 'remove',
    close: 'Close',
    move: 'Move',
    activate: 'Activate',
    uploadPicture: 'upload picture',
    columns: 'Columns',
    day: {
      short: {
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',
      },
    },
    deftypes:{
      one: '* 1',
      num_nights: '* # of nights',
      num_person: '* # of person',
      num_night_person: '* # of nights * # of person',
      net_price: '* net price (stay)',
      price_per_night: '* price per night (average)',
    },
    dormbook:{
      not_accessible: 'not available',
      only_pdf: 'only pdf document',
      only_email: 'only e-mail',
      email_and_pdf: 'pdf document and e-mail',
    },
    dormbookdata:{
      per_group: '1x per reservation group',
      per_reservation: '1x per reservation',
      per_guest: '1x per guest',
    },
    error:{
      wrongHostel: 'Wrong Hostel',
      noAccess: 'No Access',
      dbError: 'Could not write to database',
    },
    gender:{
      label: 'Gender',
      woman: 'Woman',
      man: 'Man',
      mixed: 'Mixed',
      empty: 'Empty',
      private: 'Private',
    },
    language:{
      de: 'German',
      en: 'English',
      fr: 'French',
    },
    agentEmailType:{
      guest: '(0) Guest',
      guest_agent: '(1) Guest + Agent',
      agent: '(2) Agent',
    },
    onlinechoices:{
      not_online: 'Not online',
      cons_book_woman: 'Consider bookings, Women',
      cons_book_man: 'Consider bookings, Men',
      cons_book_woman_man: 'Consider bookings, Women and Men',
      cons_book_mixed: 'Consider bookings, Mixed',
      cons_book_woman_mixed: 'Consider bookings, Women and Mixed',
      cons_book_man_mixed: 'Consider bookings, Men and Mixed',
      cons_book_all: 'Consider bookings, All options',
      ign_book_woman: 'Ignore bookings, Women',
      ign_book_man: 'Ignore bookings, Men',
      ign_book_mixed: 'Ignore bookings, Mixed',
    },
    onlinetypes:{
      not_online: 'Not Online',
      checkbox: 'Checkbox',
      num_selectable: '# selectable',
      fix: 'Fix',
    },
    resstatus:{
      stat0: 'normal',
      stat1: 'temporary',
      stat2: 'fix',
      stat3: 'checked in',
      stat4: 'no show',
      stat5: 'cancelled',
      stat6: 'checked out',
    },
    servermsg:{
      paybill: 'We have not yet registered your payment for the open invoice(s).',
      accountcancelledNotPaid: 'We have not yet registered your payment for the final invoice(s). This account will be canceled. Please run any necessary reports prior to the cancellation date. We will thereafter archive/delete your data in accordance with our internal policies.',
      accountcancelled: 'This account will be canceled. Please run any necessary reports prior to the cancellation date. We will thereafter archive/delete your data in accordance with our internal policies.',
    },
    pwdremark: '-> Please make sure to write down this password!',
    pwdremark2: 'Note: this password has to be changed on first login!',
    pwdcopied: 'The password was copied',
    setpwd: 'Set password',
  },
  resources: {
    bwhAgent: {
      fields: {
        q: 'Search',
      },
    },
    bwhDiscount: {
      name: 'discount code |||| discount codes',
      forcedCaseName: 'discount code |||| discount codes',
      fields: {
        rabatt: 'Discount',
        rabatt_typ: 'Discount type',
        status: 'State',
        ankunft_min: 'Arrival from',
        ankunft_max: 'Arrival until',
        personen_min: 'Min. persons',
        personen_max: 'Max. persons',
        dauer_min: 'Min. stay ',
        dauer_max: 'Max. stay',
        abgelaufen: 'Expired codes',
      },
    },
    bwhGuestfield: {
      fields: {
        q: 'Search',
        orig_text: 'Original text',
        disp_opts: 'Display options',
        checkin: 'Check-in',
        validate: 'Validation',
      }
    },
    bwhImage: {
      name: 'Image |||| Images',
      forcedCaseName: 'Image |||| Images',
      fields: {
        art_lbl: 'Image type',
        art_lbl_tr: 'Image type',
        text: 'Image',
        id_element: 'Position',
      },
    },
    bwhRoomcategory: {
      fields: {
        bezeichnung: 'Category',
        img: 'Image',
        id_element: 'Position',
        not_onbwh: 'Not Online',
      },
    },
    bwhService: {
      fields: {
        bezeichnung: 'Service',
        img: 'Image',
        not_onbwh: 'Not Online',
      },
    },
    bwhSettings: {
      fields: {
        bwh_status: 'Status',
        bwh_defaultsprache: 'Default language',
        bwh_sprachen: 'Available languages',
        bwh_ident: 'Domain prefix',
        bwh_domain: 'Domain',
      },
    },
    bwhText: {
      name: 'Text |||| Texts',
      forcedCaseName: 'Text |||| Texs',
      fields: {
        art_lbl: 'Text type',
        id_agent: 'Agent',
        id_element: 'Position',
      },
    },
    calPricegr: {
      name: 'price group |||| price groups',
      forcedCaseName: 'price group |||| price groups',
      fields: {
        q: 'Search',
        bezeichnung: 'Description',
        mwst: 'VAT',
        preiskat: 'Room category',
        alterskat: 'Age category',
        belegung: 'Occupation',
        preis: 'Price',
        pricezero: 'Price = 0',
      },
    },
    calOnline: {
      name: 'online group |||| online groups',
      forcedCaseName: 'online group |||| online groups',
      fields: {
        q: 'Search',
        bezeichnung: 'Description',
        farbe: 'Color',
        copyTitle: 'New name',
      },
    },
    calOportal: {
      fields: {
        firma: 'Portal',
        kontingent: 'Cont.',
        freihalten: 'keep free',
        id_preiskat: 'Room category',
        min_nachtA: 'Min',
        max_nachtA: 'Max',
        noarrivalwd: 'n.Arr.',
        nodeparturewd: 'n.Dep.',
        firstres: 'First',
        lastnewres: 'New',
        lastchange: 'LM Change',
        lastminstart: 'LM Start',
        lastminend: 'LM End',
        zimmer: 'Rooms',
      },
    },
    calService: {
      name: 'service |||| services',
      forcedCaseName: 'service |||| services',
      fields: {
        q: 'Search',
        bezeichnung: 'Description',
        farbe: 'Color',
        copyTitle: 'New name',
        zusatzleistung: 'Service',
        preis: 'Price',
        mwst: 'VAT',
        online_type: 'Online',
      },
    },
    globHostel: {
      fields: {
        q: 'Search',
        hostel_id: 'Hostel ID',
        hostel: 'Hostel',
        cc_text: 'CC refferer',
        name: 'Contact Name',
        strasse: 'Address',
        plz: 'ZIP',
        land: 'Country',
        region: 'State/Prefecture',
        latitude: 'Latitude',
        longitude: 'Longitude',
        telefon: 'Phone',
        fax: 'Fax',
        email: 'E-Mail',
        res_email: 'E-Mail 2',
        url: 'URL',
        titel: 'URL',
        text_de: 'Text German',
        text_en: 'Text English',
        text_fr: 'Text French',
        max_dormbook: 'DormBook licences',
        Preisplan: 'Price plan',
        interfacemin: 'min invoiced interfaces',
        id_reseller: 'Reseller',
        id_kundeabrechreseller: 'Invoicing',
        id_kundeabrechnung: 'Customer number',
        id_promotinghostel: 'Promotion reduction for hostel',
        rabatt: 'Reduction',
        rabattart: 'Reduction type',
        credituse: 'Use of credits',
        resellerremark: 'Remarks: from reseller (e.g. pricing, special deals)',
        guestdetail: 'copy guest details',
        alterskat: 'copy age categories',
        zahlungsart: 'copy payment types',
        zusatzleistung: 'copy services',
      },
    },
    repDocuments: {
      fields: {
        datum: 'Date',
        type: 'Type',
        flag: 'New',
        titel: 'Title',
        filename: 'Filename',
      },
    },
    repMessages: {
      fields: {
        betreff: 'Subject',
        id_hostel: 'Hostel',
        receiver: 'Receiver',
        type: 'Type',
        zeit: 'Date',
      },
    },
    setAgecategory: {
      empty: 'No age category yet.',
      fields: {
        bezeichnung: 'Description',
        abk: 'Shortcut',
      },
    },
    setAgent: {
      name: 'agent |||| agents',
      forcedCaseName: 'agent |||| agents',
      empty: 'No agent yet.',
      fields: {
        q: 'Search',
        id: 'Id',
        firma: 'Organisation',
        plz_ort: 'City',
        code: 'Code',
        kontakt: 'Contact',
        strasse: 'Street',
        land: 'Country',
        tel: 'Phone',
        fax: 'Fax',
        email: 'Email',
        emailtype: 'Email Type',
        bank: 'Bank',
        postkonto: 'Post Account',
        bankclearing: 'Bank clearing',
        kontonummer: 'Account No',
        mwst: "VAT",
      },
    },
    setAgentcontract: {
      name: 'agent contract |||| agent contracts',
      forcedCaseName: 'agent contract |||| agent contracts',
      empty: 'No contract yet.',
      fields: {
        q: 'Search',
        id: 'Id',
        pos: 'Pos',
        firma: 'Organisation',
        plz_ort: 'City',
        kom_buchung: 'Book',
        aufpreis: 'on Top',
        bookingfee: 'Fee',
        zart: 'Type',
        ohne_kk: 'no CC',
        kom_link: 'Link',
      },
    },
    setBed: {
      name: 'bed |||| beds',
      forcedCaseName: 'bed |||| beds',
      empty: 'No bed yet.',
      fields: {
        id_zimmer: 'Room',
        buchstabe: 'Bed',
        matratze: 'Additional bed',
        bemerkung: 'Remark',
      },
    },
    setBookingsheet: {
      empty: 'No booking sheet yet.',
      fields: {
        titel: 'Title',
        id_sprache: 'Language',
        pdfconf: 'PDF Conf',
        dormbook: 'DormBook',
        data: 'Daten',
        id_datei: 'docx file',
        html: 'HTML',
        text: 'Text',
      },
    },
    setDbuser: {
      name: 'user |||| users',
      forcedCaseName: 'user |||| users',
      empty: 'No Users yet.',
      fields: {
        q: 'Search',
        loginname: 'user name',
        name: 'Name',
        email: 'Email',
        id_db_loginlevel: 'user group',
        id_fw_gruppe: 'user group admin',
        status: 'Status',
        yubi: 'Yubico',
        hid: 'HID',
        inactive: '(inactive)',
        last_change: '(change)',
        edit_en: '',
      },
    },
    setDbuserglob: {
      name: 'user |||| users',
      forcedCaseName: 'user |||| users',
      empty: 'No Users yet.',
      fields: {
        q: 'Search',
        loginname: 'user name',
        name: 'Name',
        email: 'Email',
        id_db_loginlevel: 'user group',
        id_fw_gruppe: 'user group admin',
        status: 'Status',
        yubi: 'Yubico',
        hid: 'HID',
        inactive: '(inactive)',
        last_change: '(change)',
        edit_en: '',
      },
    },
    setGuestcategory: {
      name: 'guest category |||| guest categorys',
      forcedCaseName: 'guest category |||| guest categorys',
      empty: 'No guest category yet.',
      fields: {
        bezeichnung: 'Description',
      },
    },
    setGuestdetail: {
      empty: 'No guest fields yet.',
      fields: {
        title_de: 'Title German',
        title_en: 'Title English',
        bez1_de: 'Field 1 German',
        bez1_en: 'Field 1 English',
        bez2_de: 'Field 2 German',
        bez2_en: 'Field 2 English',
        bez3_de: 'Field 3 German',
        bez3_en: 'Field 3 English',
        bez4_de: 'Field 4 German',
        bez4_en: 'Field 4 English',
        bez5_de: 'Field 5 German',
        bez5_en: 'Field 5 English',
        bez6_de: 'Field 6 German',
        bez6_en: 'Field 6 English',
      },
    },
    setHostel: {
      fields: {
      },
    },
    setPaymenttype: {
      empty: 'No payment type yet.',
      fields: {
        bezeichnung: 'Description',
      },
    },
    setPayrequest: {
      name: 'payment point |||| payment points',
      forcedCaseName : 'payment point |||| payment points',
      empty: 'No payment point yet.',
      fields: {
        zeit: 'Time',
        zeitart: 'Time anchor',
        betrag: 'Value',
        betragart: 'Unit',
        active: 'active',
      },
    },
    setPrefcountry: {
      name: 'main country |||| main countries',
      forcedCaseName: 'main country |||| main countries',
      empty: 'No main country yet.',
      fields: {
        id_land: 'Country',
      },
    },
    setRate: {
      name: 'rate |||| rates',
      forcedCaseName : 'rate |||| rates',
      empty: 'No rates yet.',
      fields: {
        bezeichnung: 'Description',
        id: 'Payment points',
        erinnerung: 'Reminder',
        frist: 'Deadline',
        aktion: 'Action',
        id_payrequest: 'Payment points',
        id_storno: 'Cancelation restrictions',
        min_stay: 'Min nights',
        max_stay: 'Max nights',
        min_pers: 'Min pers.',
        max_pers: 'Max pers.',
        bwo_rate: 'BookWorldOnline',
        default_rate: 'Default option',
      },
    },
    setRoom: {
      name: 'room |||| rooms',
      forcedCaseName: 'room |||| rooms',
      empty: 'No rooms yet.',
      fields: {
        bezeichnung: 'Description',
        id_preiskat: 'Room category',
        geschlecht: 'Gender',
        bett: 'Bed',
        zusatz: 'Additional bed',
        online: 'Online',
        pos: 'Pos',
        farbe: 'Color',
        id: 'ID',
      },
    },
    setRoomcategory: {
      name: 'room category |||| room categories',
      forcedCaseName: 'room category |||| room categories',
      empty: 'No room category yet.',
      fields: {
        q: 'Search',
        bezeichnung: 'Description',
        kurzbez: 'Shortcut (4 Characters)',
        farbe: 'Color',
        text_de: 'Text German',
        text_en: 'Text English',
        text_fr: 'Text French',
        bild: 'Picture',
        stattagrep: 'S/T/R',
        in_bfs: 'In statistics',
        in_daily_list: 'In daily lists',
        in_report: 'In reports',
        id: 'ID',
      },
    },
    setService: {
      name: 'service |||| services',
      forcedCaseName: 'service |||| services',
      empty: 'No services yet.',
      fields: {
        bezeichnung: 'Description',
        bild: 'Picture',
        kuerzel: 'Shorcut',
        def_anz: 'Default number',
        def_type: 'Default type',
        online_type: 'Online',
        text_de: 'Text German',
        text_en: 'Text English',
        text_fr: 'Text French',
        pos: 'Pos',
        anz_modifier: 'Condition',
      },
    },
    setSpecialday: {
      empty: 'No spezial days yet.',
      fields: {
        datum: 'Date',
        bemerkung: 'Description',
      },
    },
  },
}
export default langEN;
