import React, { useState } from 'react';
import {getInfoValue} from '../lib/globalData';
import dataProvider from '../lib/dataProvider';
import { useTranslate } from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

const Contact = (props) => {
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [email, setEmail] = useState(getInfoValue('email'));
  const [res, setRes] = useState({});
  const tl = useTranslate();

  const SendContact = ev => {
    const data = {subject, text, email, url:window.lasturl};
    data.property = getInfoValue('hostelname')+' ['+getInfoValue('id_hostel')+']';
    data.user = getInfoValue('username'); 
    dataProvider.create('dashboardContact', { data: data })
      .then(r  => { 
        setRes(r);
        setRes(r);
      })
  }
  
  const changeSubject = ev => {
    setSubject(ev.target.value);
  }

  const changeText = ev => {
    setText(ev.target.value);
  }
  
  const changeEmail = ev => {
    setEmail(ev.target.value);
  }
  
  const id = res.data && res.data.id;

  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
        <h3>{tl('bw.dashboard.contact.title')}:</h3>
    {id<0 && <p style={{color:'#D40000'}}>{tl('bw.dashboard.contact.error')}</p>}    
        <p>{tl('bw.dashboard.contact.property')}: {getInfoValue('hostelname')} [{getInfoValue('id_hostel')}]</p>
        <p>{tl('bw.dashboard.contact.user')}: {getInfoValue('username')}</p>
        <TextField required value={email} onChange={changeEmail} disabled={id===1} id="filled-required" label={tl('bw.dashboard.contact.email')} variant="filled" fullWidth style={{marginBottom:'1em'}} />
        <TextField required value={subject} onChange={changeSubject} disabled={id===1} id="filled-required" label={tl('bw.dashboard.contact.subject')} variant="filled" fullWidth  />
        <TextField required value={text} onChange={changeText} disabled={id===1} id="filled-required" label={tl('bw.dashboard.contact.text')} variant="filled" fullWidth multiline style={{margin:'1em 0'}} />
    {id===1?
        <h4>{tl('bw.dashboard.contact.sent')}</h4>
    :
        <Button onClick={SendContact} variant="contained" color="primary">{tl('bw.dashboard.contact.send')}</Button>
    }
      </CardContent>
    </Paper>         
  )
}
export default Contact;