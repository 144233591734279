import React, { Fragment, useState } from 'react';
import { useTranslate, useRecordContext, required, useNotify, Button,
        SimpleForm, useRefresh, TextInput, } from 'react-admin';
import IconFileCopy from '@mui/icons-material/FileCopy';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import dataProvider from '../dataProvider';
import NoDeleteToolbar from '../../lib/NoDeleteToolbar';

const ServiceCopy = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const record = useRecordContext();

  const [showDialog, setShowDialog] = useState(false);
  
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  
  const doSave = (data) => {
    dataProvider.update('calService', { id:record.id, data:data })
      .then(({ data }) => {
        setShowDialog(false);
        refresh();
      })
      .catch(error => {
        notify(error.message, 'error');
      })
  };

  const prefill = 'Copy of '+record.bezeichnung;
  return (
    <Fragment>
      <Button onClick={handleClick} label="glob.copy">
        <IconFileCopy />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.service.copytitle" >
        <DialogTitle>{translate("bw.cal.service.copytitle")}</DialogTitle>
        <DialogContent>
          <SimpleForm onSubmit={doSave} toolbar={<NoDeleteToolbar />} >
            <Fragment>
              <b>{record.bezeichnung}</b>
              <div>{translate('bw.cal.service.copyinfo')}</div>
            </Fragment>
            <TextInput source="copyTitle" defaultValue={prefill} validate={required()} />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default ServiceCopy;

