import React from 'react';
import { useRecordContext, RichTextField } from 'react-admin';

const ConditionalRichTextField = ({ dispSource, dispLabel, condition, ...rest }) => {
  const record = useRecordContext();
  return record && record[condition] ? <RichTextField {...rest} source={dispSource} label={dispLabel} /> :null;
};

export default ConditionalRichTextField; // decorate with redux-form's <Field>

