import React, { useState, useEffect, useRef } from 'react';
import dataProvider from '../../lib/dataProvider';
import { getGlobalData} from '../../lib/globalData';
import { useTranslate } from 'react-admin';
import { apiPath } from '../../lib/isTest';
import Checkbox from '@mui/material/Checkbox';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Guestexport = (props) => {
  const tr = useTranslate();
  const [allRes, setAllRes] = useState(true);
  const [guestcats, setGuestcats] = useState(false);
  const [agents, setAgents] = useState(false);
  const datefilterEl = useRef(null);

  useEffect(() => {
    dataProvider.getList('setGuestcategory', { filter: { }, sort: {  }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setGuestcats(res.data);
      })
    dataProvider.getList('setAgent', { filter: { }, sort: {  }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setAgents(res.data);
      })
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setAllRes(datefilterEl.current.control.checked);
  };
  
  if (guestcats === false) return null;
  const info = getGlobalData('info');
  var curr = new Date();
  var dateend = curr.toISOString().substring(0,10);
  curr.setDate(curr.getDate() - 90);
  var datestart = curr.toISOString().substring(0,10);

  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
        <form method="post" action={apiPath+"v1/repGuestexport"} target="_blank">
          <input type="hidden" name="token" value={getGlobalData('token')} />
          <input type="hidden" name="lang" value= {info.id_sprache===1?"en":"de"} />
          <h4 style={{marginBottom:0}}>{tr('bw.rep.guestexport.datafields')}</h4>
          <RadioGroup aria-label="felder" name="felder" defaultValue="email" style={{display:"flex",flexDirection:"row"}}>
            <FormControlLabel value="email" control={<Radio />} label={tr('bw.rep.guestexport.email')} />
            <FormControlLabel value="address" control={<Radio />} label={tr('bw.rep.guestexport.address')} />
            <FormControlLabel value="allfields" control={<Radio />} label={tr('bw.rep.guestexport.allfields')} />
          </RadioGroup>
    {guestcats.length > 0 &&       
          <>
            <h4 style={{marginBottom:0}}>{tr('bw.rep.guestexport.gastcats')}</h4>
            <div>
              <Checkbox name={"cat_all"} value="1" />{tr('bw.rep.guestexport.allGuests')}
            </div>
    {guestcats.map((it)=>(
            <div>
              <Checkbox name="kats[]" value={it.id} />{it.bezeichnung}
            </div>
    ))}      
          </>
    }
          <>
            <h4 style={{marginBottom:0}}>{tr('bw.rep.guestexport.resfilter')}</h4>
            <RadioGroup aria-label="resfilter" name="resfilter" onClick={handleChange} defaultValue="allres" style={{display:"flex",flexDirection:"row"}}>
              <FormControlLabel value="allres" control={<Radio />} ref={datefilterEl} label={tr('bw.rep.guestexport.allres')} />
              <FormControlLabel value="arrival" control={<Radio />} label={tr('bw.rep.guestexport.arrival')} />
              <FormControlLabel value="stay" control={<Radio />} label={tr('bw.rep.guestexport.stay')} />
              <FormControlLabel value="depature" control={<Radio />} label={tr('bw.rep.guestexport.departure')} />
            </RadioGroup>
    {!allRes && 
            <div style={{marginLeft:"1em",display:"flex",gap:"1em"}}>
              <TextField name="resfilterstart" label={tr('bw.rep.guestexport.startdate')} type="date" InputLabelProps={{shrink: true}} defaultValue={datestart} />  
              <TextField name="resfilterend" label={tr('bw.rep.guestexport.enddate')} type="date" InputLabelProps={{shrink: true}} defaultValue={dateend} />  
            </div>
    }
          </>
    {!allRes && agents.length > 0 &&       
          <div>
            <h4 style={{marginBottom:0}}>{tr('bw.rep.guestexport.agents')}</h4>
            <Select native inputProps={{name: 'resfilteragent'}}>
              <option value="all" >{tr('bw.rep.guestexport.allAgents')}</option>
    {agents.map((it)=>(
              <option value={it.id}>{it.firma}</option>
    ))}      
            </Select>          
          </div>
    }
          <Button variant="contained" type="submit" style={{ marginTop: '1em'}} >{tr('bw.rep.guestexport.Export')}</Button>
        </form>
      </CardContent>
    </Paper>         
  )
}
export default Guestexport;
