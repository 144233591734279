import React, { useState, useEffect } from 'react';
import { useStore } from 'react-admin';
import Ansi from "../../lib/ansi2react";
import dataProvider from '../../lib/dataProvider';
//import { getInfoValue} from '../../lib/globalData';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

const GlobServer = () => {
  const [versionlist, setVersionlist] = useState([]);
  const [version, setVersion] = useState(false);
  const [textid, setTextId] = useState({id: false, pos: -1, retry: 0});
  const [text, setText] = useState('');
  const [cccmd, setCCcmd] = useStore('cccmd', 'unit-file web/portale/portalTest.php:dasistkeinTest');

  useEffect(() => {
    dataProvider.getOne('globServer', { id: 'versions' })
      .then(res  => { 
        setVersionlist(res.data);
        setVersion(0);
      })
  }, []);

  useEffect(() => {
    if (textid.id === false) return;
    dataProvider.getOne('globServer', { id: 'getoutput/'+textid.id+'/'+textid.pos })
      .then(res  => { 
        if (res.data.pos === textid.pos) {
          if (textid.pos>-1 && textid.retry<300) {
            setTimeout(() => {
              setTextId({id: textid.id, pos: textid.pos, retry: textid.retry+1});
            }, 1000);          
          }
        }
        else {
          setText(t => t + res.data.text);
          setTextId({id: textid.id, pos: res.data.pos, retry: 0});
        }

      })
  }, [textid]);

  const versionchange = (event) => {
    setVersion(event.target.value);
  };
  
  const cccmdchange = (event) => {
    setCCcmd(event.target.value);
  };
  
  const clickcmd = ( cmd ) => () => {
    setText('');
    dataProvider.getOne('globServer', { id: 'cmd/'+cmd })
      .then(res  => {
        setTextId({id: res.data.id, pos: 0, retry: 0});
      })
  }
  
  const clickupdate = () => {
    setText('');
    dataProvider.getOne('globServer', { id: 'cmd/svnupdate/'+encodeURI(version).replace(/\//g, '%09') })
      .then(res  => {
        setTextId({id: res.data.id, pos: 0, retry: 0});
      })
  }
  
  const clickcc = () => {
    setText('');
    dataProvider.getOne('globServer', { id: 'cmd/start_cc/'+encodeURI(cccmd).replace(/\//g, '%09') })
      .then(res  => {
        setTextId({id: res.data.id, pos: 0, retry: 0});
      })
  }
  
  const clicksql = () => {
    setText('');
    dataProvider.update('globServer', { id: 'cmd/start_sql/', data: {sql: cccmd} })
      .then(res  => {
        setText(res.data.text);
        //setTextId({id: res.data.id, pos: 0, retry: 0});
      })
  }
  
  const LocalButton = (props) => ( 
    <React.Fragment>
      <Button variant="contained" color="primary" value="-" disabled={textid.pos>-1} {...props}>{props.text}</Button>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </React.Fragment>
  )
  
  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div style={{display:'flex', justifyContent: 'space-between'}}>
        <div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl variant="filled">
            <InputLabel htmlFor="version">Version</InputLabel>
            <Select
              native
              value={version}
              onChange={versionchange}
              style={{minWidth: '170px'}}
              inputProps={{name: 'age',id: 'version'}}
            >
              {versionlist.map((v)=>(<option value={v.id} key={v.id}>{v.text}</option>))}
            </Select>
          </FormControl>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <LocalButton onClick={clickupdate} text="Update" />
          <LocalButton onClick={clickcmd('svnrevert')} text="Revert" />
          <LocalButton onClick={clickcmd('installphp')} text="Install PHP" />
          <LocalButton onClick={clickcmd('installbwo')} text="Install BWO" />
          <LocalButton onClick={clickcmd('installadm')} text="Install Admin" />
          <LocalButton onClick={clickcmd('test')} text="Test Script" />
          <LocalButton onClick={clicksql} text="run sql on testDBs" />
        </div>
      </div>
      <TextField id="standard-basic" label="Test" value={cccmd} onChange={cccmdchange} fullWidth multiline />
      <LocalButton onClick={clickcc} text="Codeception" />
      <br />
      <Ansi>{text}</Ansi>
      <br />
      <TextField id="standard-basic" label="Test" value={cccmd} onChange={cccmdchange} fullWidth multiline />
      <LocalButton onClick={clickcc} text="Codeception" />
      </CardContent>
    </Paper>         
  )
}
/*
// https://www.npmjs.com/package/ansi-to-react

*/
export default GlobServer;