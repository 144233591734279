import React, { useEffect, useState } from 'react';
import { getGlobalData } from './globalData';
import { useSetLocale } from 'react-admin';

const SetLang = () => {
  const [langLoaded] = useState(false);
  const setLocale = useSetLocale();
  useEffect(() => {
    var info = getGlobalData('info');
    info && setLocale(info.id_sprache===1?'de':'en');
  }, [langLoaded, setLocale]);
  return (
      <div />
  );
}

export default SetLang;
