import React from 'react';
import Button from '@mui/material/Button';
import dataProvider from '../lib/dataProvider';
import { useRecordContext, useRefresh } from 'react-admin';
import MoveIcon from '@mui/icons-material/UnfoldMore';

const Move = (props) => {
  const refresh = useRefresh();
  const record = useRecordContext();
  
  const handleClick = (event) => {
    event.stopPropagation();
    const { getMoveStart, setMoveStart, endpoint } = props;
    const id = record.id;
    const mx = getMoveStart();
    if (mx === 0) {
      setMoveStart(id);
    }
    else if (mx===id) {
      setMoveStart(0);
    }
    else {
      const updatedRecord = { moveStart: mx};  
      dataProvider.update(endpoint, { id: id, data: updatedRecord })
       .then(() => {
         setMoveStart(0);
         refresh();
       })
       .catch((e) => {
           console.error(e);
       });  
    }
    
  }
  
  const mx = props.getMoveStart();
  const id = record?record.id:0;
  return <Button onClick={handleClick} variant="contained"  color={mx===id?"primary":"inherit"} ><MoveIcon /></Button>;
}
export default Move;
