import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import classnames from 'classnames';

import {
  useNotificationContext,
  useTranslate,
} from 'ra-core';

interface Props {
  type?: string;
}
const MyNotification: React.FunctionComponent<
  Props & Omit<SnackbarProps, 'open'>
> = props => {
  const { classes: classesOverride, type, className, autoHideDuration, ...rest } = props;
  const [open, setOpen] = useState(false);
  const {notification} = useNotificationContext();
  const translate = useTranslate();
  const styles = {} //useStyles(props);
  
  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if(notification && notification.message && notification.message.indexOf(';') !== -1){
    notification.messageArgs = notification.message.split(';');
    notification.message = notification.message.substring(0, notification.message.indexOf(';'));
  }

  return (
    <Snackbar
      open={open}
      message={
        notification &&
        notification.message &&
        translate(notification.message, notification.messageArgs)
      }
      autoHideDuration={
        (notification && notification.autoHideDuration) ||
        autoHideDuration
      }
      disableWindowBlurListener={false}
      onClose={handleRequestClose}
      ContentProps={{
        className: classnames(
          styles[(notification && notification.type) || type],
          className
        ),
      }}
      {...rest}
    />
  );
};

MyNotification.propTypes = {
  type: PropTypes.string,
};

MyNotification.defaultProps = {
  type: 'info',
  autoHideDuration: 4000,
};

export default MyNotification;