import React from 'react';
import { useRecordContext, useTranslate, TextField } from 'react-admin';

const TranslateField = ({ source, target = 'target', label, noOutput, ...props }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record[source]){
    return null;
  }
  record[target] = record[source];
  if(record[source].search("[.]") > 0){
    record[target] = translate(record[source]);
  }
  if(noOutput)return null;
  return (
    <TextField source={target} label={label} record={record} {...props} />
  )
};
export default TranslateField;
