import React, { useState } from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { useTranslate, List, Create, Edit, Datagrid, SimpleForm, TextField, 
  EditButton, TextInput, Filter } from 'react-admin';
import Move from '../../lib/Move';
import BooleanRedField from '../../fields/BooleanRedField';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const {classes, ...rest} = props;
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <List {...rest} title="bw.set.agecategory.title" sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <TextField source="pos" textAlign="right" />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setAgecategory" />
        <TextField source="bezeichnung" />
        <TextField source="abk" />
        {bwh_sprachen.map((sp)=>(<BooleanRedField label={sp.toUpperCase()} source={"complete_" + sp} sortable={false} key={sp} />))}
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}> 
        <TextInput source="bezeichnung" />
        <TextInput source="abk" />
      {bwh_sprachen.map((sp)=>(
        <TextInput fullWidth source={"description_" + sp} label={translate("bw.set.agecategory.description") + ' ' + sp} />
      ))}
        <p fullWidth>{translate("bw.set.agecategory.help")}</p>
        <p fullWidth>{translate("bw.set.agecategory.help2")}</p>
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="bezeichnung" alwaysOn />
        <TextInput source="abk" alwaysOn />
    </Filter>
);

const Agecategory =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} filters={<ListFilter />} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.agecategory.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.agecategory.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Agecategory