import React, { useState } from 'react';
import {  useTranslate, useListContext, SimpleForm, List, Pagination, Button, BooleanInput, SelectInput } from 'react-admin';
import { Link } from 'react-router-dom'
import Backspace from '@mui/icons-material/Backspace';
import { parse } from 'query-string';
import classnames from 'classnames';
import Table from '@mui/material/Table';
/*
const useSelectStyles = makeStyles(theme => ({
  padding: {
    '& > div:nth-child(1) > div': {paddingTop: '7px'},
    '& > div:nth-child(1) > input': {paddingTop: '7px'},
    width: '219px',
  },
  flex: {
     '& .MuiFormControl-root': { marginRight: 32, width: '200px' },
  },
  bool: {
    marginTop: theme.spacing(2),
  },
  odd: {
    backgroundColor: '#DDD',
  },
  even: {
    backgroundColor: '#EEE',
  },
}));
*/
const PostPagination = props => <div style={{marginTop:'1em',marginLeft:'1em'}}>
  <ReturnButton />
  <Pagination rowsPerPageOptions={[]} {...props} />
</div>

const ReturnButton = ({
  record, variant = 'contained',
  icon = <Backspace style={{marginRight: '0.5em', fontSize:'24px'}} />,
}) => (
  <Button component={Link} to="/calOnline" label='ra.action.back' variant={variant} style={{float:'left', marginRight:'1em'}} >
    {icon}
  </Button>
);

const DisplayValue = ({ value, value2, filter }) => {
  if(!value)  return null;
  if(!filter) return 'x';
  if(!filter.d && !filter.n) return 'x';
  if(!filter.n) return value;
  return value2?value2:value;
};

const SimpleTable = (props) => {
  const [filter, setFilter] = useState(false);
  const { data, total, filterValues } = useListContext();
  const classes = {} //useSelectStyles();
  const translate = useTranslate();
  if(!total) return null;
  const Cell = ({ type, textAlign, ...props}) => {
    switch(type) {
      case 'value': return (<td style={{ padding: '5px 1em', borderBottom:'1px solid #ccc', textAlign }}>{props.value}</td>)
      default: return null;
    }
  }

  const singleGroup = filterValues.id?filterValues.id.length===1:true
  
  const params = window.location.hash.split('?');
  const { filter: filter_string } = parse(params[1]);
  var col_ids = [];
  if (singleGroup && data.length>0) {
    for (const [key, value] of Object.entries(data[0])) {
      if (key.substring(0,2)==='c_') {
        col_ids.push(parseInt(key.substring(2)));
      }
    }
  }
  else {
    const ogfilter = JSON.parse(filter_string);
    col_ids = ogfilter.id;
  }
  var pfilter = [];
  var rcfilter = [];
  var rfilter = [];
  //var dfilter = ['condensed', 'internal ID', 'readable'];
  var rows = [];
  var show = true;
  var Zbra = true;
  for(var i=0; i<data.length; i++) {
    if(data[i]){
      const row = data[i]
      show = true;
      if(filter){
        if(filter.p && filter.p !== row.strasse)show = false;
        if(filter.rc && filter.rc !== row.pkbez)show = false;
        if(filter.r && filter.r !== row.zimmerb)show = false;
      }
      pfilter.push(row.strasse);
      rcfilter.push(row.pkbez);
      rfilter.push(row.zimmerb);
      //rfilter[data.zimmerb] = data.zimmerb;
      if(show){
        Zbra = !Zbra;
        
        var cells = [];
        for (var j=0; j<col_ids.length; j++) {        
          var id = col_ids[j];
          cells.push(<Cell type="value" value={<DisplayValue value={row["c_" + id]} value2={row["d_" + id]} filter={filter} />} textAlign='center' key={id} />)
        } 
        
        rows.push(
          <tr key={i} className={classnames({[classes.odd]: Zbra, [classes.even]: !Zbra})} >
  {!singleGroup && 
            <Cell type="value" value={row.strasse}/>
  }
            <Cell type="value" value={row.pkbez}/>
            <Cell type="value" value={row.zimmerb}/>
            {cells}
          </tr>
        )
      }
    }
  }
  
  const pfdata = pfilter.filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  const rcfdata = rcfilter.sort().filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  const rfdata = rfilter.sort().filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  //const dfdata = dfilter.sort().filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  //const rfdata = rfilter.map((v, i)=>{return{id:v,bezeichnung:v}});
  return (
    <SimpleForm toolbar={null}>
    <div style={{ display: 'flex', flexWrap: 'wrap', width: 'auto' }} className={classes.flex} >
  {!singleGroup && 
      <SelectInput source={"rcfilter"} record={filter.p} choices={pfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.onlinecompare.showall" label="bw.cal.onlinecompare.strasse" helperText={false} onChange={e=> { setFilter({...filter, ...{p: e.target.value}}) }} />
  }
      <SelectInput source={"acfilter"} record={filter.rc} choices={rcfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.onlinecompare.showall" label="bw.cal.onlinecompare.pkbez" helperText={false} onChange={e=> { setFilter({...filter, ...{rc: e.target.value}}) }} />
      <SelectInput source={"rfilter"} record={filter.r} choices={rfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.onlinecompare.showall" label="bw.cal.onlinecompare.zimmerb" helperText={false} translateChoice={false} onChange={e=> { setFilter({...filter, ...{r: e.target.value}}) }} />

      <BooleanInput className={classes.bool} source={"dfilter"} record={filter.d} label="bw.cal.onlinecompare.details" onChange={e=> { setFilter({...filter, ...{d: e.target.checked}}) }} />
      <BooleanInput className={classes.bool} source={"nfilter"} record={filter.n} label="bw.cal.onlinecompare.details2" onChange={e=> { setFilter({...filter, ...{n: e.target.checked}}) }} />
    </div>
    <Table style={{width:'auto', borderCollapse:'collapse', padding:0}} >
      <tbody>
        <tr align="left">
  {!singleGroup && 
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.onlinecompare.strasse')}</th>
  }
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.onlinecompare.pkbez')}</th>
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.onlinecompare.zimmerb')}</th>
          {col_ids.map((id)=>(<th style={{padding: '5px 1em', borderBottom:'1px solid #333'}} key={id}>{data[0]["l_" + id] }</th>))}
        </tr>
        {rows}
      </tbody>
    </Table>
    </SimpleForm>
  )
}

const ListForm = ({ ...props}) => {
  return (
    <List {...props} sort={{ field: 'strasse', order: 'ASC' }} pagination={<PostPagination />} perPage={1000} bulkActionButtons={false} >
      <SimpleTable {...props} />
    </List>
  )
};

const OnlineCompare = {
  list: ({ translate, ...props }) => (
    <ListForm {...props} />
  ),
}
export default OnlineCompare 


/*
  {false && 
      <SelectInput source={"dfilter"} record={filter.d} choices={dfdata} optionText="bezeichnung" allowEmpty emptyText="" label="Details" helperText={false} translateChoice={false} onChange={e=> { setFilter({...filter, ...{r: e.target.value}}) }} />
  }
*/