import React from 'react';
import { useTranslate } from 'react-admin';

export const ServerMessage = ({ msgdata, ...props }) => {
  const translate = useTranslate();
  let msg = msgdata.msg;
  let style = {position:'absolute',left:'245px',top:'55px',color:'#000',backgroundColor:'#FF0',padding:'5px',borderRadius:'4px',fontWeight:'bold'};
  if(msgdata.trans)msg = translate(msgdata.msg);
  if(msgdata.lvl === 2){
    style = {position:'absolute',left:'245px',top:'55px',color:'#000',backgroundColor:'#F80',padding:'5px',borderRadius:'4px',fontWeight:'bold',fontSize:'xxx-large',animation: 'pulse 1.5s infinite',boxShadow: '0 0 0 0 rgba(0,0,0,0)'};
  }
  return (
    <div style={style} key="servermsg">{msg}</div>
  );
}

export default ServerMessage;
