import React, { Fragment, useState } from 'react';
import {
    useTranslate, useRecordContext,
    required, useNotify,
    Button,
    SimpleForm,  
    DateInput,
    useRefresh,
} from 'react-admin';
import IconFileCopy from '@mui/icons-material/FileCopy';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import NoDeleteToolbar from '../../lib/NoDeleteToolbar';
import dataProvider from '../dataProvider';

const SpecialdayCopy = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const record = useRecordContext();
  
  const [showDialog, setShowDialog] = useState(false);
  
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  
  const doSave = (data) => {
    dataProvider.update('setSpecialday', { id:record.id, data:data })
        .then(({ data }) => {
           setShowDialog(false);
           refresh();
        })
        .catch(error => {
            notify(error.message, 'error');
        })
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="glob.copy">
                <IconFileCopy />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="bw.set.specialday.copytitle"
            >
                <DialogTitle>{translate("bw.set.specialday.copytitle")}</DialogTitle>
                <DialogContent>
                    <SimpleForm onSubmit={doSave} toolbar={<NoDeleteToolbar />} >
                      <Fragment>
                        <h3>{record.datum} {record.bemerkung}</h3>
                        <div>{translate('bw.set.specialday.copyinfo')}</div>
                      </Fragment>
                      <DateInput source="copyStart" validate={required()} style={{display: 'inline-block'}} />
                      <DateInput source="copyEnd" validate={required()} style={{display: 'inline-block'}} />
                      <Fragment>
                        <div>{translate('bw.set.specialday.overwrite')}</div>
                      </Fragment>
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default SpecialdayCopy;

