import React, { Fragment  } from 'react';
import { useTranslate, useDataProvider, useRefresh, useUpdateMany, useListContext, useRecordContext, List, Filter, Create, Datagrid, Edit, SimpleForm, TextField, FunctionField,
  EditButton, TextInput, BooleanInput, SelectInput, DateInput, NumberInput, ReferenceInput } from 'react-admin';
import { BulkDeleteButton } from 'react-admin';
import { Button } from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import { Stack } from '@mui/material';

const BulkActionButtons = props => (
  <Fragment>
    <ToggelActiveButton {...props} />
    <BulkDeleteButton mutationMode="pessimistic" {...props} />
  </Fragment>
);
const ToggelActiveButton = () =>{
  const { selectedIds } = useListContext();
  const refresh = useRefresh();
  const [updateMany] = useUpdateMany(
      'bwhDiscount',
      { ids: selectedIds, data: { status: 'toggle' } },
      { onSuccess: () => { refresh(); } }
  );
      
  const handleClick = () => {
    updateMany();
  };

  return (
    <Button label="Toggle Active" onClick={handleClick} />
  );
}
const DiscountFilter = (props) => (
  <Filter {...props}>
    <TextInput source="code" alwaysOn />
    <SelectInput source="status" alwaysOn choices={[
      { id: 'neu', name: 'bw.bwh.discount.neu' },
      { id: 'benutzt', name: 'bw.bwh.discount.benutzt' },
      { id: 'inaktiv', name: 'bw.bwh.discount.inaktiv' },
    ]}/>
    <BooleanInput source="abgelaufen" alwaysOn />
  </Filter>
);

const BedingungField = ({ record = {} }) => {
  const translate = useTranslate();
  return (
    <span>
      {record.ankunft_min || record.ankunft_max?translate('bw.bwh.discount.ankunft')+' ':''}
      {record.ankunft_min?translate('bw.bwh.discount.ab')+' '+record.ankunft_min+' ':''}
      {record.ankunft_max?translate('bw.bwh.discount.bisdatum')+' '+record.ankunft_max+' ':''}

      {record.dauer_min>0 || record.dauer_max>0?translate('bw.bwh.discount.dauer')+' ':''}
      {record.dauer_min>0?translate('bw.bwh.discount.ab')+' '+record.dauer_min+' ':''}
      {record.dauer_max>0?translate('bw.bwh.discount.bis')+' '+record.dauer_max+' ':''}

      {record.personen_min>0 || record.personen_max>0?translate('bw.bwh.discount.personen')+' ':''}
      {record.personen_min>0?translate('bw.bwh.discount.ab')+' '+record.personen_min+' ':''}
      {record.personen_max>0?translate('bw.bwh.discount.bis')+' '+record.personen_max:''}
    </span>
  )
}

const paramTranslate = (json, translate) => {
  var k = '';
  for(k in json.params) {
    if (k.substr(0, 6)==='trans_') {
      json.params[k.substr(6)] = translate(json.params[k]);
    }
  }
  return translate(json.text, json.params);
}
  
const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const translate = useTranslate();
  const dis = record && typeof record.status !== 'undefined' && record.status!=='neu';

  const asyncValidate = async (values) => {
    const res = await dataProvider.update('bwhDiscount/verify', {id: record?record.id:0, data:{ values: values }});
    const json = res.data;
    if (json.ok) return {};
    for(var k in json.errors) {
      json.errors[k] = paramTranslate(json.errors[k], translate);
    }
    return(json.errors)
  }
    

  return (
    <SimpleForm validate={asyncValidate} mode="onBlur" toolbar={<CloseToolbar noSave={dis} />}  {...props}>
        <TextInput source="code" disabled={dis} />
        <Stack direction="row" gap={2}>
          <SelectInput source="rabatt_typ" choices={[
            { id: 'prozent', name: 'bw.bwh.discount.prozent' },
            { id: 'einmalig', name: 'bw.bwh.discount.einmalig' },
            { id: 'nacht', name: 'bw.bwh.discount.nacht' },
            { id: 'person', name: 'bw.bwh.discount.person' },
            { id: 'person_nacht', name: 'bw.bwh.discount.person_nacht' },
          ]} disabled={dis} />
          <TextInput source="rabatt" disabled={dis} />
        </Stack>
        <Stack direction="row" gap={2}>
          <DateInput source="ankunft_min" disabled={dis} />
          <DateInput source="ankunft_max"  disabled={dis} />
          <NumberInput source="personen_min"  disabled={dis} />
          <NumberInput source="personen_max"  disabled={dis} />
        </Stack>
        <Stack direction="row" gap={2}>
          <NumberInput source="dauer_min" disabled={dis} />
          <NumberInput source="dauer_max" disabled={dis} />
        </Stack>
        <ReferenceInput source="force_agent_id" reference="setAgent" 
            perPage={1000} filter={{bwovertrag:true}} sort={{ field: 'firma', order: 'ASC' }} allowEmpty alwaysOn>
          <SelectInput optionText="firma" label="bw.bwh.discount.force_agent" emptyText="bw.bwh.discount.dontchangeangent" />
        </ReferenceInput>
    </SimpleForm>
  )
};

const CodeList = () => {
  const translate = useTranslate();
  return (
    <Datagrid bulkActionButtons={<BulkActionButtons />}>
      <TextField source="code" />
      <TextField source='rabatt' />
      <FunctionField source='rabatt_typ' render={record => { return translate('bw.bwh.discount.'+record.rabatt_typ) }} />
      <BedingungField />
      <FunctionField source='status'  render={record => { return translate('bw.bwh.discount.'+record.status) }} />
      <EditButton label="" />
    </Datagrid>
  )
}
const Discount = {
  list: ({ classes, ...props }) => { 
    return (
      <List {...props} title='bw.bwh.discount.title' 
        filters={<DiscountFilter />} pagination={<NoPagination />} perPage={1000} filterDefaultValues={{ abgelaufen: true }} >
        <CodeList />
      </List>
    )
  },
  
  create: ({classes, ...props }) => (
    <Create {...props} title='bw.bwh.discount.titleCreate' redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title='bw.bwh.discount.titleEdit' mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
} 
export default Discount 