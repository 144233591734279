import React, { Component } from 'react';
import { getGlobalData, getRoute } from '../lib/globalData';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { oldDomain } from '../lib/isTest';
import LoadingIcon from '../img/loading.svg';

class CatchAll extends Component {
  render() {
    var height = window.innerHeight;                           
    height -= 168;
    
    return (
      <Card>
        <CardContent>
          <div id="loadinganimation" key={"loadinganimation"+Math.random()}>
            <img src={LoadingIcon} style={{display:"block", margin:'auto'}} alt={"Loading"} />
          </div>
          <iframe src={oldDomain +"/wrap_admin.php?x=y&token="+getGlobalData('token')+"&page="+getRoute()} title={getRoute()} style={ { width: '100%', minHeight: height+'px', border: 'none' } } key={"catchalliframe"+Math.random()} />
        </CardContent>
      </Card>
    )
  }
}

export default CatchAll;
