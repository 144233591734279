import React, { Fragment, useState } from 'react';
import { useNotify,
    Button, useRefresh, useTranslate, useRedirect, useRecordContext } from 'react-admin';
import Activate from 'mdi-react/AccountReactivateOutlineIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import dataProvider from '../dataProvider';

const ActivateUserButton = ({ label="glob.activate", ...props}) => {
  var record = useRecordContext();
  const [showDialog, setShowDialog] = useState(false);
  const [nPwd, setNewPwd] = useState(false);
  
  if (!record) {
    record = {...props.record};
  }
  if(record && record.edit_en && !props.showbutton)return null;

  const handleClick = () => {
    if(nPwd === false) dataProvider.getOne('setDbuser/getnewpassword', { id: 0 })
    .then(response => {
      setNewPwd(response.data);
      setShowDialog(true);
    });
  };

  return (
    <Fragment>
      <Button onClick={handleClick} label={label}>
        <Activate />
      </Button>
      {showDialog && <ActivateUserDialog id={record.id} name={record.loginname} newPwd={nPwd} />}
    </Fragment>
  );
}

const ActivateUserDialog = ({id, newPwd='', name, ...props}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [showDialog, setShowDialog] = useState(true);
  const [copied, setCopy] = useState(false);
  const translate = useTranslate();

  if(!id || !newPwd || !name){
    return null;
  }

  const handleCloseClick = () => {
    setShowDialog(false);
    refresh();
  };

  const handleCopyClick = () => {
    setCopy(translate("glob.pwdcopied"));
  };

  const doSave = (data) => {
    dataProvider.update('setDbuser/activate', { id:id, data:{ newpwd:newPwd } })
      .then(({ data }) => {
         setShowDialog(null);
         redirect('/setDbuser');
         refresh();
      })
      .catch(error => {
          notify(error.message, 'error');
      })
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.set.dbuser.activatetitle">
      <DialogTitle>{translate("bw.set.dbuser.activatetitle")}</DialogTitle>
      <DialogContent>
        <b>{translate("bw.set.dbuser.newPasswordInfo")}<span style={{color:"orange"}}>{name}</span></b>
        <br />
        <br />
        <div>
          <CopyToClipboard onCopy={handleCopyClick} text={newPwd}>
            <Button>
              <b style={{border:"1px solid red",padding: "1em",textTransform:"none"}}>{newPwd}</b>
            </Button>
          </CopyToClipboard>
          <span>{copied}</span>
          <div style={{marginTop:"1em",width:"25em"}}>{translate("glob.pwdremark")}<br /><br /><br />{translate("glob.pwdremark2")}</div>
        </div>
        <br /> <br />
        <Button onClick={doSave} label="bw.set.dbuser.activate">
          <Activate />
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export { ActivateUserButton, ActivateUserDialog };
