import React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import NestableMenu from './NestableMenu';
import MyNotification from './MyNotification';

const MyLayout = props => <Layout
    {...props}
    appBar={MyAppBar}
    menu={NestableMenu}
    notification={MyNotification}
/>;

export default MyLayout;