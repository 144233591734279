import React from 'react';
import { useRecordContext, DeleteWithConfirmButton} from 'react-admin';

const ConditionalDeleteButton = ({ source, ...rest }) => {
  const record = useRecordContext();
  return record && record[source] ? <DeleteWithConfirmButton {...rest} label="" /> :null;
};

export default ConditionalDeleteButton; // decorate with redux-form's <Field>

