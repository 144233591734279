import React from 'react';
import { useTranslate, useRedirect, SaveButton, Toolbar } from 'react-admin';


const StayEditToolbar = ({noSave, ...props})=> {
  const translate = useTranslate();
  const redirect = useRedirect();
  return (
    <Toolbar {...props} >
      <SaveButton
        label={translate("ra.action.save")}
        style={{marginRight:'1em'}}
        type="button"
        mutationOptions={{
          onSuccess: () => {
            redirect(false);
          }}
        }
      />
    </Toolbar>
  )};

export default StayEditToolbar;
