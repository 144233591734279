import React, { Fragment, useState } from 'react';
import { useTranslate, required, useNotify, Button,
        SimpleForm, useRefresh, TextInput, SelectInput, 
        ArrayInput, SelectArrayInput, FormDataConsumer } from 'react-admin';
import PencilBoxMultipleIcon from 'mdi-react/PencilBoxMultipleIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import dataProvider from '../dataProvider';

const OportalMultiedit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const doSave = (data) => {
    data.ids = props.selectedIds;
    dataProvider.update('calOportal', { id: 0, data:data })
      .then(({ data }) => {
        setShowDialog(false);
        refresh();
      })
      .catch(error => {
        notify(error.message, 'error');
      })
  };

  const meFields=[
    { id:  'kontingent', name: 'bw.cal.oportal.me.kontingent' },
    { id:  'freihalten', name: 'bw.cal.oportal.me.freihalten' },
    { id:  'min_nacht', name: 'bw.cal.oportal.me.min_nacht' },
    { id:  'max_nacht', name: 'bw.cal.oportal.me.max_nacht' },
    { id:  'noarrivalwd', name: 'bw.cal.oportal.me.noarrivalwd' },
    { id:  'nodeparturewd', name: 'bw.cal.oportal.me.nodeparturewd' },
    { id:  'firstres', name: 'bw.cal.oportal.me.firstres' },
    { id:  'lastnewres', name: 'bw.cal.oportal.me.lastnewres' },
  ];

  const days=[
    [ 'glob.day.short.mon' ],
    [ 'glob.day.short.tue' ],
    [ 'glob.day.short.wed' ],
    [ 'glob.day.short.thu' ],
    [ 'glob.day.short.fri' ],
    [ 'glob.day.short.sat' ],
    [ 'glob.day.short.sun' ],
  ];

  const day_choices = days.map((v, i)=>{return{id:i+1,name:v}});

  return (
    <Fragment>
      <Button onClick={handleClick} label="bw.cal.oportal.multiedit">
        <PencilBoxMultipleIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.oportal.multiedit" >
        <DialogTitle>{translate("bw.cal.oportal.multiedit")}</DialogTitle>
        <DialogContent>
          <SimpleForm resource="calOportal" onSubmit={doSave} >
            <Fragment>
              <div>{translate('bw.cal.oportal.copyinfo')}</div>
            </Fragment>
            <SelectInput source="meField" choices={meFields} label='bw.cal.oportal.meField' />
            <Fragment>
              <div>{translate('bw.cal.oportal.copyinfo2')}</div>
            </Fragment>

            <FormDataConsumer>
            {({ formData, ...rest }) => (formData.meField === 'noarrivalwd' || formData.meField === 'nodeparturewd') ?
              <ArrayInput source="meValueWd" label="" fullWidth={false} style={{ width: '400px', marginRight: 0 }} >
                <SelectArrayInput source='id' label='bw.cal.oportal.meValue' style={{ width: '368px' }} choices={day_choices} />
              </ArrayInput>
              :
              <TextInput source="meValue" validate={required()} label='bw.cal.oportal.meValue' />
            }
            </FormDataConsumer>
            

          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default OportalMultiedit;
