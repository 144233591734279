import React, { useState } from 'react';
import { useTranslate, useListContext, SimpleForm, List, Pagination, Button, Link, SelectInput } from 'react-admin';
import Backspace from '@mui/icons-material/Backspace';
import { parse } from 'query-string';
import Table from '@mui/material/Table';
/*
const useSelectStyles = makeStyles(theme => ({
  padding: {
    '& > div:nth-child(1) > div': {paddingTop: '7px'},
    '& > div:nth-child(1) > input': {paddingTop: '7px'},
    width: '219px',
  },
  padding2: {
    '& > div:nth-child(1) > div': {paddingTop: '7px'},
    '& > div:nth-child(1) > div > input': {paddingTop: '7px'},
  },
  flex: {
     '& .MuiFormControl-root': { marginRight: 32, width: '200px' },
  },
}));
*/
const PostPagination = props => <div style={{marginTop:'1em',marginLeft:'1em'}}>
  <ReturnButton />
  <Pagination rowsPerPageOptions={[]} {...props} />
</div>

const ReturnButton = ({
  record, variant = 'contained',
  icon = <Backspace style={{marginRight: '0.5em', fontSize:'24px'}} />,
}) => (
  <Button component={Link} to={{pathname:'/calPricegr'}} label='ra.action.back' variant={variant} style={{float:'left', marginRight:'1em'}} >
    {icon}
  </Button>
);

const SimpleTable = (props) => {
  const { data, total } = useListContext();
  const translate = useTranslate();

  const [filter, setFilter] = useState(false);

  if(!total) return null;
  const Cell = ({ type, textAlign, ...props}) => {                                      
    switch(type) {
      case 'value': return (<td style={{ padding: '5px 1em', borderBottom:'1px solid #ccc', textAlign, color:props.value===0?'red':'black' }}>{props.value}</td>)
      default: return null;
    }
  }

  const params = window.location.hash.split('?');
  const { filter: filter_string } = parse(params[1]);
  const { id_preis_gr: col_ids } = JSON.parse(filter_string);
  var rcfilter = [];
  var acfilter = [];
  var belfilter = [];
  var rows = [];
  var show = true;
  for(var i=0; i<data.length; i++) {
    if(data[i]){
      const row = data[i];
      show = true;
      if(filter){
        if(filter.rc && filter.rc !== row.bezeichnung)show = false;
        if(filter.ac && filter.ac !== row.alterskat)show = false;
        if(filter.bc && filter.bc !== row.belegung)show = false;
      }
      rcfilter.push(row.bezeichnung);
      acfilter.push(row.alterskat);
      belfilter[row.belegung] = row.belegung;
      if(show){
        rows.push(
          <tr key={i}>
            <Cell type="value" value={row.bezeichnung} />
            <Cell type="value" value={row.alterskat} />
            <Cell type="value" textAlign='center' value={row.belegung} />
            {col_ids && col_ids.map((id)=>(<Cell type="value" value={row["c_" + id]==='-'?'-':(Math.round(row["c_" + id] * 100) / 100)} textAlign='center' key={id} />))}
          </tr>
        )
      }
    }
  }
  const rcfdata = rcfilter.filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  const acfdata = acfilter.filter((value, index, self) => self.indexOf(value) === index).map((v, i)=>{return{id:v,bezeichnung:v}});
  const belfdata = belfilter.map((v, i)=>{return{id:v,bezeichnung:v}});
  return (
    <SimpleForm toolbar={null}>
    <div style={{ display: 'flex', flexWrap: 'wrap', width: 'auto' }} >
      <SelectInput source={"rcfilter"} record={filter.rc} choices={rcfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.pricegrcompare.showall" label="bw.cal.pricegrcompare.bezeichnung" helperText={false} onChange={e=> { setFilter({...filter, ...{rc: e.target.value}}) }} />
      <SelectInput source={"acfilter"} record={filter.ac} choices={acfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.pricegrcompare.showall" label="bw.cal.pricegrcompare.alterskat" helperText={false} onChange={e=> { setFilter({...filter, ...{ac: e.target.value}}) }} />
      <SelectInput source={"belfilter"} record={filter.bc} choices={belfdata} optionText="bezeichnung" allowEmpty emptyText="bw.cal.pricegrcompare.showall" label="bw.cal.pricegrcompare.belegung" helperText={false} translateChoice={false} onChange={e=> { setFilter({...filter, ...{bc: e.target.value}}) }} />
    </div>
    <Table style={{width:'auto', borderCollapse:'collapse', padding:0}} >
      <tbody>
        <tr align="left">
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.pricegrcompare.bezeichnung')}</th>
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.pricegrcompare.alterskat')}</th>
          <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('bw.cal.pricegrcompare.belegung')}</th>
          {col_ids && col_ids.map((id)=>(<th style={{padding: '5px 1em', borderBottom:'1px solid #333'}} key={id}>{data[0]["l_" + id] }</th>))}
        </tr>
        {rows}
      </tbody>
    </Table>
    </SimpleForm>
  )
}

const ListForm = ({ ...props}) => {
  return (
    <List {...props} sort={{ field: 'bezeichnung', order: 'ASC' }} pagination={<PostPagination />} perPage={1000} bulkActionButtons={false} >
      <SimpleTable {...props} />
    </List>
  )
};

const pricegrcompare = {
  list: ({ ...props }) => (
    <ListForm {...props} />
  ),
}
export default pricegrcompare;