import React, { useState, useEffect } from 'react';
import { getGlobalData, getInfoValue, isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, Pagination, 
  ListBase, Create, Edit, SimpleForm, TextField,
  EditButton, CreateButton, SelectField,  
  TextInput, BooleanInput, ReferenceInput, AutocompleteInput, SelectInput, NumberInput,
  FilterForm, Filter } from 'react-admin';
import Move from '../../lib/Move';
import BooleanRedField from '../../fields/BooleanRedField';
import { ColorInput } from 'react-admin-color-picker';
import ColorField from '../../fields/ColorField';
import UnitField from '../../fields/UnitField';
import CloseToolbar from '../../lib/CloseToolbar';
import Stack from '@mui/material/Stack';
import { apiPath } from '../../lib/isTest';
import DatagridConfigurable from '../../lib/DatagridConfigurable.tsx';
import { SelectColumnsButton } from '../../lib/SelectColumnsButton.tsx';


import dataProvider from '../../lib/dataProvider';

const bwochoices=[
  { id: 'No',        name: 'bw.set.agentcontract.bwoNo' },
  { id: 'OhneRates', name: 'bw.set.agentcontract.bwoNR' },
  { id: 'MitRates',  name: 'bw.set.agentcontract.bwoMR' },
];

const commissionUnit = [
  { id: '0', name: '%' },
  { id: '1', name: getInfoValue('currency') },
]

const agentFilter = [
  <TextInput source="q" alwaysOn />
];

const ListToolbar = () => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FilterForm filters={agentFilter} />
          <Stack alignItems="flex-start">
              <SelectColumnsButton label="hallo" />
          </Stack>
          <Stack alignItems="flex-start">
              <CreateButton />
          </Stack>
      </Stack>
    </>
  )
}

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const {...rest} = props;

  return (
    <ListBase {...rest} title="bw.set.agentcontract.title" sort={{ field: 'pos', order: 'ASC' }} perPage={200} >
      <ListToolbar />
      <DatagridConfigurable style={{ width: 'auto' }} omit={['plz_ort','ohne_kk', 'kom_link']} bulkActionButtons={false}>
        <TextField source="pos" textAlign="right" />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setAgentcontract" />
        <ColorField source="firma" />
        <TextField source="plz_ort" />
        <SelectField source="bwo" choices={bwochoices} />
        <UnitField source="kom_buchung" unit="kom_buchung_unit" unitMap={commissionUnit}/>
        <BooleanRedField source="aufpreis" />
        <BooleanRedField source="bookingfee" />
        <TextField source="zart" />
        <BooleanRedField source="ohne_kk" />
        <UnitField source="kom_link" unit="kom_link_unit" unitMap={commissionUnit}/>
        <EditButton label="" />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" />
}
      </DatagridConfigurable>
      <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} />
    </ListBase>
  )
}


const CEForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const record = useRecordContext();
  const [defaultValues, setDefaultValues] = useState({});
  const rec_id = record && typeof record.id !== 'undefined' ? record.id : '0';
  const translate = useTranslate();
  useEffect(() => {
    dataProvider.getOne('setAgentcontract/getdefaultvalues', {
      id: rec_id,
    })
      .then(response => {
        setDefaultValues(response.data);
      });
  }, [rec_id]);

  const asyncValidate = async (values) => {
    return new Promise(async (resolve, reject) => {
      var token = getGlobalData('token');
      const res = await fetch(apiPath+'v1/setAgentcontract/verify', {
        method: 'PUT',
        body: JSON.stringify({ id: record?record.id:0, ...values }),
        headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
      })
      const json = await res.json();
      if (json.ok) return resolve();
      var errors = {};
      var error ='';
      Object.keys(json.errors).forEach(key => {
        error = json.errors[key].split('|')
        errors[key] = translate(error[0], {value:error[1]});
      })
      resolve(errors)
    })
  }

  const dis_agent = !record || typeof record.id === 'undefined' ? false : true;
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props} validate={asyncValidate}>
      <Stack direction="row" spacing={2}>
        <ReferenceInput source="id_agent" reference="setAgent" filter={{ agentcontract: rec_id }} sort={{ field: 'firma', order: 'ASC' }} perPage={1000} >
          <AutocompleteInput optionText="firma" label="bw.set.agentcontract.id_agent" sx={{minWidth: '20em'}} disabled={dis_agent} />
        </ReferenceInput>
          <ColorInput source="farbe" label="bw.set.agentcontract.color" disableAlpha={true} />
      </Stack>
      <h4>{translate("bw.set.agentcontract.pricePaymentSettings")}</h4>
      <Stack direction="row" spacing={2}>
        <NumberInput source="kom_buchung" label="bw.set.agentcontract.kom_buchung" defaultValue={defaultValues.gebuehr} inputProps={{ min: 0, max: 100 }} disabled={defaultValues.kom_buchung_dis} sx={{margin:0}} />
        <SelectInput source="kom_buchung_unit" label="bw.set.agentcontract.kom_buchung_unit" choices={commissionUnit} defaultValue={1} disabled={defaultValues.kom_buchung_dis}/>
      </Stack>
      <Stack direction="row" spacing={2}>
        <BooleanInput source="aufpreis" label="bw.set.agentcontract.aufpreis" defaultValue={true} disabled={defaultValues.aufpreis_dis} />
        <BooleanInput source="bookingfee" label="bw.set.agentcontract.bookingfee" defaultValue={true} disabled={defaultValues.bookingfee_dis} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ReferenceInput source="id_zahlungsart_react" reference="setPaymenttype" filter={{ agentcontract: true }} sort={{ field: 'pos', order: 'ASC' }} perPage={1000} defaultValue={-1} AllowEmpty disabled={defaultValues.id_zahlungsart_dis} >
          <AutocompleteInput optionText="bezeichnung" label="bw.set.agentcontract.id_zahlungsart_react" sx={{minWidth: '20em'}} />
        </ReferenceInput>
    { defaultValues.interface === false &&
        <AutocompleteInput source="bwo" label="bw.set.agentcontract.bwo" choices={bwochoices} helperText={false} defaultValue={"OhneRates"} />
    }
      </Stack>
    { defaultValues.interface === false &&
      <>
        <div>
          <h4>
            {translate("bw.set.agentcontract.buchungAgentWebsite")}
            <span> {translate("bw.set.agentcontract.bookingChannelInternet")}</span>
          </h4>
        </div>
        <Stack direction="row" spacing={2}>
          <NumberInput source="kom_link" label="bw.set.agentcontract.kom_link" helperText={translate("bw.set.agentcontract.kom_link_help")} defaultValue={0} inputProps={{ min: 0, max: 99 }} sx={{margin:0}} />
          <SelectInput source="kom_link_unit" label="bw.set.agentcontract.kom_buchung_unit" choices={commissionUnit} defaultValue={1}/>
        </Stack>
        <div>
          <br /><b>{translate("bw.set.agentcontract.buchungDurchAgent")}</b>
          <span> {translate("bw.set.agentcontract.bookingChannelAgent")}</span>
        </div>
        <BooleanInput source="ohne_kk" label="bw.set.agentcontract.ohne_kk" disabled={defaultValues.ohne_kk_dis} />
      </>
    }
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" alwaysOn />
    </Filter>
);

const Agentcontract =  {
  list: (props) => (
    <ListForm {...props} filters={<ListFilter />} />
  ),

  create: ( props ) => (
    <Create {...props} title="bw.set.agentcontract.titleCreate" redirect="list">
      <CEForm {...props} />
    </Create>
  ),

  edit: ( props ) => (
    <Edit {...props} title="bw.set.agentcontract.titleEdit" mutationMode="pessimistic">
      <CEForm {...props} />
    </Edit>
  )
}
export default Agentcontract
