import React from 'react';
import { isHauptadmin } from '../lib/globalData';
import { useRecordContext, ListBase, Datagrid, useTranslate, TextField, DateField, Pagination } from 'react-admin';

const DateDField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div>
      <DateField source="startdatum" />
    </div>
  )
}

const InfoText = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div dangerouslySetInnerHTML={{__html: record.text}} />
  )
}

const GridHeader = () => {
  const translate = useTranslate();  
  return (
    <thead>
      <tr>
        <th colSpan={2} style={{textAlign: 'left', paddingTop: '1em'}}>
          {translate('bw.dashboard.info_archive.title')}
        </th>
      </tr>
    </thead>
  )
}

const Infoarchiv = (props) => (
    <ListBase resource="dashboardHomeinfo" title="bw.dashboard.incident_archive.title" filter={{ typ: 1 }}
      exporter={false} perPage={10} >
      <Datagrid header={<GridHeader />} style={{ width: 'auto' }} bulkActionButtons={false} sx={{'& .MuiTableCell-root': {verticalAlign: 'top'}}}>
        <DateDField sx={{verticalAlign: 'top'}} />
        <InfoText />
{isHauptadmin() &&
        <TextField source="id" textAlign="right" label="" />
}
      </Datagrid>
      <Pagination />
    </ListBase>
  )

export default Infoarchiv