import React from 'react';
import { useRedirect } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';

export const MainMenuItem = (props) => {
    const redirect = useRedirect();
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
        const {
            route,
            label,
            icon,
            onClick,
            pos,
            aktiv,
        } = props;
    
    const style = !isDesktop?{minWidth:0,padding:'6px',margin:0}:{};
    if (aktiv) {
      style.backgroundColor = "#323F8E";
    }
    return (                
      <Button variant={aktiv?"contained":"text"} color="inherit" style={style} onClick={()=>{onClick(pos); redirect(route?route:'/')}}>
        {icon}
        {isDesktop?(<span style={{paddingLeft:"0.5em"}}>{label}</span>):''} 
      </Button>                
    );
}

export default MainMenuItem;