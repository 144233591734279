import React, { useState } from 'react';
import { isHauptadmin, getRecht } from '../../lib/globalData';
import { useTranslate, useRecordContext, usePermissions, List, Create, Edit, Datagrid, SimpleForm, 
        TextField, Filter, BooleanInput, 
        EditButton, TextInput } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import { ColorInput } from 'react-admin-color-picker';
import BulkActionButtons from '../../lib/BulkActionButtons';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import ServiceCopy from '../../lib/cal/ServiceCopy';
import ColorField from '../../fields/ColorField';
import SimpleButton from '@mui/material/Button';
import Table from '@mui/material/Table';

const onlinetypes=[
  'glob.onlinetypes.not_online',
  'glob.onlinetypes.checkbox',
  'glob.onlinetypes.num_selectable',
  'glob.onlinetypes.fix',
];
/*
const useFormStyles = makeStyles(theme => ({
  colorfield: {
    zIndex: 200,
  },
}));
*/
const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  
  const [add, setAdd] = useState(false);
  const [checkCnt, setCheckCnt] = useState({});
  const [checks, setChecks] = useState(false);
  const classes = {} //useFormStyles();

  const SimpleNumInput = ({name, disabled, mutationMode, helperText, ...props}) => {
    const inp = useController({ name, defaultValue: '' });
    return (
      <input {...inp.field} type="number" style={{border: 'none', padding:'5px', width:'100px',textAlign:'right', backgroundColor:disabled?'#FFF':'#EEE' }} />
    )        
  }                                                                         
  const SimpleCheckbox = ({name, i, ...props}) => {
    const { setValue } = useFormContext();
    return (
      <input type="checkbox" checked={checks[i]} 
        onChange={e=> { 
          checks[i] = !checks[i];
          setChecks(checks); 
          setCheckCnt(checkCnt+1); 
          setValue(name, checks[i], { shouldDirty: true });
        }} 
        style={{border: 'none', padding:'5px', width:'20px',textAlign:'right' }} 
      />
    )        
  }
  const Cell = ({ type, ...props}) => {
    switch(type) {
      case 'value': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc', textDecoration:props.disabled?'line-through':'none'}}>{props.value}</td>)
      case 'num': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...props} ><SimpleNumInput {...props} /></td>)
      case 'check': return (<td style={{padding: '5px 1em', borderBottom:'1px solid #ccc'}} {...props} ><SimpleCheckbox {...props} /></td>)
      default: return null;
    }
  }
  
  if (checks===false && record.tzusatz_preis_anz) {
    var chk = {};
    for(var i=0; i<record.tzusatz_preis_anz; i++) {
      chk[i] = record.tzusatz_preis[i].addsub;
    }
    setChecks(chk);
  }
  var rows = [];
  for(i=0; i<record.tzusatz_preis_anz; i++) {
    if (add || record.tzusatz_preis[i].id>0) {
      const disabled = !checks[i];
      rows.push(
        <tr key={i}>
          <Cell type="check" name={"tzusatz_preis."+i+".addsub"} i={i} />
          <Cell type="value" value={record.tzusatz_preis[i].bezeichnung} disabled={disabled}/>
          <Cell type="num" name={"tzusatz_preis."+i+".preis"} disabled={disabled} />
          <Cell type="num" name={"tzusatz_preis."+i+".mwst"} disabled={disabled}  />
          <Cell type="value" value={translate(onlinetypes[record.tzusatz_preis[i].online_type])} disabled={disabled} />
        </tr>
      )
    }
  }
  
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextInput source="bezeichnung" />
      <ColorInput source="farbe" disableAlpha={true} className={classes.colorfield} />
      <Table style={{width:'auto', borderCollapse:'collapse', padding:0}} >
        <tbody>
          <tr align="left">
            <th></th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('resources.calService.fields.zusatzleistung')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('resources.calService.fields.preis')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('resources.calService.fields.mwst')}</th>
            <th style={{padding: '5px 1em', borderBottom:'1px solid #333'}}>{translate('resources.calService.fields.online_type')}</th>
          </tr>
          {rows}
          <tr><td colSpan="4">
      {!add?
        <SimpleButton onClick={e => {setAdd(!add);}} variant="contained" color="primary" style={{marginTop:'1em',width:'300px'}}>{translate('bw.cal.service.titleCreate')}</SimpleButton>
      :null}
          </td></tr>
        </tbody>
      </Table>
    </SimpleForm>
  )
};

const CForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextInput source="bezeichnung" />
      <ColorInput source="farbe" disableAlpha={true} />
    </SimpleForm>
  )
};
const ListFilter = (props) => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
      {getRecht(permissions, 'calService')>=9 && <BooleanInput source="showall" label="bw.cal.service.showall" alwaysOn /> }
    </Filter>
  )
}

const Service = {
  list: ({ ...props }) => (
    <List {...props} title="bw.cal.service.title" sort={{ field: 'bezeichnung', order: 'ASC' }} filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons />}>
        <ColorField source="bezeichnung" label="bw.cal.service.servicegroup" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
        <ServiceCopy />
      </Datagrid>
    </List>
  ),
  
  create: ({ ...props }) => (
    <Create {...props} title="bw.cal.service.titleCreate" >
      <CForm {...props} />
    </Create>
  ),
  
  edit: ({ ...props }) => (
    <Edit title="bw.cal.service.titleEdit" mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Service