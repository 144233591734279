import React from 'react';
import { getGlobalData, isHauptadmin, formatImg } from '../../lib/globalData';
import { useTranslate, useRecordContext, List, Filter, Edit, Datagrid, SimpleForm, FormDataConsumer,
  TextField, ImageField, EditButton, TextInput, ImageInput, 
  BooleanInput, SelectInput, ReferenceInput } from 'react-admin';
import BooleanRedField from '../../fields/BooleanRedField';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import Grid from '@mui/material/Grid';

const ListForm = (props) => {
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <List {...props} title={('bw.bwh.service.title')} exporter={isHauptadmin() && props.exporter} 
      pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <TextField source="bezeichnung" />
        <BooleanRedField source="onbwh" label="bw.bwh.service.onbwh" />
        <BooleanRedField source="free_cancel" label="bw.bwh.service.free_cancel_list" />
        <ImageField source="img" sortable={false} />
        {bwh_sprachen.map((sp)=>(<TextField label={sp.toUpperCase()} source={"titel_" + sp} sortable={false} key={sp} />))}
        
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  const {undoable, save, saving, ...rest} = props;
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}>
      <FormDataConsumer {...rest}>
      {({ formData, dispatch, ...rest }) => (
        <Grid container style={{width:'100%'}}>
          <Grid item xs={12} md={9}>
            <p style={{marginTop:'0.1em', marginBottom:'0.1em'}}>{translate("bw.bwh.service.title")}</p>
            <p style={{marginTop:'0.1em', marginLeft:'0.5em', marginBottom:'1.5em', fontSize:'larger'}}>{record.bezeichnung}</p>
            <BooleanInput source="onbwh" label="bw.bwh.service.onbwh" />
        {(formData && formData.onbwh) &&
            <BooleanInput source="free_cancel" label="bw.bwh.service.free_cancel" />
        }
        {formData && formData.onbwh?  
            <ImageInput source="img" accept="image/*" format={formatImg} {...rest} >
              <ImageField source="src" title="img" />
            </ImageInput>
        :null}
          </Grid>
        {formData && formData.onbwh?  
          <React.Fragment>
            <Grid item xs={12} md={3}>
              <ImageField source="img" {...rest} />
            </Grid>
            <Grid item xs={12} md={9}>
            <Grid container style={{width:'100%'}}>
              {bwh_sprachen.map((sp)=>(
                <React.Fragment key={sp}>
                <Grid item xs={12} md={1}>
                  <p style={{marginTop:'1.3em', marginBottom:0}}>{sp.toUpperCase()}</p>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Grid container style={{width:'100%'}}>
                    <Grid item xs={11} md={11}>
                      <TextInput multiline fullWidth source={"titel_" + sp} label="bw.bwh.service.titel" />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <TextInput multiline fullWidth source={"kurzbeschr_" + sp} label="bw.bwh.service.kurzbeschr" />
                    </Grid>
                  </Grid>
                </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <p>{translate('bw.bwh.service.deactivate')}</p>
            </Grid>
          </React.Fragment>
        :null}
        </Grid>
      )}
      </FormDataConsumer>
    </SimpleForm>
  )
};
const ListFilter = (props) => (
<Filter {...props} >
  <ReferenceInput source="id" reference="bwhService" alwaysOn >
    <SelectInput optionText="bezeichnung" label="bw.bwh.service.bezeichnung" />
  </ReferenceInput>
</Filter>
);

const Service = {
  list: (props) => (
    <ListForm {...props} filters={<ListFilter />} />
  ),
  
  edit: (props) => (
    <Edit title='bw.bwh.service.titleEdit' mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Service;