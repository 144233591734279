import React from 'react';
import { setGlobalData, getGlobalData } from './globalData';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import SwapHorizontalCircle from '@mui/icons-material/SwapHorizontalCircle';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import dataProvider from './dataProvider';

class SwitchHostel extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = event => {
    this.setState({ open: true });
    dataProvider.getOne('authSwitch', {
        id: 0,
        filter: { date_gte: true },
        sort: { field: 'date', order: 'DESC' },
        pagination: { page: 1, perPage: 50 },
    })
      .then(response => {
        this.setState({switchdata:response.data});
      })
      .catch(response => {
        window.location.hash = '/login';
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };  
  
  doSwitch = (id, action) => {
    dataProvider.update('authSwitch', { id: id, data: {action: action }})
      .then(res  => {
        const info = getGlobalData('info');
        if (res.data.info) {
          setGlobalData({info: {...info, ...res.data.info}});
          localStorage.setItem('info', JSON.stringify({...info, ...res.data.info}));
          if (res.data.info.token) {
            setGlobalData({token: res.data.info.token});
            localStorage.setItem('token', res.data.info.token);
          }
        }
        if (res.data.menu) {
          setGlobalData({menu: res.data.menu});
          localStorage.setItem('menu', JSON.stringify(res.data.menu));
        }
        this.handleClose();
        window.location.href = '/';
      })
      .catch(response => {
        window.location.hash = '/login';
      });
      
  };

  handleSelectHostel = event => {
    this.doSwitch(Number(event.target.value), 'switchhostel');
  };
  
  handleSelectGroup = event => {
    this.doSwitch(Number(event.target.value), 'switchgroup');
  };
  
  handleSelectUser = event => {
    this.doSwitch(Number(event.target.value), 'switchuser');
  };
  
  handleBack = () => {
    this.doSwitch(0, 'switchback');
  };  
  
  handleKeypress = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      this.doSwitch(Number(ev.target.value), 'switchhostel');
    }    
  };
  
  render() {
      const info = getGlobalData('info');

      return (
          <div>
              <Tooltip title="Switch hostel">
                  <IconButton
                      color="inherit"
                      onClick={this.handleClickOpen}
                  >
                      <SwapHorizontalCircle />
                  </IconButton>
              </Tooltip>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={this.state.open}
                onClose={this.handleClose}
              >
                <DialogTitle>Switch hostel / rights</DialogTitle>
                <DialogContent sx={{"form > div":{margin:"16px 0"}}} >
                  <form>
                    <Stack direction="row" spacing={2} >
                      <TextField 
                        label="Hostel ID"
                        value={this.state.name}
                        type="number"
                        autoFocus={true}
                        onKeyPress={this.handleKeypress}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{width:'100%'}}
                      />
                      <FormControl sx={{width:'100%'}}>
                        <InputLabel htmlFor="hostelselect2">Hostel</InputLabel>
                        <Select
                            native
                            id="hostelselect2"
                            value="0"
                            onChange={this.handleSelectHostel}
                            variant="standard"
                        >
                          <option value="0">--</option>
                          {info.sw_hostels && info.sw_hostels.map(el => (
                            <option value={el.hostel_id} key={el.hostel_id}>
                              {el.hostel} [{el.hostel_id}]
                            </option>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} >
                      <FormControl sx={{width:'100%'}}>
                        <InputLabel htmlFor="hostelselect">Hostel</InputLabel>
                        <Select
                            id="hostelselect"
                            value="0"
                            onChange={this.handleSelectHostel}
                            variant="standard"
                            
                        >
                          {info.sw_hostels && info.sw_hostels.map(el => (
                            <MenuItem value={el.hostel_id} key={el.hostel_id}>
                              {el.img?(<Avatar alt={el.hostel} src={el.img} />):null}
                              <p>&nbsp;{el.hostel} [{el.hostel_id}]</p>
                            </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <FormControl sx={{width:'100%'}}>
                        <InputLabel htmlFor="groupselect">User Group</InputLabel>
                        <Select
                            id="groupselect"
                            value="0"
                            onChange={this.handleSelectGroup}
                            variant="standard"
                        >
                          {info.sw_groups && info.sw_groups.map(el => (
                            <MenuItem value={el.fgr_id} key={el.fgr_id}>
                              <p>{el.bezeichnung}</p>
                            </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Stack>
                    <FormControl  sx={{width:'50%'}}>
                      <InputLabel htmlFor="userselect">User</InputLabel>
                      <Select
                          id="userselect"
                          value="0"
                          onChange={this.handleSelectUser}
                          variant="standard"
                      >
                        {info.sw_users && info.sw_users.map(el => (
                          <MenuItem value={el.fbe_id} key={el.fbe_id}>
                            <p>{el.benutzername}</p>
                          </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </form>
                </DialogContent>
                <DialogActions>
                  {info && info.back?<Button onClick={this.handleBack} color="primary">Switch Back</Button>:null}
                  <Button onClick={this.handleClose} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>              
          </div>
      );
  }
}
export default SwitchHostel;