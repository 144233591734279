import React, { useState } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, TextField, 
  EditButton, ReferenceInput, AutocompleteInput, ReferenceField } from 'react-admin';
import Move from '../../lib/Move';
import BulkActionButtons from '../../lib/BulkActionButtons';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const {classes, ...rest} = props;
  return (
    <List {...rest} title="bw.set.prefcountry.title" sort={{ field: 'pos', order: 'ASC' }} 
      exporter={isHauptadmin() && props.exporter} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons />} >
        <TextField source="pos" textAlign="right" />
        <Move label="glob.move" getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setPrefcountry" />
        <ReferenceField source="id_land" reference="setCountry" link={false} >
          <TextField source="name" />
        </ReferenceField>
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <ReferenceInput source="id_land" reference="setCountry" sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  )
};

const Prefcountry = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.prefcountry.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.prefcountry.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Prefcountry