import React, { useState, useEffect } from 'react';
import { useTranslate, Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import dataProvider from '../lib/dataProvider';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const PwdChangeInput = ({ source="pwd", save, saving, undoable, ...rest }) => {
  const { getValues, setValue, watch } = useFormContext();
  const translate = useTranslate();
  const [copied, setCopy] = useState(false);
  
  useEffect(() => {
    dataProvider.getOne('setDbuser/getnewpassword', { id: 1 })
    .then(response => {
      setValue(source, response.data, { shouldDirty: true });
    });
  }, [setValue, source ]);

  const handleCopyClick = () => {
    setCopy(translate("glob.pwdcopied"));
  };

  watch(source)
  const pwd = getValues(source);
  
  return (
    <Grid container style={{width:'100%'}}>
      <Grid item xs={12} md={6}>
        <div>
          <CopyToClipboard onCopy={handleCopyClick} text={pwd}>
            <Button>
              <b style={{border:"1px solid red",padding: "1em",textTransform:"none"}}>{pwd}</b>
            </Button>
          </CopyToClipboard>
          <span>{copied}</span>
          <div style={{marginTop:"1em"}}>{translate("glob.pwdremark")}</div>
        </div>
      </Grid>
      <Grid item xs={12} md={2}>
        <input name={source} type="hidden" value={pwd} />
      </Grid>
    </Grid>
  )
};

export { PwdChangeInput }; // decorate with redux-form's <Field>
