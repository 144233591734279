/*
  - Fehlerbehandung ungülige Resnr
*/
import React, { Component } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import ExportIcon from 'mdi-react/ExportIcon'
import ImportIcon from 'mdi-react/ImportIcon'
import DeleteIcon from '@mui/icons-material/Delete';
import Backspace from '@mui/icons-material/Backspace';

import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dataProvider from '../../lib/dataProvider';


const BaseInfo = ({ reslist, fnDel }) => {
  var formatter = new Intl.DateTimeFormat('en-us', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'});
  return (
    <Table style={{width:'auto'}}>
    {reslist.length>0?
      <TableHead>
        <TableRow>
          <TableCell>Reservaition Number</TableCell>        
          <TableCell>Guest name</TableCell>        
          <TableCell>Arrival</TableCell>        
          <TableCell>Departure</TableCell>        
          <TableCell>Nights</TableCell>        
        </TableRow>
      </TableHead>
      :null}
      <TableBody>
      {reslist.map((el, i) => { 
        var d = new Date(el.ankunft);
        var ankunft = formatter.format(d);
        d = new Date(el.abreise);
        var abreise = formatter.format(d);
        return (
        <TableRow style={{height:'auto'}} key={i}>
          <TableCell>{el.res_nr}</TableCell>        
          <TableCell>{el.name}</TableCell>        
          <TableCell>{ankunft}</TableCell>        
          <TableCell>{abreise}</TableCell>        
          <TableCell>{el.dauer}</TableCell>        
          {fnDel?<TableCell><Button color="primary" onClick={fnDel(i)}><DeleteIcon /></Button></TableCell>:null}
        </TableRow>
      )}
    )}
      </TableBody>
    </Table>
)};


class TransferPage extends Component {
  
  state = {
      status : '',
      res_nr : 0,
      reslist : [],
      importdata : ["d21zdmtSa3FlOWpBczEwYg","d1dzdmtSa3FlOWlWQ0d3Yg"],
      current : {},
  };

  handleButton = event => {
    const v = event.currentTarget.value;
    this.setState({status:v});
    
    if (v === 'importnext') {
      var list = this.state.importdata;
      var current = list.shift();
//      this.setState({ importdata: list });
      dataProvider.getOne('setTransfer', { id: current })
        .then(res  => {
          this.setState({ current: res.data });
        })
    }
  };
    
  handleChange = name => event => {
    var v = event.target.value;
    if (name==='importdata') {
      v = v.split("\n");
    }
    this.setState({ [name]: v });
  };    
    
  addResnr = event => {
    dataProvider.getOne('setTransfer', { id: this.state.res_nr })
      .then(res  => {
        var list = this.state.reslist;
        list.push(res.data);
        this.setState({ reslist: list });
      })
    this.setState({ res_nr: '' });
  };    
    
  delResnr = index => event => {
    var list = this.state.reslist;
    list.splice(index, 1);
    this.setState({ reslist: list });
  };    
    
  render() {
    const {
      status,
      res_nr,
      reslist,
      current,
    } = this.state;
    if (status==='export') {
        return (
        <CardContent>
          <TextField
            id="res-number"
            label="Reservation number"
            onChange={this.handleChange('res_nr')}
            type="number"
            value={res_nr}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
          <Button variant="contained" color="primary" value="export" onClick={this.addResnr}> Add</Button><br /><br />
          <BaseInfo reslist={reslist} fnDel={this.delResnr} />
          {reslist.length>0?
          <Button variant="contained" color="primary" value="ShowCodes" onClick={this.handleButton} style={{marginTop:'2em'}}><ExportIcon style={{marginRight:'0.5em'}}/> Create transfer codes</Button> 
          :null}
          <br/><Button variant="contained" color="primary" value="" onClick={this.handleButton} style={{marginTop:'1em'}}><Backspace style={{marginRight:'1em'}} />Back</Button> 
        </CardContent>
      )
    }
    else if (status==='ShowCodes') {
      var content = '';
      reslist.map((el) => {
        content += el.code + "\n";
        return true;
      });
      return (
        <CardContent>
          <textarea rows={reslist.length+1} cols="30">{content}</textarea><br/><br/>
          <Button variant="contained" color="primary" value="" onClick={this.handleButton}><Backspace style={{marginRight:'0.5em'}}/>Done</Button> 
        </CardContent>
      )
    }
    else if (status==='import') {
      return (
        <CardContent>
          <textarea rows="15" cols="30" onChange={this.handleChange('importdata')}></textarea><br/><br/>
          <Button variant="contained" color="primary" value="importnext" onClick={this.handleButton}><ExportIcon style={{marginRight:'0.5em'}}/>Import</Button> 
          <br/><Button variant="contained" color="primary" value="" onClick={this.handleButton} style={{marginTop:'1em'}}><Backspace style={{marginRight:'0.5em'}} />Back</Button> 
        </CardContent>
      )
    }
    else if (status==='importnext') {
      if (current.ankunft) {
        return (
          <CardContent>
            <BaseInfo reslist={[current]} fnDel={false} />
            <Table style={{marginTop:'2em', width:'auto'}}>
              <TableHead>
                <TableRow>
                  <TableCell>Current category</TableCell>        
                  <TableCell>Room</TableCell>        
                  <TableCell>No. of beds</TableCell>        
                  <TableCell>Select target room</TableCell>        
                </TableRow>
              </TableHead>
              <TableBody>
              {current.zimmer.map((el, i) => { 
                return (
                <TableRow style={{height:'auto'}} key={i}>
                  <TableCell>{el.preiskat}</TableCell>        
                  <TableCell>{el.zimmer}</TableCell>        
                  <TableCell>{el.anzpers}</TableCell>        
                  <Select native value="0" onChange={null} />
                </TableRow>
              )}
            )}
              </TableBody>
            </Table>
            
            <Button variant="contained" color="primary" value="importnext" onClick={this.handleButton}><ExportIcon style={{marginRight:'0.5em'}}/>Import</Button> 
            <br /><Button variant="contained" color="primary" value="" onClick={this.handleButton}><Backspace style={{marginRight:'1em'}} /> Back</Button> 
          </CardContent>
        )
      }
      else {
        return null;
      }
    }
    else {
      return (
        <CardContent>
          <Button variant="contained" color="primary" value="export" onClick={this.handleButton}><ExportIcon style={{marginRight:'0.5em'}}/> Send Reservation to other property</Button><br /><br />
          <Button variant="contained" color="primary" value="import" onClick={this.handleButton}><ImportIcon style={{marginRight:'0.5em'}}/>Receive Reservation from other property</Button> 
        </CardContent>
      )
    }
  }
}


export class setTransfer extends Component {
  render() {
    return (    
    <Card>
        <Title title="Transfer Reservations" />
        <TransferPage />
    </Card>
    )
  }
};

export default setTransfer;
