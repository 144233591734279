import React, { useState } from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, List, Create, Edit, Datagrid, SimpleForm, ReferenceManyField, 
  TextField, minLength, maxLength, EditButton, SelectField, 
  TextInput, Filter } from 'react-admin';
import BooleanRedField from '../../fields/BooleanRedField';
import BooleanInputAdd from '../../fields/BooleanInputAdd';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import { ColorInput } from 'react-admin-color-picker';
import ColorField from '../../fields/ColorField';
import GenderField from '../../fields/GenderField';

const validateKbez = [minLength(2), maxLength(4)];

const onlinechoices=[
  { id:  0, name: 'glob.onlinechoices.not_online' }, 
  { id:  1, name: 'glob.onlinechoices.cons_book_woman' },
  { id:  2, name: 'glob.onlinechoices.cons_book_man' },
  { id:  3, name: 'glob.onlinechoices.cons_book_woman_man' },
  { id:  4, name: 'glob.onlinechoices.cons_book_mixed' },
  { id:  5, name: 'glob.onlinechoices.cons_book_woman_mixed' },
  { id:  6, name: 'glob.onlinechoices.cons_book_man_mixed' },
  { id:  7, name: 'glob.onlinechoices.cons_book_all' },
  { id:  8, name: 'glob.onlinechoices.ign_book_woman' },
  { id:  9, name: 'glob.onlinechoices.ign_book_man' },
  { id: 10, name: 'glob.onlinechoices.ign_book_mixed' },
];

const ExpandPanel = ({ ...rest }) => {
  return ( 
    <ReferenceManyField reference="setRoom" target="id_preiskat" sort={{ field: 'bezeichnung', order: 'ASC' }} {...rest}>
      <Datagrid>
        <TextField source="bezeichnung" />
        <GenderField source="geschlecht" sortable={false} />
        <SelectField source="online" choices={onlinechoices} sortable={false}/>
        <TextField source="bett" sortable={false} />
        <TextField source="zusatz" sortable={false} />
      </Datagrid>
    </ReferenceManyField>
  )
};

const NoDispZero = ({source, ...props }) => {
  const record = useRecordContext(props);
  return record && record[source]?record[source]:'';
}

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const {classes, ...rest} = props;
  return (
    <List {...rest} title="bw.set.roomcategory.title" 
      sort={{ field: 'pos', order: 'ASC' }} filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} expand={<ExpandPanel {...props} />}>
        <TextField source="pos" textAlign="right" />
        <Move getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setRoomcategory" />
        <ColorField source="bezeichnung" />
        <TextField source="kurzbez" />
        <BooleanRedField source="in_bfs" label="bw.set.roomcategory.in_bfs" />
        <BooleanRedField source="in_daily_list" label="bw.set.roomcategory.in_daily_list" />
        <BooleanRedField source="in_report" label="bw.set.roomcategory.in_report" />
        <NoDispZero source="room_cnt" label="bw.set.roomcategory.room_cnt" textAlign="right" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  const tr = useTranslate();
  const record = useRecordContext();
  
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <TextInput source="bezeichnung" />
      <TextInput source="kurzbez" validate={validateKbez} />
      { record && record.id > 0 &&
        bwh_sprachen.map((sp)=>(<TextInput multiline label={sp.toUpperCase()+' '+tr("bw.bwh.roomcategory.titel")} fullWidth source={"titel_" + sp} />))
      }
      <h3>{tr('bw.set.roomcategory.dormbookSetting')} </h3>
      <ColorInput source="farbe" disableAlpha={true} />
      <h3>{tr('bw.set.roomcategory.reportSetting')} </h3>
      <BooleanInputAdd source="in_bfs"  />
      <BooleanInputAdd source="in_daily_list" />
      <BooleanInputAdd source="in_report" />
      { record && record.id > 0 &&
      <React.Fragment>
        <h3>{tr('bw.set.roomcategory.units')} </h3>
        <div>{tr('bw.set.roomcategory.units_info')}</div> 
        {bwh_sprachen.map((sp)=>(<TextInput multiline label={sp.toUpperCase()+' '+tr("bw.bwh.roomcategory.units")} fullWidth source={"units_" + sp} />))}
        <h3>{tr('bw.set.roomcategory.bwoSetting')}</h3>
        <ReferenceManyField reference="bwhRoomcategory" target="id" filter={{ lang: 'full' }} label="" {...props}>
          <Datagrid style={{ width: '100%' }} >
            <TextField source="lang" sortable={false} />
            <TextField source="kurzbeschr" sortable={false} />
            <TextField source="beschr" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
        <h3> {tr('bw.set.roomcategory.roomSetting')} </h3>
        <ReferenceManyField reference="setRoom" target="id_preiskat" sort={{ field: 'bezeichnung', order: 'ASC' }} label="" {...props}>
          <Datagrid style={{ width: 'auto' }} >
            <TextField source="bezeichnung" />
            <GenderField source="geschlecht" sortable={false} />
            <SelectField source="online" choices={onlinechoices} sortable={false}/>
            <TextField source="bett" sortable={false} />
            <TextField source="zusatz" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </React.Fragment>
      }
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" alwaysOn />
    </Filter>
);

const Roomcategory = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),
  
  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.roomcategory.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),
  
  edit: ({classes, ...props }) => (
    <Edit title="bw.set.roomcategory.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}   
export default Roomcategory