import { useRecordContext } from 'react-admin';

const UnitField = ({ source, unit, unitMap, ...props }) => {
  const record = useRecordContext();
   if (!record || typeof record[source] === 'undefined' || typeof record[unit] === 'undefined' || !unitMap){
    return null;
  }
  return record[source]+' '+unitMap[record[unit]].name;
};
export default UnitField;
