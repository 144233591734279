import React from 'react';
import { useRecordContext, Edit, TabbedForm, FormTab,
  TextInput, SelectInput, BooleanInput }  from 'react-admin';
import StayEditToolbar from '../../lib/StayEditToolbar';
import Grid from '@mui/material/Grid';
import { getInfoValue } from '../../lib/globalData';

const FormattedText = ({ text, ...props}) => {
  var style = {};
  var out = text;
  if(!text)return null;
  if(text.substr(0,2) === '# '){
    style = { fontWeight: 'bold'};
    out = text.substr(2);
  }
  return (
    <div style={style}>{out}</div>
  )
}

const CEForm = ({permissions, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const record = useRecordContext();
  if(!record && !record.tab_cnt)return null;

  const MultiInput = ({ data, ...rest }) => {
    if(!data.name)return null;
    const lang = getInfoValue('id_sprache')===1?"de":"en";
    var input = '';
    var label = data.name;
    if(data[lang].lbl)label = data[lang].lbl;
    var helpertext = null;
    if(data[lang].htxt){
      if(typeof data[lang].htxt === 'object'){
        helpertext = [];
        data[lang].htxt.forEach((txt)=>{helpertext.push(<FormattedText text={txt} />)});
      }
      else {
        helpertext = data[lang].htxt;
      }
    }
    var style = {width:'100%'};
    switch(data.type) {
      case 'title':
        label = <b style={{marginBottom: "1em"}}>{label}</b>;
        style = {...style, ...{height:'2em'}};
        break;
      case 'text':
        input = <TextInput {...props} label={false} helperText={false} source={'row.'+data.name} multiline/>;
        break;
      case 'bool':
        input = <BooleanInput {...props} label={false} helperText={false} source={'row.'+data.name}/>;
        break;
      case 'select':
        var options = [];
        if(typeof data[lang].choices === 'object'){
          options = data[lang].choices;
        }
        else if(typeof data[lang].choices === 'string'){
          data[lang].choices.split('|').forEach(opt=>{
            const opt2 = opt.split('@');
            options.push({id:opt2[0], name:opt2[1] });
          })
        }
        input = <SelectInput {...props} label={false} helperText={false} source={'row.'+data.name} choices={options} />;
        break;
      default:
    }
    if(!input && data.type !== 'title')return null;
    return (
      <Grid container style={style}>
        <Grid item xs={12} md={3} >{label}</Grid>
        <Grid item xs={12} md={4}>{input}</Grid>
        <Grid item xs={12} md={5}>{helpertext}</Grid>
      </Grid>
    )
  }

  return (
    <TabbedForm toolbar={<StayEditToolbar />} {...props}>
      {record.tabs.map(tab => (
        <FormTab label={tab.tab}  size={1} >
          {tab.fields.map(field => (
            <MultiInput data={field} />
          ))}
        </FormTab>
      ))}
    </TabbedForm>
  )
};

const Appopt = {
  list: null,
  edit: (props) => (
    <Edit title="bw.set.appopt.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}
export default Appopt
