import React, { useState } from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { apiPath } from '../../lib/isTest';
import { Edit, useTranslate, useRecordContext, SimpleForm, SelectInput, NumberInput, 
        useRefresh, useNotify, SelectArrayInput, 
        TextInput, Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import CloseToolbar from '../../lib/CloseToolbar';
import dataProvider from '../../lib/dataProvider';
import AddIcon from 'mdi-react/AddIcon';
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const asyncLoad = async (resource, setData) => {
  return new Promise(async (resolve, reject) => {
    var token = getGlobalData('token');
    const res = await fetch(apiPath+'v1/'+resource, {
      method: 'GET',
      headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
    })
    const json = await res.json();
    if (res.ok) {
      setData(json);
      resolve();
    }
    else reject(setData(false));
  })
};

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, permissions, ...props}) => {
  const record = useRecordContext();
  
  const [dload, setLoading] = useState(false);
  const [acdata, setAcdata] = useState(false);
  const [rcdata, setRcdata] = useState(false);
  const [checks, setChecks] = useState(false);
  const classes = {} // useSelectStyles();
  const translate = useTranslate();
  
  if(record.id && record.tpreis_mwst_anz !== dload){
    asyncLoad('setRoomcategory', setRcdata);
    asyncLoad('setAgecategory', setAcdata);
    setLoading(record.tpreis_mwst_anz);
    setChecks(false);
  }
  
  const SimpleCheckbox = ({name, i, ...props}) => {
    const { setValue } = useFormContext();
    return (
      <input type="checkbox" checked={checks[i]} 
        onChange={e=> { 
          const checks2 = {...checks};
          checks2[i] = !checks2[i]
          setChecks(checks2); 
          setValue(name, checks2[i], { shouldDirty: true });
        }} 
        style={{border: 'none', padding:'5px', width:'20px',textAlign:'right' }} 
      />
    )        
  }
  const SimpleSelectInput = ({ ...props}) => {
    if(!props.choices) return null;
    return (
      <SelectInput {...props} label="" helperText={false} />
    )
  }
  const Cell = ({ saving, undoable, save, type, ...props}) => {
    const {classes, label, optionText, validate, style, ...rest} = props;
    switch(type) {
      case 'value': return (<td style={{padding: '1px 0.5em', borderBottom:'1px solid #ccc', textDecoration:props.disabled?'line-through':'none'}}>{props.value}</td>)
      case 'num': return (<td style={{padding: '1px 0.5em', borderBottom:'1px solid #ccc'}} {...rest} ><NumberInput {...props} label="" helperText={false} /></td>)
      case 'text': return (<td style={{padding: '1px 0.5em', borderBottom:'1px solid #ccc'}} {...rest} ><TextInput {...props} label="" helperText={false} /></td>)
      case 'check': return (<td style={{padding: '1px 0.5em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleCheckbox {...props} /></td>)
      case 'select': return (<td {...rest} style={{padding: '0px 0.5em', borderBottom:'1px solid #ccc', ...style}}  ><SimpleSelectInput {...props} /></td>)
      default: return null;
    }
  }
  
  const AddPriceButton = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    if(!props.rcdata || !props.acdata)return null;
    const handleClick = () => {
      setShowDialog(true);
    };
    const handleCloseClick = () => {
      setShowDialog(false);
    };
    const doSave = (data) => {
      dataProvider.update('calMwst', { id: record.id, data:data })
        .then(({ data }) => {
          setShowDialog(false);
          refresh();
          setChecks(false);
        })
        .catch(error => {
          notify(error.message, 'error');
        })
    };
    return (
      <React.Fragment>
        <Button onClick={handleClick} label="bw.cal.mwst.titleCreate">
          <AddIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.mwst.titleCreate" >
          <DialogTitle>{translate("bw.cal.mwst.titleCreate")}</DialogTitle>
          <DialogContent>
            <SimpleForm resource="calMwst" onSubmit={doSave} toolbar={<CloseToolbar redir="/calPricegr/" noDelete noBack />} >
              <React.Fragment>
                <div>{translate('bw.cal.mwst.createInfo')}</div>
              </React.Fragment>
              <SelectArrayInput source="id_preiskat_add" choices={props.rcdata} optionText="bezeichnung" label='bw.cal.mwst.preiskat' />
              <SelectArrayInput source="id_alterskat_add" choices={props.acdata} optionText="bezeichnung" label='bw.cal.mwst.alterskat' />
            </SimpleForm>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  };
  var rows = [];
  if(rcdata && acdata){
    if(rcdata[0].id !== 0)rcdata.unshift({id: 0, bezeichnung: translate('bw.cal.mwst.all')});
    if(acdata[0].id !== -1)acdata.unshift({id: -1, bezeichnung: translate('bw.cal.mwst.all')}, {id: 0, bezeichnung: translate('bw.cal.mwst.empty')});
    const priceType = [];
    priceType.push({id: 0, name:record.currency+translate('bw.cal.mwst.fix')});
    priceType.push({id: 1, name:record.currency+translate('bw.cal.mwst.fixSep')});
    priceType.push({id: 2, name:translate('bw.cal.mwst.brut')});
    priceType.push({id: 3, name:translate('bw.cal.mwst.brutSep')});
    if (checks===false && record.tpreis_mwst_anz) {
      var chk = {};
      for(var i=0; i<record.tpreis_mwst_anz; i++) {
        chk[i] = record.tpreis_mwst[i].addsub;
      }
      setChecks(chk);
    }
    for(i=0; i<record.tpreis_mwst_anz; i++) {
      rows.push(
        <tr key={i}>
          <Cell type="check" name={"tpreis_mwst."+i+".addsub"} i={i} />
          <Cell type="select" source={"tpreis_mwst."+i+".id_alterskat"} choices={acdata} optionText="bezeichnung" className={classes.padding2} disabled={!checks[i]} style={{textDecoration:!checks[i]?'line-through':'none'}}/>
          <Cell type="select" source={"tpreis_mwst."+i+".id_preiskat"} choices={rcdata} optionText="bezeichnung" className={classes.padding2} disabled={!checks[i]} style={{textDecoration:!checks[i]?'line-through':'none'}} />
          <Cell type="text" source={"tpreis_mwst."+i+".text_de"} className={classes.padding2} disabled={!checks[i]} {...props} />
          <Cell type="text" source={"tpreis_mwst."+i+".text_en"} className={classes.padding2} disabled={!checks[i]} {...props} />
          <Cell type="text" source={"tpreis_mwst."+i+".text_fr"} className={classes.padding2} disabled={!checks[i]} {...props} />
          <Cell type="num" source={"tpreis_mwst."+i+".betrag"} className={classes.padding2} disabled={!checks[i]} {...props} />
          <Cell type="select" source={"tpreis_mwst."+i+".type"} choices={priceType} className={classes.padding2} disabled={!checks[i]} {...props} />
          <Cell type="num" source={"tpreis_mwst."+i+".mwst"} className={classes.padding2} disabled={!checks[i]} {...props} />
          {isHauptadmin() &&
            <Cell type="value" value={record.tpreis_mwst[i].id} disabled={!checks[i]}/>
          }
        </tr>
      )
    }
  }
  return (
    <SimpleForm toolbar={<CloseToolbar redir="/calPricegr/" noDelete />} {...props} style={{overflow:'auto' }}>
      <div style={{ width: 'auto'}} >
        <TextInput source="bezeichnung" label="bw.cal.mwst.preisgr" disabled helperText={false} />
        <p style={{ fontSize: 'normal', fontWeight: 'bold' }}>
          {translate('bw.cal.mwst.title')+record.bezeichnung+' ('+record.mwst+'%)'}
        </p>
        <p style={{ fontSize: 'smaller' }}>
          {translate('bw.cal.mwst.info')}
        </p>
      </div>
      <Table style={{width:'auto', borderCollapse:'collapse', padding:0}}>
        <tbody>
          <tr align="left" className={classes.table}>
            <th style={{ width:'30px' }}></th>
            <th style={{ width:'130px' }}>{translate('bw.cal.mwst.alterskat')}</th>
            <th style={{ width:'130px' }}>{translate('bw.cal.mwst.preiskat')}</th>
            <th>{translate('bw.cal.mwst.text_de')}</th>
            <th>{translate('bw.cal.mwst.text_en')}</th>
            <th>{translate('bw.cal.mwst.text_fr')}</th>
            <th style={{ width:'80px' }}>{translate('bw.cal.mwst.betrag')}</th>
            <th style={{ width:'100px' }}>{translate('bw.cal.mwst.type')}</th>
            <th style={{ width:'80px' }}>{translate('bw.cal.mwst.mwst')}</th>
            {isHauptadmin() &&
              <th style={{ width:'50px' }}>id</th>
            }
          </tr>
          {rows}
        </tbody>
      </Table>
      <AddPriceButton acdata={acdata} rcdata={rcdata} setChecks={setChecks}/>
    </SimpleForm>
  )
};

const mwst = {
  edit: ({ translate, ...props }) => (
    <Edit title='bw.cal.mwst.titleEdit' mutationMode="pessimistic" {...props} redirect="/calPricegr/">
      <CEForm {...props} />
    </Edit>
  )
}   
export default mwst;