import React, { Component } from 'react';
import DefaultIcon from '@mui/icons-material/ViewList';

import NestableMenuItem from './NestableMenuItem';
import menuIcons from './menuIcons';

import SetLang from './SetLang';

export class NestableMenuList extends Component {
    render() {
        const {
            list,
            onMenuClick,
            dense,
            menuct,
            sub
        } = this.props;
          
        var list2 = [];          
        if (sub) {
          list2 = list;
        }
        else {
          var active = 0;
          var menu = 0;
          var lastmenu = localStorage.getItem('lastmenu');
          if (lastmenu !== null) {
            if (menuct.activeMain===-1) {
              menu = parseInt(lastmenu);
            }
            else {
              menu = menuct.activeMain;
            }
          }
          active = menu + 1;
          var last = false;
          var k = 0;
          for(k in list) {
            var m = list[k];
            if (m.id_parent===0) {
              active--; 
            }
            else if (active === 0) {
              if (last===false || m.id_parent!==last) {
                m.children = [];
                list2.push(m);
                last = m.menu_id;
              }
              else {
                list2[list2.length-1].children.push(m);
              }
            }
          }
        }
        
        var res = [<SetLang key="SetLang" />];
        for(k in list2) {
          m = list2[k];
          res.push(
            <NestableMenuItem 
              key={m.route} 
              to={`/${m.route}`}
              primaryText={m.bez}
              leftIcon={
                  menuIcons[m.route] ? menuIcons[m.route] : <DefaultIcon />
              }
              onClick={onMenuClick}
              dense={dense}
              sub={sub}
              menuct={menuct}
              children={m.children}
            />
          );
        }
        return res;
    }
}

export default NestableMenuList;