import React from 'react';
import { getGlobalData, isHauptadmin, formatImg } from '../../lib/globalData';
import { List, Filter, Edit, Datagrid, SimpleForm, SingleFieldList, 
  ReferenceArrayField, FormDataConsumer, TextField, ReferenceManyField, ImageField, 
  EditButton, TextInput, ImageInput, BooleanInput, ReferenceInput, SelectInput, 
  ReferenceArrayInput, SelectArrayInput  } from 'react-admin';
import BooleanRedField from '../../fields/BooleanRedField';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import Grid from '@mui/material/Grid';

const IconField = ({ source, record}) => {
  if (!record || !record[source]){
    return null;
  }
  const classname="flaticon-"+record[source];
  return (
    <i className={(classname)}></i>
  ) 
}

const ListFilter = (props) => (
<Filter {...props} >
  <ReferenceInput source="id" reference="bwhRoomcategory" alwaysOn>
    <SelectInput optionText="bezeichnung" label="bw.bwh.roomcategory.bezeichnung" emptyText="bw.bwh.roomcategory.all" />
  </ReferenceInput>
</Filter>
);           
const ListForm = (props) => {
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  return (
    <List {...props} title={('bw.bwh.roomcategory.title')} exporter={isHauptadmin() && props.exporter} 
      filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
        <TextField source="bezeichnung" />
        <BooleanRedField source="onbwh" label="bw.bwh.roomcategory.onbwh" sortable={false} />
        <ImageField source="img" sortable={false} />
        {bwh_sprachen.map((sp)=>(<TextField label={sp.toUpperCase()} source={"titel_" + sp} sortable={false} key={sp} />))}
        <ReferenceArrayField label="Icons" reference="bwhIcon" target="id" source="icons" sortable={false}>
          <SingleFieldList linkType={false}  style={{maxWidth:'170px'}}>
            <IconField source="icon" {...props} />
          </SingleFieldList>
        </ReferenceArrayField>

        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const EForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const bwh_sprachen = getGlobalData('info').bwh_sprachen;
  const {undoable, save, saving, ...rest} = props;
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}>
      <FormDataConsumer {...rest}>
      {({ formData, dispatch, ...rest }) => (
        <Grid container style={{width:'100%'}}>
          <Grid item xs={12} md={9}>
            <TextField source="bezeichnung" />
            <BooleanInput source="onbwh" label="bw.bwh.roomcategory.onbwh" />
        {formData && formData.onbwh?  
            <ImageInput source="img" accept="image/*" format={formatImg} {...rest} >
              <ImageField source="src" title="img" />
            </ImageInput>
        :null}
          </Grid> 
        {formData && formData.onbwh?  
          <React.Fragment>
            <Grid item xs={12} md={3}>
              <ImageField source="img" {...rest} />
            </Grid>
            <Grid item xs={12} md={9}>
            <Grid container style={{width:'100%'}}>
              {bwh_sprachen.map((sp)=>(
                <React.Fragment key={sp}>
                <Grid item xs={12} md={1}>
                  <p style={{marginTop:'2.1em', marginBottom:0}}>{sp.toUpperCase()}</p>
                </Grid>
                <Grid item xs={12} md={11}>
                  <Grid container style={{width:'100%'}}>
                    <Grid item xs={11} md={11}>
                      <TextInput multiline fullWidth source={"titel_" + sp} label="bw.bwh.roomcategory.titel" />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <TextInput multiline fullWidth source={"kurzbeschr_" + sp} label="bw.bwh.roomcategory.kurzbeschr" />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <TextInput multiline fullWidth source={"beschr_" + sp} label="bw.bwh.roomcategory.beschr" />
                    </Grid>
                  </Grid>
                </Grid>
                </React.Fragment>
              ))}
            </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container style={{width:'100%'}}>
                <Grid item xs={12} md={12}>
                  <ReferenceArrayInput label="Icons" source="icons" reference="bwhIcon" {...rest}>
                    <SelectArrayInput optionText="beschreibung" />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ReferenceManyField reference="setRoom" target="id_preiskat" source="id" {...rest} sort={{ field: 'bezeichnung', order: 'ASC' }}>
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="bezeichnung" label="bw.bwh.roomcategory.associated_rooms" />
                    </Datagrid>
                  </ReferenceManyField>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        :null}
        </Grid>
      )}
      </FormDataConsumer>
    </SimpleForm>
  )
};

const Roomcategory = {
  list: (props) => (
    <ListForm {...props} />
  ),
  
  edit: (props) => (
    <Edit title='bw.bwh.roomcategory.titleEdit' mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Roomcategory