import React from 'react';
import { getInfoValue, isHauptadmin, getRecht } from '../../lib/globalData';
import { useRecordContext, useListContext, usePermissions, List, Create, Edit, Datagrid, SimpleForm,
        Filter, BooleanInput, TextInput, TextField,
        BulkDeleteButton, EditButton, Button } from 'react-admin';
import { Link } from 'react-router-dom';
import { ColorInput } from 'react-admin-color-picker';
import { stringify } from 'query-string';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import PricegrCopy from '../../lib/cal/PricegrCopy';
import ColorField from '../../fields/ColorField';
import PercentOutlineIcon from 'mdi-react/PercentOutlineIcon';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import AlignVerticalBottomIcon from 'mdi-react/AlignVerticalBottomIcon';
import CompareArrows from '@mui/icons-material/CompareArrows';

const PostBulkActionButtons = props => (
    <React.Fragment>
        <CompareButton comparePath='/calPricegrcompare' title='bw.cal.pricegr.compare' filterid='id_preis_gr' {...props} />
        <CompareButton comparePath='/calPricegrcomparerate' title='bw.cal.pricegr.compareRate' filterid='id_preis_gr' {...props} />
        { isVAT() &&
          <CompareButton comparePath='/calPricegrcomparevat' title='bw.cal.pricegr.compareVAT' filterid='id_preis_gr' {...props} />
        }
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} mutationMode="pessimistic" />
    </React.Fragment>
);

const CompareButton = ({ filterValues, comparePath, title, filterid, ...rest }) => {
  const { selectedIds } = useListContext();
  return (
    <Button variant="text" size="small" component={Link} label={title} title={title}
      to={{
        pathname: comparePath,
        search: stringify({
          page: 1, perPage: 1000, sort: 'bezeichnung', order: 'ASC',
          filter: JSON.stringify({ ...filterValues, ...{[filterid?filterid:'id']: selectedIds}}),
        }),
      }}
    >
      <CompareArrows />
    </Button>
  )
};

const PriceButton = () => {
  const record = useRecordContext();
  if(!record) return null;
  return (
    <Button
      component={Link}
      to={{
        pathname: '/calPrice/'+record.id,
      }}
      label='bw.cal.pricegr.titleEdit'
    >
      <AttachMoneyIcon />
    </Button>
  )
};

const MwstEditButton = () => {
  const record = useRecordContext();
  if(!record) return null;
  return (
    <Button
      component={Link}
      to={{
        pathname: '/calMwst/'+record.id,
      }}
      label='bw.cal.pricegr.editMwst'
    >
      <PercentOutlineIcon />
    </Button>
  )
};

const RateEditButton = () => {
  const record = useRecordContext();
  if(!record) return null;
  return (
    <Button
      component={Link}
      to={{
        pathname: '/calRate/'+record.id,
      }}
      label='bw.cal.pricegr.editRate'
    >
      <AlignVerticalBottomIcon />
    </Button>
  )
};

/*
const useFilterStyles = makeStyles(theme => ({
  bool: {
    marginBottom: theme.spacing(1),
  },
}));
*/
const ListFilter = (props) => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
      {getRecht(permissions, 'calPricegr')>=9 && <BooleanInput source="showall" label="bw.cal.pricegr.showall" alwaysOn /> }
    </Filter>
  )
}

const isVAT = () => {
  return getInfoValue('splitVAT');
}

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, permissions, ...props}) => (
  <SimpleForm toolbar={<CloseToolbar />} {...props}>
    <TextInput source="bezeichnung" />
    <TextInput source="mwst" />
    <ColorInput source="farbe" disableAlpha={true} />
  </SimpleForm>
)

const pricegr = {
  list: ({ ...props }) => (
    <List {...props} title="bw.cal.pricegr.title" sort={{ field: 'bezeichnung', order: 'ASC' }} filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} bulkActionButtons={<PostBulkActionButtons />} >
      <Datagrid style={{ width: 'auto' }}>
        <ColorField source="bezeichnung" />
        <TextField source="mwst" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <PriceButton />
        {isVAT() &&
          <MwstEditButton />
        }
        <RateEditButton />
        <EditButton label="" icon={<SettingsIcon />} />
        <PricegrCopy />
      </Datagrid>
    </List>
  ),

  create: ({ ...props }) => (
    <Create {...props} title="bw.cal.pricegr.titleCreate" redirect="list">
      <CEForm {...props} />
    </Create>
  ),

  edit: ({ ...props }) => (
    <Edit title="bw.cal.pricegr.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}

export default pricegr;
/*
      <ReferenceManyField
        addLabel={false}
        reference="calpricegrprice"
        target="id_zusatz_gr"
        sort={{ field: 'bezeichnung', order: 'ASC' }}
        redirect="edit"
        fullWidth
      >
        <Datagrid style={{width: 'auto'}}>
          <TextField source="bezeichnung" />
          <TextField source="preis" />
          <TextField source="mwst" />
          <EditButton />
          <DeletePriceButton />
        </Datagrid>
      </ReferenceManyField>
*/
