import React from 'react';
import Card from '@mui/material/Card';

const styles = {
    card: {
        float: 'left',
        margin: '-20px 20px 0 15px',
        zIndex: 100,
        borderRadius: 1,
    },
    icon: {
        float: 'right',
        width: 56,
        height: 56,
        padding: 14,
        color: '#fff',
    },
};

const CardIcon = ({ Icon, classes, bgColor }) => (
    <Card sx={{...styles.card, backgroundColor: bgColor }}>
        <Icon style={styles.icon} />
    </Card>
);

export default CardIcon;