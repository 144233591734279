/*
Liste Gastfelder für Buchungssprache
Textfelder erstellen
*/
import React from 'react';
import { getGlobalData, isHauptadmin } from '../../lib/globalData';
import { apiPath, testsystemId } from '../../lib/isTest';
import { useTranslate, useRecordContext, Edit, TabbedForm, FormTab,
  TextInput, NumberInput, BooleanInput, SelectInput, SelectArrayInput,
  ReferenceInput, AutocompleteInput, ReferenceArrayInput,
  AutocompleteArrayInput, FormDataConsumer  }  from 'react-admin';
import StayEditToolbar from '../../lib/StayEditToolbar';
import Grid from '@mui/material/Grid';

const statusChoices = [
  { id: 1, name: 'bw.bwh.settings.inaktiv' },
  { id: 2, name: 'bw.bwh.settings.test' },
  { id: 3, name: 'bw.bwh.settings.aktiv' },
  { id: 4, name: 'bw.bwh.settings.haupt' },
];
const genderChoices = [
  { id: 0, name: 'bw.bwh.settings.MixedOnly' },
  { id: 3, name: 'bw.bwh.settings.MixedOnlyAndFemale' },
  { id: 4, name: 'bw.bwh.settings.FemaleOnly' },
  { id: 1, name: 'bw.bwh.settings.AllPossibilities' },
  { id: 2, name: 'bw.bwh.settings.NoGenderFilter' },
  { id: 5, name: 'bw.bwh.settings.NoDorm' },
  { id: 6, name: 'bw.bwh.settings.NoFilter' },
];
const usearrivaltimeChoices = [
  { id: 0, name: 'bw.bwh.settings.No' },
  { id: 1, name: 'bw.bwh.settings.Optional' },
  { id: 2, name: 'bw.bwh.settings.Mandatory' },
];
const domainChoices = [
  { id: 'bookworldhostels.com', name: 'bookworldhostels.com' },
  { id: 'bookworldhotels.com', name: 'bookworldhotels.com' },
  { id: 'bookworldonline.com', name: 'bookworldonline.com' },
  { id: 'bookworldstay.com', name: 'bookworldstay.com' },
  { id: 'bookworldcamping.com', name: 'bookworldcamping.com' },
];
const asyncValidate = async (values, dispatch, props, field) => {
  return new Promise(async (resolve, reject) => {
    var token = getGlobalData('token');
    const res = await fetch(apiPath+'v1/bwhSettings/verify', {
      method: 'PUT',
      body: JSON.stringify({ values: values }),
      headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
    })
    const json = await res.json();

    if (json.ok) return resolve()
    resolve(json.errors)
  })
}

const conditionTrue = (record, condition) => {
  if(record && record[condition])return true;
  return false;
}

const timeParser = v => {
  const match = /([:0-9]{1,5})/.exec(v);
  if (match === null) return;

  return match[1];
};

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const {undoable, save, saving, ...rest} = props;
  const translate = useTranslate();
  const record = useRecordContext();
  
  return (
    <TabbedForm toolbar={<StayEditToolbar />} {...props} validate={asyncValidate} asyncBlurFields={['bwh_ident','bwh_childaltersspan[0]']}>
      <FormTab label="bw.bwh.settings.grund"  size={1} >
        <SelectInput source="bwh_status" choices={statusChoices} />
        <TextInput source="bwh_ident" />
        <SelectInput source="bwh_domain" choices={domainChoices} />
        <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => {
            const domain = testsystemId?'bwhdorm'+testsystemId+'.alixon.ch':formData.bwh_domain
            return (
              <div dangerouslySetInnerHTML={{__html:translate('bw.bwh.settings.identDesc', {bwh_ident:formData.bwh_ident,bwh_domain:domain})}} />
            )
          }
        }
        </FormDataConsumer>
        <ReferenceInput source="bwh_defaultsprache" reference="setSprache">
          <AutocompleteInput optionText="bezeichnung" />
        </ReferenceInput>
        <ReferenceArrayInput source="bwh_sprachen" reference="setSprache">
          <AutocompleteArrayInput optionText="bezeichnung" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab label="bw.bwh.settings.konfig"  size={1} >
        <Grid container style={{width:'100%'}} spacing={2}>
          <Grid item xs={12} md={4}>
            <NumberInput label="bw.bwh.settings.bwh_def_personen" source="bwh_def_personen" inputProps={{ min: 1, max: 10 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput label="bw.bwh.settings.bwh_anz_personen" source="bwh_anz_personen" inputProps={{ min: 1, max: 50 }} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="bw.bwh.settings.bwh_anz_naechte" source="bwh_anz_naechte" inputProps={{ min: 1, max: 100 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberInput label="bw.bwh.settings.lastNewRes" helperText={translate('bw.bwh.settings.lastNewRes_helpertext')} source="lastNewRes" inputProps={{ min: 0 }} />
          </Grid>
          <Grid item xs={12} md={8}>
            <NumberInput label="bw.bwh.settings.lastChange" helperText={translate('bw.bwh.settings.lastChange_helpertext')} source="lastChange" inputProps={{ min: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <BooleanInput label="bw.bwh.settings.bwh_childindorm" source="bwh_childindorm" helperText={translate('bw.bwh.settings.bwh_childindorm_helpertext')} />
          </Grid>
          <Grid item xs={8}>
            <BooleanInput label="bw.bwh.settings.bwh_childinprivat" source="bwh_childinprivat" helperText={translate('bw.bwh.settings.bwh_childinprivat_helpertext')} />
          </Grid>
          <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => formData && (formData.bwh_childindorm || formData.bwh_childinprivat)?
            (
            <React.Fragment>
            <Grid item xs={12} md={4}>
              <ReferenceInput source="bwh_childalterskat" reference="setAgecategory" sort={{ field: 'pos', order: 'ASC' }} filter={{nopos1:true}} {...rest} >
                <AutocompleteInput optionText="bezeichnung" label="bw.bwh.settings.bwh_childalterskat" helperText={translate('bw.bwh.settings.bwh_childalterskat_helpertext')} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={4}>
              <NumberInput label="bw.bwh.settings.bwh_child_min_age" source="bwh_childaltersspan[0]" inputProps={{ min: 1, max: 18}} />
            </Grid>
            <Grid item xs={4}>
              <NumberInput label="bw.bwh.settings.bwh_child_max_age" source="bwh_childaltersspan[1]" inputProps={{ min: 1, max: 18 }} />
            </Grid>
          {formData && (formData.bwh_childalterskat2 !== null)?
            (
            <React.Fragment>
            <Grid item xs={12} sx={{backgroundColor:'#f8f8f8', padding:'5px 0 0 2', fontWeight:'bold'}}>Zweite Kinder Alterskategorie</Grid>
            <Grid item xs={12} md={4} style={{backgroundColor:'#f8f8f8'}}>
              <ReferenceInput source="bwh_childalterskat2" reference="setAgecategory" allowEmpty={true} sort={{ field: 'pos', order: 'ASC' }} filter={{nopos1:true}} {...rest}>
                <AutocompleteInput optionText="bezeichnung" label="bw.bwh.settings.bwh_childalterskat" helperText={translate('bw.bwh.settings.bwh_childalterskat_helpertext')} />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} md={4} style={{backgroundColor:'#f8f8f8'}}>
              <NumberInput label="bw.bwh.settings.bwh_child_min_age" source="bwh_childaltersspan2[0]" inputProps={{ min: 1, max: 18}} />
            </Grid>
            <Grid item xs={4} style={{backgroundColor:'#f8f8f8'}}>
              <NumberInput label="bw.bwh.settings.bwh_child_max_age" source="bwh_childaltersspan2[1]" inputProps={{ min: 1, max: 18 }} />
            </Grid>
            </React.Fragment>)
            :null}
            </React.Fragment>)
            :null}
          </FormDataConsumer>
          <Grid item xs={12} md={4}>
            <SelectInput source="bwh_genderchoice" label="bw.bwh.settings.bwh_genderchoice" choices={genderChoices} /><br/>
          </Grid>
          <Grid item xs={12} md={4}>
            <BooleanInput label="bw.bwh.settings.bwh_hideprivatmarker" source="bwh_hideprivatmarker" helperText={translate('bw.bwh.settings.bwh_hideprivatmarker_helpertext')} />
          </Grid>
          <Grid item xs={12}>
            <NumberInput label="bw.bwh.settings.bwh_gelesenhacken" source="bwh_gelesenhacken" helperText={translate('bw.bwh.settings.bwh_gelesenhacken_helpertext')} inputProps={{ min: 0, max: 6 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectInput source="bwh_usearrivaltime" label="bw.bwh.settings.bwh_usearrivaltime" helperText={translate('bw.bwh.settings.bwh_usearrivaltime_helpertext')} choices={usearrivaltimeChoices} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput source="bwh_checkintime" label="bw.bwh.settings.bwh_checkintime" helperText="e.g. 16:30"  parse={timeParser} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput source="bwh_checkouttime" label="bw.bwh.settings.bwh_checkouttime" helperText="e.g. 10:00"  />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput source="bwh_gastfeldstorelan" reference="setGuestfield" sort={{ field: 'id', order: 'ASC' }} style={{width:'20em'}} {...rest} >
              <SelectInput optionText="text" label="bw.bwh.settings.bwh_gastfeldstorelan" helperText={translate('bw.bwh.settings.bwh_gastfeldstorelan_helpertext')} />
            </ReferenceInput>
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="bw.bwh.settings.konfig2"  size={1} >
        <Grid container style={{width:'100%'}}>
          { conditionTrue(record, "CVVenabled") && <Grid item xs={12} md={4}>
            <SelectInput label="bw.bwh.settings.cvv" source="CVV" choices={[
                { id: '0', name: 'bw.bwh.settings.noEntry' },
                { id: '1', name: 'bw.bwh.settings.optional' },
                { id: '2', name: 'bw.bwh.settings.required' },
              ]} />
          </Grid>}
          <Grid item xs={12} md={4}>
            <SelectInput label="bw.bwh.settings.prefAddGast" source="prefAddGast" choices={[
                { id: '0', name: 'bw.bwh.settings.update' },
                { id: '1', name: 'bw.bwh.settings.alwaysNew' },
              ]} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectArrayInput label="bw.bwh.settings.ccTypes" source="CCTypes" choices={[
                { id: 'V', name: 'bw.bwh.settings.visa' },
                { id: 'M', name: 'bw.bwh.settings.master' },
                { id: 'A', name: 'bw.bwh.settings.amex' },
                { id: 'D', name: 'bw.bwh.settings.diners' },
              ]} style={{minWidth:'20em'}} />
          </Grid>
          {isHauptadmin() && conditionTrue(record, "BookingFeeenabled") && <Grid item xs={12} md={4}>
            <SelectInput label="bw.bwh.settings.BookingFee" source="BookingFee" choices={[
              { id: '0', name: 'bw.bwh.settings.noFee' },
              { id: '1', name: 'bw.bwh.settings.worldPay' },
              { id: '2', name: 'bw.bwh.settings.stripe' },
            ]} />
          </Grid>}
          {isHauptadmin() && conditionTrue(record, "storePaymentInfoenabled") &&  <Grid item xs={12} md={4}>
            <SelectInput label="bw.bwh.settings.storePaymentInfo" source="storePaymentInfo" choices={[
              { id: '0', name: 'bw.bwh.settings.noneExternal' },
              { id: '1', name: 'bw.bwh.settings.local' },
            ]} />
          </Grid>}
        </Grid>
      </FormTab>
      <FormTab label="bw.bwh.settings.MultiRoom"  size={1} >
        <FormDataConsumer>
        {({ formData, dispatch, ...rest }) => formData && (formData.bwh_multiroomopt !== null)?
          <React.Fragment>
            <BooleanInput source="bwh_multiroomopt_1" label="bw.bwh.settings.SplitGroupInto2" helperText={translate('bw.bwh.settings.Split2Ex')} fullWidth />
            <BooleanInput source="bwh_multiroomopt_2" label="bw.bwh.settings.SplitGroupInto3" helperText={translate('bw.bwh.settings.Split3Ex')} fullWidth />
            <BooleanInput source="bwh_multiroomopt_3" label="bw.bwh.settings.SplitGroupIntoMoreThan3" helperText={translate('bw.bwh.settings.SplitMoreEx')} fullWidth />
            {isHauptadmin() && <NumberInput label="bw.bwh.settings.anzsingle" source="bwh_mr_anzsingle" helperText="Nur alixon !" />}
          </React.Fragment>
        :
          <p>{translate('bw.bwh.settings.disInfo1')}<br/><br/>{translate('bw.bwh.settings.disInfo2')} <a href="mailto:info@book.world">info@book.world</a>.</p>
        }
        </FormDataConsumer>
      </FormTab>

      <FormTab label="bw.bwh.settings.roomchange"  size={1} >
        <BooleanInput source="bwh_roomchange" label="bw.bwh.settings.roomchange2" fullWidth />
      </FormTab>

      <FormDataConsumer>
      {({ formData, dispatch, ...rest }) => formData && (formData.bwh_maplink !== null)?
        (<FormTab label="bw.bwh.settings.google"  size={1} {...rest}>
          <TextInput fullWidth source="bwh_maplink" label="bw.bwh.settings.bwh_maplink" />
          <TextInput fullWidth source="bwh_mapapikey" label="bw.bwh.settings.bwh_mapapikey" />
          <TextInput fullWidth source="bwh_analyticskey" label="bw.bwh.settings.bwh_analyticskey" />
        </FormTab>)
        :null}
      </FormDataConsumer>
    </TabbedForm>
  )
};

 const Settings = {
  list: null,
  edit: (props) => (
    <Edit title='bw.bwh.settings.titleEdit' mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}
export default Settings