import React, { useState, useEffect, useRef } from 'react';
import dataProvider from '../../lib/dataProvider';
import { getGlobalData} from '../../lib/globalData';
import { useTranslate } from 'react-admin';
import { apiPath } from '../../lib/isTest';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Bookingcom = (props) => {
  const tr = useTranslate();
  const [value, setValue] = useState(0);
  const [settings, setSettings] = useState(false);
  const formEl = useRef(null);
  const actionEl = useRef(null);
  const uplResEl = useRef(null);
  const uplPayEl = useRef(null);

  useEffect(() => {
    dataProvider.getList('repBookingcom/getAllSettings', { filter: { }, sort: {  }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        const data = {};
        res.data.forEach((el)=>{data[el.id]=el.value==="1"});
        setSettings(data);
      })
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  const action = (action) => (ev) => {
    
    if (action.substr(0,3) === "res" ) {
      if (uplResEl.current.files.length === 0) {
        alert(tr('bw.rep.bookingcom.noFile'));
        return;
      }
    }
    else {
      if (uplPayEl.current.files.length === 0) {
        alert(tr('bw.rep.bookingcom.noFile'));
        return;
      }
    }
    
    actionEl.current.value = action;
    formEl.current.submit();
  }

  if (settings === false) return null;
  
  return (         
    <Paper style={{scroll:'auto'}} >
      <form method="post" action={apiPath+"v1/repBookingcom"} target="_blank" ref={formEl} enctype="multipart/form-data" >
        <input type="hidden" name="token" value={getGlobalData('token')} />
        <input type="hidden" name="action" value="" ref={actionEl}/>
        <CardContent>
        <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={tr('bw.rep.bookingcom.resStatement')} />
            <Tab label={tr('bw.rep.bookingcom.payoutStatement')} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div><img src="/img/bc_statement.png" style={{width:'90%', maxWidth:'900px', display:'block'}} alt="statement" /></div>
          <div>{tr('bw.rep.bookingcom.resOptions')}</div>
          <div>
            <Checkbox defaultChecked={settings.bccheck_netto} name="bccheck_netto" value="1" />{tr('bw.rep.bookingcom.resCompareNetto')}
          </div>
          <div>
            <Checkbox defaultChecked={settings.bccheck_payments} name="bccheck_payments" value="1" />{tr('bw.rep.bookingcom.resComparePayment')}
          </div>
          <div style={{ color: "#ff0000"}}>
            {tr('bw.rep.bookingcom.WarnText')}
          </div>
          <div>
            {tr('bw.rep.bookingcom.UploadText')} 
            <input type="file" name="rescsv" ref={uplResEl} />
          </div>
          <Button variant="contained" onClick={action('resCheck')} style={{ marginRight: '10px'}} >{tr('bw.rep.bookingcom.StartCheck')}</Button>
          <Button variant="contained" onClick={action('resExcel')} >{tr('bw.rep.bookingcom.StartExcel')}</Button>
        </TabPanel>
        
        <TabPanel value={value} index={1}>
          <div><img src="/img/bc_payout.png" style={{width:'90%', maxWidth:'900px', display:'block'}} alt="payout" /></div>
          <div>
            <Checkbox defaultChecked={settings.bccheck_netto} name="bccheck_netto" value="1" />{tr('bw.rep.bookingcom.resCompareNetto')}
          </div>
          <div>
            <Checkbox defaultChecked={settings.bccheck_payments} name="bccheck_payments" value="1" />{tr('bw.rep.bookingcom.resComparePayment')}
          </div>
          <div style={{ color: "#ff0000"}}>
            {tr('bw.rep.bookingcom.WarnText')}
          </div>
          <div>
            {tr('bw.rep.bookingcom.UploadText')}
            <input type="file" name="paycsv" ref={uplPayEl} />
          </div>
          <Button variant="contained" onClick={action('payCheck')} style={{ marginRight: '10px'}} >{tr('bw.rep.bookingcom.StartCheck')}</Button>
          <Button variant="contained" onClick={action('payExcel')} >{tr('bw.rep.bookingcom.StartExcel')}</Button>
        </TabPanel>
        </CardContent>
      </form>
    </Paper>         
  )
}
export default Bookingcom;
