import React from 'react';
import { getGlobalData } from '../../lib/globalData';
import { useTranslate, useRecordContext, Edit, SimpleForm, PasswordInput, TextInput, BooleanInput } from 'react-admin';
import StayEditToolbar from '../../lib/StayEditToolbar';
import { apiPath } from '../../lib/isTest';

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const validatePwd = (values, allValues) => {
    if(allValues.passwort && allValues.passwort !== allValues.passwort2){
      return 'bw.set.pwdyubi.pwdErr';
    }
  }
  const validateYubico = async (values, allValues) => {
    return new Promise(async (resolve, reject) => {
      var token = getGlobalData('token');
      const res = await fetch(apiPath+'v1/setPwdyubi/verify', {
        method: 'PUT',
        body: JSON.stringify({ id: 1, values: allValues }),
        headers: {"Content-type": "application/json; charset=UTF-8", authorization: token}
      })
      const json = await res.json();

      if (json.ok) return resolve()
      resolve(json.errors)
    })
  }
  if(!record)return null;

  let yubiinfo = '';
  if(record.yubi)yubiinfo = translate('bw.set.pwdyubi.yubiinfo', record);

  return (
    <SimpleForm toolbar={<StayEditToolbar />} {...props}>
      <div>
        <h2>{translate('bw.set.pwdyubi.pwdtitle')+record.benutzername}</h2>
        <b>{translate('bw.set.pwdyubi.pwdinfo')}</b><br />
        <b>{translate('bw.set.pwdyubi.pwdinfo2')}</b><br />
      </div>
      <PasswordInput source="passwort" label="bw.set.pwdyubi.passwort" validate={validatePwd} />
      <PasswordInput source="passwort2" label="bw.set.pwdyubi.passwort2" validate={validatePwd} />
      <div >
        <h2>{translate('bw.set.pwdyubi.yubititle')}</h2>
        <b>{yubiinfo}</b><br />
      </div>
      <TextInput source="yubico" label="bw.set.pwdyubi.yubico" helperText="bw.set.pwdyubi.yubicohelp" validate={validateYubico} />
      <BooleanInput source="yubicodel" label="bw.set.pwdyubi.yubicodel" />
    </SimpleForm>
  )
};

const Pwdyubi =  {
  list: null,
  edit: ({ classes, ...props }) => (
    <Edit {...props} title="bw.set.pwdyubi.titleEdit" mutationMode="pessimistic">
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Pwdyubi
