import React from 'react';
import { getGlobalData } from './globalData';
import List from "@mui/material/List";

import NestableMenuList from './NestableMenuList';
import {MenuConext} from './MenuContext';

//https://materialdesignicons.com

function nestResources(resources, onMenuClick, dense, classes) {
  var menu = getGlobalData('menu');

  return (
    <div>
      <MenuConext.Consumer>
        {(ct) => {
          return (
            <NestableMenuList list={menu} menuct={ct} onMenuClick={onMenuClick} dense={dense} sub={false} />
          )}}
      </MenuConext.Consumer>
    </div>
  );
}

const MyMenu = ({
    dense,
    hasDashboard,
    onMenuClick,
    open,
    pathname,
    resources,
    translate,
    logout,
    ...rest
}) => (
    <div>
      <img src="/logo.svg" style={{width:"100%"}} alt="Logo" />
      <List {...rest}>
        {nestResources(resources, onMenuClick, dense)}            
      </List>
    </div>
);

/*          <Responsive xsmall={logout} medium={null} /> */

export default MyMenu;
