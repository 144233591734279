import React from 'react';
import { useTranslate, useListContext, List, Pagination, Button, Link } from 'react-admin';
import Backspace from '@mui/icons-material/Backspace';
import { parse } from 'query-string';
import Table from '@mui/material/Table';
const PostPagination = props => <div style={{marginTop:'1em',marginLeft:'1em'}}>
  <ReturnButton />
  <Pagination rowsPerPageOptions={[]} {...props} />
</div>

const ReturnButton = ({
  record, variant = 'contained',
  icon = <Backspace style={{marginRight: '0.5em', fontSize:'24px'}} />,
}) => (
  <Button component={Link} to={{pathname:'/calPricegr'}} label='ra.action.back' variant={variant} style={{float:'left', marginRight:'1em'}} >
    {icon}
  </Button>
);

const SimpleTable = ({ toolbar, ...props }) => {
  const { data, total } = useListContext();
  const params = window.location.hash.split('?');
  const { filter: filter_string } = parse(params[1]);
  const { id_preis_gr: col_ids } = JSON.parse(filter_string);
  
  const translate = useTranslate();
  if(!total) return null;
  const Cell = ({ type, textAlign, line, ...props}) => {
    switch(type) {
      case 'value': return (<td style={{ padding: '1px 0', borderTop:line?'1px solid #ccc':'', textAlign }}>{props.value}</td>)
      default: return null;
    }
  }


  const Tbl = (props) => {

    if(!total) return null;
    const data = props.data;
    var rows = [];
    var c = 0;
    var col_header = [];
    var headerText = false;
    for(c=0; c<col_ids.length; c++){
      headerText = data.pg_name[col_ids[c]];
      if(data.active[col_ids[c]] !== 1)headerText += '\n (Active=0)';
      col_header.push(<th style={{ maxWidth:'300px', minWidth:'200px' }} key={"th"+c}>{headerText}</th>);
    }
    var oldbez = '';
    for(var i=0; i<data.rooms.length; i++){
      const newbez = data.rooms[i].roomname !== oldbez;
      oldbez = data.rooms[i].roomname;
      var cols = [];
      for(c=0; c<col_ids.length; c++){
        if(data.rooms[i].compare[col_ids[c]]){
          cols.push(<Cell type="value" value={data.rooms[i].compare[col_ids[c]]} line={newbez} key={i+"c3"+c} />);
        }
        else {
          cols.push(<td style={{borderTop:newbez?'1px solid #ccc':''}} key={i+"c3"+c} ></td>);
        }
      }
      rows.push(
        <tr key={i}>
          <Cell type="value" value={data.rooms[i].roomname} line={newbez} key={i+"c1"} />
          <Cell type="value" value={data.rooms[i].ratename} line={newbez} key={i+"c2"} />
          {cols}
        </tr>
      )
    }

    return (
      <div>
        <Table sx={{width:'100%', borderCollapse:'collapse', mt:'1em', padding:0, "& td":{padding:0}}} >
          <tbody>
            <tr align="left" >
              <th style={{ width:'300px' }}>{data.agent_name}</th>
              <th style={{ width:'150px' }}>{translate('bw.cal.pricegrcomparerate.rate')}</th>
              {col_header}
            </tr>
            {rows}
          </tbody>
        </Table>
      </div>
    )
  }

  var channels = [];
  for(var i=0; i<data.length; i++) {
    if(data[i]){
      channels.push(
        <tr key={i}>
          <Tbl data={data[i]} idx={i} key={i+"t"} />
        </tr>
      )
    }
  }
  return (
    <Table style={{width:'auto', borderCollapse:'collapse', margin:'1em'}} >
      <tbody>
        {channels}
      </tbody>
    </Table>
  )
}

const ListForm = ({ ...props}) => {
  return (
    <List {...props} sort={{ field: 'bezeichnung', order: 'ASC' }} pagination={<PostPagination />} perPage={1000} bulkActionButtons={false} >
      <SimpleTable {...props} />
    </List>
  )
};

const PriceCompareRate = {
  list: ({ ...props }) => (
    <ListForm {...props} />
  ),
}
export default PriceCompareRate