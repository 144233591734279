import * as React from 'react';
import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import isTest from './isTest';
import LogoBW from '../img/logo.svg';

import {
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import {
    Form,
    required,
    TextInput,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import Box from '@mui/material/Box';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [checkres, setCheckres] = useState({status:'invalid'});
    const [lastValid, setLastValid] = useState('');
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    var get = {};
    if (!clicked) {
      var parts = window.location.search.substr(1).split("&");
      for (var i = 0; i < parts.length; i++) {
        var temp = parts[i].split("=");
        get[decodeURIComponent(temp[0])] = decodeURIComponent(temp[1]);
      }
    }

    const validate = values => {
        const errors = {};
                      
        if (values.token) return {}
        
        if (!values.username && !values.token) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password && !values.token) {
            errors.password = translate('ra.validation.required');
        }
        if (values.username && lastValid!==(values.username+values.password)) {
          setLastValid(values.username+values.password);
          dataProvider.update('authCheckuser', { id:0, data: {username: values.username, password: values.password}})
            .then(res  => {
               setCheckres(res.data.status);
            })
        }
        return errors;
    };
    
    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        setClicked(true);
        login(
            auth,
            location.state ? (location.state as any).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        });
    };

    return (
        <Form onSubmit={handleSubmit} validate={validate}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: isTest?'#D02628':'#002D7A',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={LogoBW} alt="Logo book.world" />
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                    </Box>
            {!get.token && !loading &&
                    <Box sx={{ padding: '0 1em 0 1em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                source="username"
                                label={translate('ra.auth.username')}
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ marginTop: '0.2em' }}>
                            <TextInput
                                source="password"
                                label={translate('ra.auth.password')}
                                type="password"
                                disabled={loading}
                                validate={required()}
                                fullWidth
                            />
                        </Box>
                    </Box>
            }
                    <Box sx={{ padding: '0 1em 1em 1em', display:loading || checkres!=='multi'?'none':'block' }} >
                        <TextInput
                            source="token"
                            sx={{display:'none'}}
                            defaultValue={get.token}
                        />
                        <Box>
                            <TextInput
                                source="id_hostel"
                                label="Property"
                                fullWidth
                            />

                        </Box>
                    </Box>
                    <CardActions sx={{ padding: '0 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
    email?: string;
    password?: string;
}

