import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const DataGrid = ({rows, columns, edit, remove}) => {
  const handleEdit = (id) => (ev) => {
    edit(id);
  }
  const handleRemove = (id) => (ev) => {
    remove(id);
  }
  return (
    <Table size="small" >
      <TableHead>
        <TableRow>
        {columns.map((col, k) => (
          <TableCell key={k} >{col.headerName}</TableCell>
        ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, k1) => (
          <TableRow key={k1} hover={true} >
          {columns.map((col, k2) => (
            <TableCell component="th" key={k2} scope="row" style={row.mark>0 && col.mark ?{background:col.mark[row.mark-1]}:{}}>
              {row[col.field]}
            </TableCell>
        ))}
        {edit && <TableCell><EditIcon color="primary" onClick={handleEdit(row.id)} style={{cursor:'pointer'}} /></TableCell>}
        {remove && <TableCell><DeleteIcon color="primary" onClick={handleRemove(row.id)} style={{cursor:'pointer'}} /></TableCell>}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )    
};

export default DataGrid;
