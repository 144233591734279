import React, { useState } from 'react';
import { getGlobalData } from '../../lib/globalData';
import { isHauptadmin } from '../../lib/globalData';
import { useTranslate, useRecordContext, Labeled, List, Filter, Edit, Datagrid, SimpleForm, 
  TextField, BooleanField, SelectField, FileField, 
  EditButton, TextInput, BooleanInput, SelectInput, FileInput, 
}  from 'react-admin';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';

import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import TextFieldMat from '@mui/material/TextField';
import EmbedIcon from '@mui/icons-material/InsertLink';
import IconCancel from '@mui/icons-material/Cancel';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const pricenocommChoices = [
  { id: 1, name: 'bw.bwh.agent.NetPrice' },
  { id: 2, name: 'bw.bwh.agent.NetPriceFee' },
  { id: 3, name: 'bw.bwh.agent.NetPriceService' },
  { id: 0, name: 'bw.bwh.agent.GrossPrice' },
];
const iframeresizeChoices = [
  { id: 0, name: 'bw.bwh.agent.NoResizer' },
  { id: 2, name: 'V4.1.1' },
  { id: 1, name: 'V3.6.5' },
];

const CSSLinkField = (el) => {
  const record = useRecordContext();
  return (
    <Labeled label="bw.bwh.agent.css">
      <a href={record.css_url} target="_blank" rel="noopener noreferrer">{record.css_file}</a>
    </Labeled>
  )
}

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const translate = useTranslate();
  
  const Redirs = (el) => {
    const record = useRecordContext();
    var r = [];
    var f;
    for(f in record) {
      if (f.substr(0,6)==='redir_') {
        r.push(<TextInput source={f} label={translate("bw.bwh.agent.redirect")+" "+f.substr(6)} key={f} fullWidth />)
      }
    }
    return r;
  }
  return (
    <SimpleForm toolbar={<CloseToolbar noDelete />} {...props}> 
      <BooleanInput source="avpreview" label="bw.bwh.agent.avPreview" />
      <BooleanInput source="showavgprice" label="bw.bwh.agent.showavgprice" />
      <BooleanInput source="showtotservice" label="bw.bwh.agent.showtotservice" />
      <SelectInput source="pricenocomm" choices={pricenocommChoices} label="bw.bwh.agent.PriceNoComm" />
      <SelectInput source="iframeresize" choices={iframeresizeChoices} label="bw.bwh.agent.resize" />
      <CSSLinkField source="css_file" label="bw.bwh.agent.css" style={{width:"100px"}} />
      <FileInput source="file" label="bw.bwh.agent.cssupload">
        <FileField source="css" title="css" />
      </FileInput>
      <BooleanInput source="delcss" label="bw.bwh.agent.cssremove" />
      <Redirs />
    </SimpleForm>
  )
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput source="q" alwaysOn />
        {isHauptadmin() && <BooleanInput source="admin" defaultValue={false} alwaysOn />}
    </Filter>
);

const ListForm = (props) =>{
  const [dispEmbed, setDispEmbed] = useState(false);
  const [int, setInt] = useState('link');
  const [book, setBook] = useState(false);
  const [noheadimg, setNoheadimg] = useState(false);
  const [lang, setLang] = useState('en');
  const [width, setWidth] = useState(100);
  const [unit, setUnit] = useState('%');
  const [rec, setRec] = useState({});
  const [copied, setCopied] = useState({});

  const calcCode = () => {
    if (!rec.bwhdomain) {
      return;
    }
    var url = 'https://'+rec.bwhdomain
    if (rec['redir_'+lang]) {
      url = rec['redir_'+lang];
    }
    url += '?lang=' + lang;
    url += '&weiter=1&agent='+rec.code;
    if (book) {
      url += '&book='+rec.book;
    }
    if (noheadimg) {
      url += '&noheadimg=1';
    }
    var code = url;
    if (int==='iFrame') {
      code = '<div id="bwoWrap"></div>'+
        '<script>var ifr=document.createElement("iframe");ifr.id = "bwoIframe";ifr.style="border:none;";ifr.scrolling="no";ifr.marginheight="0px";ifr.marginwidth="0px";'+
        'ifr.width="'+width+unit+'";ifr.allowfullscreen=true;'+
        'ifr.src="'+url+'";document.getElementById("bwoWrap").appendChild(ifr);';
      if (rec.iframeresize) {
        code = '<script src="https://'+rec.bwhdomain+'/react/js/resizer_'+
          iframeresizeChoices.reduce((res, v)=>{return rec.iframeresize===v.id?v.name:res+''}, '')+'.js"></script>'+code;
        code = code+ 'iFrameResize({checkOrigin:false},"#bwoIframe")';
      }
      code = code+ '</script>';
    }
    else if (int==='widget') {
      const ident = rec.bwhdomain.split('.',2)[0];
      code = url.replace('?lang', '/bwh_widget.php?hostel='+ident+'&lang');
      code = '<script src="'+code+'" type="text/javascript"></script>'; 
    }
    return code;
  }
  const clickEmbedd = (rec) => () => {
    setRec(rec);
    setDispEmbed(true);
  }
  const Embed = () => {
    const record = useRecordContext();
    return (<Button onClick={clickEmbedd(record)} style={{color:'#3f51b5'}}>{<EmbedIcon />}</Button>);
  } 
  
  const code = calcCode();

  const translate = useTranslate();
  const info = getGlobalData('info');
  const bwh_sprachen = info.bwh_sprachen;
    
  return (
    <React.Fragment>
      <List {...props} title="bw.bwh.agent.title" sort={{ field: 'agent', order: 'ASC' }} 
        filters={<ListFilter />} pagination={<NoPagination />} perPage={1000} >
          <Datagrid style={{ width: 'auto' }} bulkActionButtons={false} >
            <TextField source="agent" />
            <TextField source="code" />
            <BooleanField source="avpreview" label="bw.bwh.agent.avPreviewK"  sortable={false} />
            <BooleanField source="showavgprice" label="bw.bwh.agent.showavgpriceK"  sortable={false} />
            <SelectField source="pricenocomm" choices={pricenocommChoices} label="bw.bwh.agent.PriceNoComm"  sortable={false} />
            <SelectField source="iframeresize" choices={iframeresizeChoices} label="bw.bwh.agent.resizeK"  sortable={false} />
            <BooleanField source="has_css" label="bw.bwh.agent.cssK" sortable={false}/>
            <BooleanField source="has_url" label="bw.bwh.agent.redirectK"  sortable={false}/>
      {isHauptadmin() &&
              <TextField source="id_agent" align="right" />
      }
            <EditButton label="" />
            <Embed/>
          </Datagrid>
      </List>
      <Dialog fullWidth open={dispEmbed} onClose={()=>setDispEmbed(false)} aria-label="Embed">
        <DialogActions style={{position:'absolute', right:0}}>
          <Button label="ra.action.cancel" onClick={()=>setDispEmbed(false)}><IconCancel /></Button>
        </DialogActions>
        <DialogTitle>{translate('bw.bwh.agent.embedtitle')}</DialogTitle>
        <DialogContent>
          <Select value={int} onChange={(e)=>setInt(e.target.value)} native={true} fullWidth>
            <option value="link">{translate('bw.bwh.agent.intLink')}</option>
            <option value="widget">{translate('bw.bwh.agent.intWidget')}</option>
            <option value="iFrame">{translate('bw.bwh.agent.intIFrame')}</option>
          </Select> 
        {/* !Agent_DormOnline,Agent_HomePage,Agent_BookWorldHostels,Agent_RegionPage */}
        {(int!=='widget' && rec && [3,22,23,24].indexOf(rec.id_agent)===-1) && 
          <FormControlLabel control={<Checkbox checked={book} onChange={(e)=>setBook(e.target.checked)} color="primary" />} 
            label={translate('bw.bwh.agent.book')}  style={{width:'100%'}} />
        }
          <Select value={lang} label={translate('bw.bwh.agent.language')} onChange={(e)=>setLang(e.target.value)} style={{marginRight:'2em'}} native={true}  >
          {bwh_sprachen.map((sp)=>(<option value={sp} key={sp}>{sp}</option>))}
          </Select> 
          <FormControlLabel control={<Checkbox checked={noheadimg} onChange={(e)=>setNoheadimg(e.target.checked)} color="primary" />} 
            label={translate('bw.bwh.agent.noheadimg')} />
        {int==='iFrame' &&
          <TextFieldMat label="Width" defaultValue="100" margin="normal" style={{width:"5em"}}  onChange={(e)=>setWidth(e.target.value)} />
        }
        {int==='iFrame' &&
          <Select native={true} onChange={(e)=>setUnit(e.target.value)}>
            <option value="%">%</option>
            <option value="px">px</option>
          </Select> 
        }
          <p>{translate('bw.bwh.agent.'+(int==='link'?'link':'embed'))}</p>
          <div style={{width: '100%', padding: '6px', border: 'solid 1px #000', overflow:'auto'}} >{code}</div>
          <CopyToClipboard text={code} onCopy={(t,r) => {setCopied(true)}}>
            <Button color={copied?"primary":'inherit'} variant="contained">Copy to clipboard</Button></CopyToClipboard>
        </DialogContent>
      </Dialog>
    </React.Fragment>
                      
  )
}
const Agent = {
  list: (props) => (
    <ListForm {...props} />
  ),
  edit: (props) => (
    <Edit title="bw.set.specialday.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
export default Agent 