import React, { useState } from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useDataProvider, useRecordContext, 
  ListBase, Create, Edit, SimpleForm, FilterForm, Pagination, 
  TextField, SelectField, ReferenceField, CreateButton, 
  TextInput, SelectInput, ReferenceInput, BooleanInput } from 'react-admin';
import BulkActionButtons from '../../lib/BulkActionButtons';
import ConditionalEditButton from '../../fields/ConditionalEditButton';
import { PwdChangeInput } from '../../fields/PwdChangeInput';
import CloseToolbar from '../../lib/CloseToolbar';
import { ActivateUserButton } from '../../lib/set/ActivateUser';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import DatagridConfigurable from '../../lib/DatagridConfigurable.tsx';
import { SelectColumnsButton } from '../../lib/SelectColumnsButton.tsx';

const statusChoices = [
  { id: 0,    name: 'bw.set.dbuser.active' },
  { id: 6,    name: 'bw.set.dbuser.locked' },
  { id: 50,   name: 'bw.set.dbuser.unused' },
  { id: 127,  name: 'bw.set.dbuser.inactive' },
]

const userFilter = [
  <TextInput source="q" alwaysOn />,
  <BooleanInput source="active" alwaysOn />
];

const ListToolbar = () => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FilterForm filters={userFilter} />
          <Stack alignItems="flex-start">
              <SelectColumnsButton />
          </Stack>
          <Stack alignItems="flex-start">
              <CreateButton />
          </Stack>
      </Stack>
    </>
  )
}

const ListForm = (props) => {
  return (
    <ListBase {...props} title="bw.set.dbuser.title" sort={{ field: 'loginname', order: 'ASC' }} perPage={25} filterDefaultValues={{active:true}} >
      <ListToolbar />
      <DatagridConfigurable style={{ width: 'auto' }} omit={['yubi','hid']} bulkActionButtons={<BulkActionButtons />} >
        <TextField source="loginname" />
        <TextField source="name" />
        <TextField source="email" />
        <ReferenceField source="id_db_loginlevel" reference="setLoginlevel" link={false} >
          <TextField source="bez_lang" />
        </ReferenceField>
        <ReferenceField source="id_fw_gruppe" reference="globUsergroup" emptyText="" link={false} >
          <TextField source="bezeichnung" emptyText="" />
        </ReferenceField>
        <SelectField source="status" choices={statusChoices} />
        <TextField source="yubi" />
        <TextField source="hid" />
        {isHauptadmin() && <TextField source="inactive" /> }
        {isHauptadmin() && <TextField source="last_change" /> }
        {isHauptadmin() && <TextField source="id" textAlign="right" /> }
        <ConditionalEditButton source="edit_en" label="" />
        <ActivateUserButton source="edit_en" label="glob.activate" />
      </DatagridConfigurable>
      <Pagination />
    </ListBase>
  )
}



const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [showReactivate, setShowReactivate] = useState(false);
  const [rName, setName] = useState(false);

  const asyncValidate = async (values) => {
    var errors = {};
    if (!values.loginname) {
      errors.loginname = 'bw.set.dbuser.error.required';
    }
    if (!values.id_db_loginlevel) {
      errors.id_db_loginlevel = 'bw.set.dbuser.error.required';
    }
    
    const res = await dataProvider.update('setDbuser/verify', {id: record?record.id:0, data:{ values: values }});
    const json = res.data;
    errors = {...json.errors, ...errors};

    if (json.ok && Object.keys(errors).length===0) return errors;

    if(json && json.errors && json.errors.doubleid){
      setShowReactivate(json.errors.doubleid);
    }
    else {
      setShowReactivate(false);
    }
    if(json && json.errors && json.errors.dname){
      setName(json.errors.dname);
    }

    return errors
  }

  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props} validate={asyncValidate} mode="onBlur" >
      <Grid container style={{width:'100%'}}>
        <Grid item xs={12} md={4}>
          <TextInput source="loginname" label="bw.set.dbuser.loginname" />
        </Grid>
        {showReactivate &&
          <Grid item xs={12} md={12}>
            <ActivateUserButton {...props} record={{id: showReactivate, loginname:rName}} />
          </Grid>}
        {!showReactivate &&
        <React.Fragment>
          <Grid item xs={12} md={4}>
            <TextInput source="name" label="bw.set.dbuser.name" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput source="email" label="bw.set.dbuser.email" />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferenceInput source="id_db_loginlevel" reference="setLoginlevel" sort={{ field: 'pos', order: 'ASC' }} perPage={1000}>
              <SelectInput optionText="bez_lang" label="bw.set.dbuser.id_db_loginlevel" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={8}>
            <ReferenceInput source="id_fw_gruppe" reference="globUsergroup" filter={{dbuser: true, res: props.resource}} sort={{ field: 'pos', order: 'ASC' }} emptyText="bw.set.dbuser.noAccess" perPage={1000}>
              <SelectInput optionText="bezeichnung" label="bw.set.dbuser.id_fw_gruppe" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} md={12}>
          { (!record || !record.pwd) && <PwdChangeInput source="newpwd" label="bw.set.dbuser.newpwd" />}
          { record && record.pwd && <ActivateUserButton {...props} label="bw.set.dbuser.newpwd" showbutton={true} />}
          </Grid>
          <Grid item xs={12} md={12}>
            <TextInput source="yubico" label="bw.set.dbuser.yubico" helperText="bw.set.dbuser.yubicohelper" />
          </Grid>
          </React.Fragment>
        }
      </Grid>
    </SimpleForm>
  )
};

const Dbuser =  {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

  create: ({ classes, ...props }) => (
    <Create {...props} title="bw.set.dbuser.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit {...props} title="bw.set.dbuser.titleEdit" mutationMode="pessimistic">
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default Dbuser
