import React from 'react';
import { setGlobalData, getGlobalData } from './globalData';
import MenuItem from '@mui/material/MenuItem';
import SwapHoriz from '@mui/icons-material/SwapHoriz';

import dataProvider from './dataProvider';

class SwitchLang extends React.Component {

  doSwitch = data => {
    const info = getGlobalData('info');
    dataProvider.update('authSwitch', { id: info.id_sprache===1?2:1 , data: {action: 'switchlang' }})
      .then(res  => {
        setGlobalData({info: {...info, ...res.data.info}});
        setGlobalData({menu: res.data.menu});
        localStorage.setItem('info', JSON.stringify({...info, ...res.data.info}));
        localStorage.setItem('menu', JSON.stringify(res.data.menu));
        window.location.href = '/';
      })
      .catch(response => {
        window.location.hash = '/login';
      });
  };

  render() {
    const info = getGlobalData('info');
    return (
      <MenuItem onClick={this.doSwitch}>
        <SwapHoriz style={{paddingRight:"1rem"}} />
        {info.id_sprache===1?"English":"Deutsch"}
      </MenuItem>
    );
  }
}
export default (SwitchLang);