const PadZero = (v) => {
  return ('00'+v).slice(-2);
}

const FormatTimeArrival = (v, u, tr) => {
  var t = '';
  if (!v || v===-1) return t;
  if (u!=='d') {
    v = v / 24;
  }
  const w = Math.floor(v / 7);
  const d = Math.floor(v) % 7;
  var h = (1 - v%1) * 24;
  if (w) {
    t = t + w +' ' + tr('bw.cal.oportal.'+(w===1?'woche':'wochen'))+' ';
  }
  if (d) {
    t = t + d +' ' + tr('bw.cal.oportal.'+(d===1?'tag':'tage'))+' ';
  }
  if (d===0 && w===0) {
    t = t + tr('bw.cal.oportal.ankunftstag')+' ';
  }
  if (h) {
    h = Math.round(h*10000)/10000;
    t = t + '@ '+ PadZero(Math.floor(h)) + ':' + PadZero(Math.round(h*60%60));
  }
  
  return t;
}

export default FormatTimeArrival;