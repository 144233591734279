import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
  
const NoDeleteToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export default NoDeleteToolbar;
