import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

const ColorField = ({ source, label }) => {
  const record = useRecordContext();
  if (!record[source]) {
    return null;
  }
  if(!record.dispFarbe)record.dispFarbe = '#FFFFFF';
  var f = record.dispFarbe;
  var hell = 0.299*parseInt(f.substr(1,2),16)+0.587*parseInt(f.substr(3,2),16)+0.114*parseInt(f.substr(5,2),16);
  var color = hell < 127?'#FFFFFF':'#000000';
  
  return (<span style={{backgroundColor:f,color:color,padding:'4px 8px',borderRadius:'4px',display:'inline-block',width:'100%'}}>{record[source]}</span>)
} 

ColorField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ColorField;