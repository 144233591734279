import { useRecordContext, useTranslate } from 'react-admin';

const GenderField = ({ source, label }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const gender = {
    0: translate('glob.gender.woman'), 
    1: translate('glob.gender.man'), 
    2: translate('glob.gender.mixed'), 
    3: translate('glob.gender.empty')
  };

  var ret = gender[record[source]];
  return ret?ret:null;

};
export default GenderField;