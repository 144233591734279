import React from 'react';
import { isHauptadmin } from '../../lib/globalData';
import { useRecordContext, usePermissions, List, Create, Edit, Datagrid, SimpleForm, 
        Filter, BooleanInput, TextInput, TextField, 
        BulkDeleteButton, EditButton, Button } from 'react-admin';
import { getRecht } from '../../lib/globalData';
import { Link } from 'react-router-dom';
import { ColorInput } from 'react-admin-color-picker';
import { stringify } from 'query-string';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import CompareArrows from '@mui/icons-material/CompareArrows';
import CloseToolbar from '../../lib/CloseToolbar';
import NoPagination from '../../lib/NoPagination';
import OnlineCopy from '../../lib/cal/OnlineCopy';
import ColorField from '../../fields/ColorField';


const PostBulkActionButtons = props => (
    <React.Fragment>
        <CompareButton {...props} />
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} mutationMode="pessimistic"/>
    </React.Fragment>
);

const PortalEditButton = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: '/calOportal',
        search: stringify({
          page: 1,
          perPage: 1000,
          sort: 'strasse',
          order: 'ASC',
          filter: JSON.stringify({ id_online_gr: record?record.id:0 }),
        }),
      }}
      label=''
    >
      <EditIcon />
    </Button>
  );
}
const CompareButton = ({ selectedIds, ...rest }) => {
  return (
    <Button
      variant="contained"
      size="small"
      component={Link}
      to={{
        pathname: '/calOnlinecompare',
        search: stringify({
          page: 1,
          perPage: 1000,
          sort: 'strasse',
          order: 'ASC',
          filter: JSON.stringify({ id: selectedIds }),
        }),
      }}
      label='bw.cal.online.compare'
      title='bw.cal.online.compare'
    >
      <CompareArrows />
    </Button>
  )
};

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextInput source="bezeichnung" />
      <ColorInput source="farbe" disableAlpha={true} />
    </SimpleForm>
  )
};
/*
const useFilterStyles = makeStyles(theme => ({
  bool: {
    marginBottom: theme.spacing(1),
  },
}));
*/
const ListFilter = (props) => {
  const { permissions } = usePermissions();
  return (
    <Filter {...props}>
      <TextInput source="q" alwaysOn />
      {getRecht(permissions, 'calOnline')>=9 && <BooleanInput source="showall" label="bw.cal.online.showall" alwaysOn /> }
    </Filter>
  )
}

const online = {
  list: ({ ...props }) => (
    <List {...props} title='bw.cal.online.title' sort={{ field: 'bezeichnung', order: 'ASC' }} filters={<ListFilter {...props} />} pagination={<NoPagination />} perPage={1000} bulkActionButtons={<PostBulkActionButtons />} >
      <Datagrid style={{ width: 'auto' }}>
        <ColorField source="bezeichnung" label="bw.cal.online.onlinegroup"/>
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <PortalEditButton />
        <EditButton label="" icon={<SettingsIcon />} />
        <OnlineCopy />
      </Datagrid>
    </List>
  ),
  
  create: ({ ...props }) => (
    <Create {...props} title='bw.cal.online.titleCreate' redirect="list">
      <CEForm {...props} />
    </Create>
  ),
  
  edit: ({ ...props }) => (
    <Edit title='bw.cal.online.titleEdit' mutationMode="pessimistic" {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   

export default online;