import React from 'react';
import { useRecordContext, TextInput, NumberInput, SelectInput } from 'react-admin';

const ConditionalTextInput = ({ dispSource, dispLabel, condition, ...rest }) => {
  const record = useRecordContext();
  return record && record[condition] ? <TextInput {...rest} source={dispSource} label={dispLabel} /> :null;
};

const ConditionalNumberInput = ({ dispSource, dispLabel, condition, save, saving, undoable, ...rest }) => {
  const record = useRecordContext();
  return record && record[condition] ? <NumberInput {...rest} source={dispSource} label={dispLabel} /> :null;
};

const ConditionalSelectInput = ({ condition, save, saving, undoable, ...rest }) => {
  const record = useRecordContext();
  return record && record[condition] ? <SelectInput {...rest} /> :null;
};

export { ConditionalTextInput, ConditionalNumberInput, ConditionalSelectInput }; // decorate with redux-form's <Field>