import React, { Fragment, useState } from 'react';
import { useTranslate, Button,
        SimpleForm, useRefresh, useNotify, ReferenceInput, SelectInput } from 'react-admin';
import PlusBoxMultipleIcon from 'mdi-react/PlusBoxMultipleIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import dataProvider from '../dataProvider';

const OportalMultiedit = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  
  const [showDialog, setShowDialog] = useState(false);
  if(!props || !props.filterValues || !props.filterValues.id_online_gr)return null;
  const src_id = props.filterValues.id_online_gr;

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const doSave = (data) => {
    data.ids = props.selectedIds;
    data.copygr = true;
    dataProvider.update('calOportal', { id: src_id, data:data })
      .then(({ data }) => {
        setShowDialog(false);
        refresh();
      })
      .catch(error => {
        setShowDialog(false);
        notify(error.message, 'error');
      })
  };

  return (
    <Fragment>
      <Button onClick={handleClick} label="bw.cal.oportal.multicopy">
        <PlusBoxMultipleIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="bw.cal.oportal.multicopy" >
        <DialogTitle>{translate("bw.cal.oportal.multicopy")}</DialogTitle>
        <DialogContent>
          <SimpleForm resource="calOportal" onSubmit={doSave} >
            <Fragment>
              <div>{translate('bw.cal.oportal.copyinfo3')}</div>
            </Fragment>
            <ReferenceInput source="id_online_gr" reference="calOnline" allowEmpty={false} filter={{ _id: src_id }} alwaysOn >
              <SelectInput optionText="bezeichnung" label='bw.cal.oportal.id_online_gr' />
            </ReferenceInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default OportalMultiedit;
