import React from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

const HtmlField = ({ source, label }) => {
  const record = useRecordContext();
  if (!record[source]) {
    return null;
  }
  return (<div dangerouslySetInnerHTML={{ __html: record[source] }} />)
};

HtmlField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default HtmlField; // decorate with redux-form's <Field>