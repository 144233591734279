import React, { useState, Fragment } from 'react';
import dataProvider from '../../lib/dataProvider';
import {useTranslate} from 'react-admin';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const RepGdpr = (props) => {
  const translate = useTranslate();
  const [search, setSearch] = useState('');
  const [eraseref, setEraseref] = useState('');
  const [mailtext, setMailtext] = useState('');
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(0);
  const [loading, setLoading] = useState(false);

  const searchchange = (event) => {
    setSearch(event.target.value);
    setEraseref('');
  };

  const eraserefchange = (event) => {
    setEraseref(event.target.value);
  };

  const clickSearch = () => {
    if (!search) return;
    setLoading(true);

    dataProvider.getList('repGdpr', {
        id: 0,
        filter: { search: search},
        sort: { field: 'id_hostel', order: 'ASC' },
        pagination: { page: 1, perPage: 50 },
    })
      .then(response => {
        if (response) {
          const resp = response.data[0];
          if (resp.list.length) {
            setList(resp.list);
          }
          if (resp.mailtext) {
            setMailtext(mt => mt + resp.mailtext + "\n##################\n");
          }
        }
        setLoading(false);
      });
  }
  
  const clickErase = (gastid) => () => {
    if (!gastid) return;
    setLoading(true);

    dataProvider.update('repGdpr', {
        id: gastid,
        data: {eraseref: eraseref}
    })
      .then(response => {
        if (response.data) {
          setMailtext(mt => mt + response.data.mailtext + "\n##################\n");
          setEraseref(response.data.erasereqref);
        }
        setLoading(false);
        clickSearch();
      });
  }
  
  const clickClearMailTxt = () => {
    setMailtext('');
  }
  
  const clickOpen = ({idx}) => () => {
    setOpen(idx);
  }

  const tr = (id) => {
    return translate('bw.rep.gdpr.'+id);
  }
  return (         
    <Paper style={{scroll:'auto'}} >
      <CardContent>
      <div>
        <TextField id="standard-basic" label={tr('searchtitle')} value={search} onChange={searchchange} style={{minWidth: '30%'}}/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Button variant="contained" color="primary" onClick={clickSearch} disabled={loading}>{tr('searchbutton')}</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
{mailtext?
        <Button variant="contained" color="primary" onClick={clickClearMailTxt} disabled={loading}>{tr('clearmailtxtbutton')}</Button>
        :null
}
      </div>
{mailtext?
      <pre style={{overflowX: 'auto', wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>{mailtext}</pre>
      :null
}
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
{list && list.map((row, idx) => (
            <Fragment>
              <TableRow>
                <TableCell>
                  <IconButton aria-label="expand row" size="small" onClick={clickOpen({idx})}>
                    {open===idx ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell align="left">{row.hostel.hostel_id} / {row.hostel.login_name}</TableCell>
                <TableCell align="left">{row.name} {row.vorname} ({row.country} / {row.nationality})</TableCell>
                <TableCell align="left">id: {row.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open===idx} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <span >
                        <font style={{fontWeight: 'bold'}}>{tr('guestremarktitle')}</font> 
                        {row.bemerkungen}
                        <br />
                        <br />
                      </span>
                      <Table size="small" aria-label="purchases" style={{width: 'auto'}}>
                        <TableBody>
          {row.gastdetail.map((detailsRow) => (
                            <TableRow key={detailsRow.date}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {detailsRow.titel === 'General'?tr('General'):detailsRow.titel}
                              </TableCell>
            {detailsRow.fields.map((detailsField) => (
                              <TableCell>{detailsField.name}: {detailsField.val}</TableCell>
            ))}
                            </TableRow>
          ))}
                        </TableBody>
                      </Table>
                      <Typography variant="h6" gutterBottom component="div" style={{marginTop: '1em'}}>
                        {tr('reservations')}
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>{tr('resno')}</TableCell>
                            <TableCell>{tr('status')}</TableCell>
                            <TableCell>{tr('arrival')}</TableCell>
                            <TableCell>{tr('departure')}</TableCell>
                            <TableCell>{tr('remark')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
          {row.reslist.map((resRow) => (
                            <TableRow key={resRow.reservation_id}>
                              <TableCell style={{fontWeight: 'bold'}}>
                                {resRow.id_hostel}-{resRow.res_nr}
                              </TableCell>
                              <TableCell>{translate('glob.resstatus.stat'+resRow.status)}</TableCell>
                              <TableCell>{resRow.ankunft}</TableCell>
                              <TableCell>{resRow.abreise}</TableCell>
                              <TableCell>{resRow.bemerkung}</TableCell>
                            </TableRow>
          ))}
                        </TableBody>
                      </Table>
                    </Box>
                    <br />
                    <div>
                      <TextField id="standard-basic" label={tr('erasereference')} value={eraseref} onChange={eraserefchange} style={{width: '30%'}}/>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button variant="contained" color="primary" onClick={clickErase(row.id)} disabled={loading}>{tr('erasebutton')}</Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {row.hostel.hostel_id} {row.hostel.login_name} / id: {row.id}                      
                    </div>
                    <br />
                    <br />
                  </Collapse>
                </TableCell>
              </TableRow>      
            </Fragment>
))}          
          </TableBody>
        </Table>
      </TableContainer>
      </CardContent>
    </Paper>         
  )
}

export default RepGdpr;

/*
                          {row.history.map((historyRow) => (
                            <TableRow key={historyRow.date}>
                              <TableCell component="th" scope="row">
                                {historyRow.date}
                              </TableCell>
                              <TableCell>{historyRow.customerId}</TableCell>
                              <TableCell align="right">{historyRow.amount}</TableCell>
                              <TableCell align="right">
                                {Math.round(historyRow.amount * row.price * 100) / 100}
                              </TableCell>
                            </TableRow>
                          ))}

*/