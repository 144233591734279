import React, { useState, useEffect} from 'react';
import dataProvider from '../../lib/dataProvider';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { getInfoValue, isHauptadmin, isReseller  } from '../../lib/globalData';
import DataGrid from '../../lib/DataGrid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandAllIcon from 'mdi-react/ChevronDoubleDownIcon';
import ReduceAllIcon from 'mdi-react/ChevronDoubleUpIcon';

import RefreshIcon from '@mui/icons-material/Refresh';

import OkIcon from '../../img/if/ok.svg';
import OffIcon from '../../img/if/off.svg';
import ErrorIcon from '../../img/if/error.svg';
import AddIcon from '../../img/if/add.svg';
import CheckIcon from '@mui/icons-material/Check';

const IfPortal = (props) => {
  const tl = useTranslate();
  
  const [value, setValue] = useState(0);
  const [aktionen, setAktionen] = useState(false);
  const [data, setData] = useState({});
  const [overview, setOverview] = useState({});
  const [portalinfo, setPortalinfo] = useState([]);
  const [portalinfoDisp, setPortalinfoDisp] = useState([]);
  const [portalinfoHelp, setPortalinfoHelp] = useState([]);
  const [details, setDetails] = useState({});
  const [detailData, setDetailData] = useState(false);
  const [rmiList, setRmiList] = useState(false);
  
  const [progress, setProgress] = useState(false);
  const [progAktion, setProgAktion] = useState(false);
  const [logid, setLogid] = useState(false);
  
  const [startDate, setStartDate] = useState(new Date());
  const [anzTage, setAnzTage] = useState(8);
  const [lnr, setLnR] = useState(true);
  const [aktiveRate, setAktiveRate] = useState(-1);
  
  const [piEditId, setPiEditId] = useState(0);
  const [piReload, setPiReload] = useState(0);

  const location = useLocation();
  const params = location.pathname.split("/");
  const agent = params[2];
  
  useEffect(() => {
    dataProvider.getOne('ifAktionen', { id: agent })
      .then(res  => { 
        setAktionen(res.data);
      })
  }, [agent]);

  useEffect(() => {
    dataProvider.getList('ifOverview', { filter: {agent: agent, startDate:startDate.toISOString().substr(0,10), anzTage:anzTage, lastNewRes: lnr, }, pagination: { page: 1, perPage: 1 }, sort: { field: 'date', order: 'DESC' },})
      .then(res  => {
        if (res.data && res.data[0]) {
          const ov = res.data[0];
          var ar = aktiveRate;
          for (var r=0; r < ov.ratelist.length;r++) {
            for (var k=0; k < ov.portrbt.length;k++) {
              var info = ov.portrbt[k];
              var rbt = info.id; 
              var rateid = ov.ratelist[r].ids[rbt];
              for(var tag in ov.availRate.pogr_datum) {
                var pogrid = ov.availRate.pogr_datum[tag];
                var pogrinfo = ov.availRate.pogr_info[pogrid];
                if (pogrinfo.preis[rbt] && pogrinfo.preis[rbt][rateid] && pogrinfo.preis[rbt][rateid].open) {
                  ov.ratelist[r].anyopen = true;
                }
                else {
                  ov.ratelist[r].anyclosed = true;
                }
              }
            }
            if (ov.ratelist[r].anyopen && ov.ratelist[r].anyclosed) {
              ov.ratelist[r].color = '#F9F96E'
              if (ar === -1) {
                ar = r;
              }
            }
            else if (ov.ratelist[r].anyopen) {
              ov.ratelist[r].color = '#E2F9CB';
              if (ar === -1) {
                ar = r;
              }
            }
            else {
              ov.ratelist[r].color = '#F9D8CB'
            }
          }
          if (ar === -1) {
            ar =0;
          }
          setAktiveRate(ar);
          setOverview(ov);
        } 
      })
  }, [startDate, anzTage, lnr, agent, aktiveRate]);

  
  useEffect(() => {
    const ov = overview;
    var det = {};
    if (ov.portrbt && aktionen && ov.ratelist[aktiveRate]) {

      var resttext = {};
      if (aktionen.optionClosedForArrival) { resttext.noarrival = 'no arrival'};
      if (aktionen.optionClosedForDeparture) { resttext.nodeparture = 'no departue'};
      if (aktionen.optionMinNacht) { resttext.min_nacht = 'min. nights'};
      if (aktionen.optionMaxNacht) { resttext.max_nacht = 'max. nights'};

      const decimal = ov.decimal; 
      for (var k=0; k < ov.portrbt.length;k++) {
        var info = ov.portrbt[k];
        var rbt = info.id; 

        var roomlist = [];
        var roomoglist = [];
        
        var occlist = [];
        var restrlist = [];
        var tage = [];
        det[rbt] = [];
        for(var tag in ov.availRate.pogr_datum) {
          var pogrid = ov.availRate.pogr_datum[tag];
          var pogrinfo = ov.availRate.pogr_info[pogrid];
          var restrictions = ov.availRate.restrictions[tag];
          
          if (!pogrinfo) continue;
          
          var rooms = pogrinfo.rooms[rbt];
          if (roomlist.indexOf(rooms) === -1) {
            roomlist.push(rooms);
            roomoglist.push(ov.gruppen[tag].onlinegr);
          }
          
          var rateid = ov.ratelist[aktiveRate].ids[rbt];
          var preis = null;
          var detlist = {};
          if (pogrinfo.preis[rbt]) {
            preis = pogrinfo.preis[rbt][rateid];

            for (var n in preis) {
              if ((!isNaN(n) && isFinite(n)) || n==='default') {
                var p = parseFloat(preis[n]);
                detlist[n] = p.toFixed(decimal);
                if (occlist.indexOf(n) === -1) {
                  occlist.push(n);
                }
              }
            }
            const restr = restrictions[rbt];
            if (restr) {
              for (n in restr) {
                if (resttext[n]) {
                  detlist[resttext[n]] = restr[n];
                  if (restrlist.indexOf(resttext[n]) === -1) {
                    restrlist.push(resttext[n]);
                  }
                }
              }
            }
          }
          tage.push(detlist);
        }
        det[rbt] = {headlist:occlist.sort().concat(restrlist), tage:tage, roomlist:roomlist, roomoglist:roomoglist };
      }
    }
    setDetailData(det);
  }, [aktionen, overview, aktiveRate]);
  
  const UpdateRmiList = (pi) => {
    var rmil = {};
    for (var i=0; i<pi.length; i++) {
      if (pi[i].schluessel.substr(0,4)==='rmi_') {
        rmil[pi[i].schluessel.substr(4)] = pi[i].wert;
      }
    }
    setRmiList(rmil);
  }
    
  useEffect(() => {
    dataProvider.getList('ifPortalinfo', { filter: {agent: agent}, pagination: { page: 1, perPage: 1000 }, sort: { field: 'schluessel', order: 'ASC' },})
      .then(res  => {
        if (res.data && res.data[0]) {
          const pi = res.data;
          setPortalinfo(pi);
          UpdateRmiList(pi)
        } 
      })
  }, [piReload, agent]);
  
  useEffect(() => {
    dataProvider.getList('ifPortalinfo', { filter: {agent: agent, help: true}, pagination: { page: 1, perPage: 1000 }, sort: { field: 'schluessel', order: 'ASC' },})
      .then(res  => {
        if (res.data && res.data[0]) {
          setPortalinfoHelp(res.data);
        } 
      })
  }, [agent]);

  
  useEffect(() => {
    const SetKeyMarks = () => {
      if (!portalinfo.length || !portalinfoHelp.length) return;
      var pi = portalinfo;
      for (var i=0; i<portalinfo.length; i++) {
        pi[i].mark = 2;
        for (var j=0; j<portalinfoHelp.length; j++) {
          if (portalinfoHelp[j].reg) {
            var re = new RegExp(portalinfoHelp[j].reg,"g");
            if (pi[i].schluessel.match(re)) {
              pi[i].mark = portalinfoHelp[j].glob?0:1;
              break;
            }
          }
          else {
            if (pi[i].schluessel === portalinfoHelp[j].key) {
              pi[i].mark = portalinfoHelp[j].glob?0:1;
              break;
            }
            
          }
        }
      }
      setPortalinfoDisp(pi);
    }
    SetKeyMarks();
  }, [portalinfo, portalinfoHelp, agent]);
  
  const Help = (props) => {
    if (!portalinfoHelp.length) return null;
    var prefglob = true;
    return (
      <table><tbody>
        <tr><td><b>All interfaces</b></td></tr>
    {portalinfoHelp.map(row => {
      if (!row.text) return null;
      var jsx = [];
      if (!row.glob && prefglob) {
        prefglob = false
        jsx.push(<tr key={"t-"+row.id}><td><b>{aktionen.lang}</b></td></tr>);
      }
      jsx.push(<tr key={row.id}><td>{row.key}</td><td>{row.text}</td></tr>);
      return jsx;
    })}  
      </tbody></table>
    
    )
  }
  
  const DoRefresh = (props) => (
    <RefreshIcon style={{position:"relative", top:"10px", cursor:"pointer"}} onClick={props.onClick} />
  )
  
  const handleChange = (key) => (event) => {
    setData((d)=>{d[key]=event.target.value; return d});
  };
  
  const handleAktion = (aktion) => (event) => {
    dataProvider.update('ifAktionen', { id: agent, data: {aktion: aktion, data:data} })
      .then(res  => { 
        if (aktion!=='RoomInfo') {
          setAktionen(res.data);
        }
        if (res.data.result && res.data.result.logid>0) {
          setLogid(res.data.result.logid);
          setProgress(1);
        }
        setPiReload(piReload+1);
        setProgAktion(aktion);
      })
  };
  
  useEffect(() => {
    if (progress === false) return;
    dataProvider.update('ifAktionen', { id: agent, data: {aktion: 'getProgress'} })
      .then(res  => { 
        if (res.data.pos < logid) {
          setTimeout(() => { setProgress(progress + 1); }, 1000);          
        }
        else {
          setPiReload(piReload+1);
          setProgress(false);
        }
      })
  }, [progress, agent, logid]);
  
  const ActionBu = (props) => (
    <Button variant="contained" color="primary" {...props}>{tl(props.text)}</Button>
  )
  const Reload = () => {
    setPiReload(piReload+1);
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleScroll = (offset) => (ev) => {
    var d = new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate() + offset * anzTage,4);
    setStartDate(d);
  };
  const handleDate = (event) => {
    var d = new Date(event.target.value)
    setStartDate(d);
  };
  const handleAnzTage = (event) => {
    setAnzTage(event.target.value);
  };
  const handleLnR = (event) => {
    setLnR(event.target.checked);
  };
  
  const handleDetailsClick = (rid) => (event, newValue) => {
    var det = {...details, [rid]:(details[rid]?false:true)};
    var open = false;
    for (var k in det) {
      if (det[k]) open = true;
    }
    if (!open) {det = {}};
    setDetails(det);
  };
  const handleAllDetailsClick = (set) => (event, newValue) => {
    var det = {};
    if (set) {
      overview.portrbt.forEach(info=>{
        det[info.id] = true;
      });
    }
    setDetails(det);
  };
  const handleRateClick = (rid) => (event, newValue) => {
    setAktiveRate(rid);
  };
  
  const handleSchluessel = (ev) => {
    React.PiEditKey = ev.target.value;
  }
  const handleWert = (ev) => {
    React.PiEditValue = ev.target.value;
  }        
  const piEdit = (id) => {
    setPiEditId(id);          
    portalinfo.map(el=>{
      if (el.id===id) {
        React.PiEditKey = el.schluessel;
        React.PiEditValue = el.wert;
      }
      return true;
    });
    return true;
  }
  const piRemove = (id) => {
    dataProvider.delete('ifPortalinfo', { id: id })
      .then(res  => { 
        setPiReload(piReload+1);
      })
  }
  const piAdd = () => {
    setPiEditId(-1);
    React.PiEditKey = '';
    React.PiEditValue = '';
    window.scroll({ top: 0, left: 0, behavior: 'smooth'});
  }
  const piCancel = () => {
    setPiEditId(0);
  }
  const piSave = () => {
    dataProvider.update('ifPortalinfo', { 
        id: piEditId, data: {id: piEditId, id_agent: aktionen.id_agent, schluessel: React.PiEditKey, wert:React.PiEditValue} }
      )
      .then(res  => { 
        setPiReload(piReload+1);
        setPiEditId(0);
      })
  }
  
  const TabPanel = (props) => {
    const { children, value, index } = props;
             
    if (value!==index) return null;
    return (
      <div style={{marginTop:"1em"}}>
        {children}
      </div>
    );
  }    
  
  var tage = [];
  var hasYield = false;
  if (overview.availRate) {
    for(var tag in overview.availRate.pogr_datum) {
      if (overview.gruppen[tag]) {
        tage.push(tag);
        if (overview.gruppen[tag].id_parent > 0) {
          hasYield = true;      
        }
      }
    }
  }
  var spancnt = 0;
  var spancnt2 = 1;
  var spancnt3 = 1;
  var formatter = new Intl.DateTimeFormat(getInfoValue('id_sprache')===1?"de-de":"en-us", { month: 'long'});

  const columns = [
    { field: 'schluessel', headerName: 'Key', width: 70, mark:['#EEE','#FFDB00'] },
    { field: 'wert', headerName: 'Value', width: 70 },
  ];
             
  const stat = aktionen.status;             
  const icon = stat==='up'?OkIcon:(stat==='down'?OffIcon:(stat==='notconfigured'?AddIcon:ErrorIcon)); 
             
  const PortalInfo = () => (
    <>
      {piEditId!==0 &&
        <>
          <TextField id="tfkey" key="tfkey" label="Key" variant="filled" defaultValue={React.PiEditKey} onChange={handleSchluessel} />
          <TextField id="tfval" key="tfval" label="Value" variant="filled" defaultValue={React.PiEditValue} onChange={handleWert} fullWidth />
          <Button variant="contained" color="primary" onClick={piSave} style={{margin:"1em 1em 1em 0"}}>Save</Button>
          <Button variant="contained" color="primary" onClick={piCancel}>Cancel</Button>
          <Help />
        </>
      }
        <DataGrid rows={portalinfoDisp} columns={columns} edit={isHauptadmin()?piEdit:null} remove={isHauptadmin()?piRemove:null} />
        <Button variant="contained" color="primary" onClick={piAdd} style={{margin:"1em 1em 1em 0"}}>Add</Button>
        <Help />
    </>
  )
  
  const Synctime = () => (
    <tr >
      <td align="right">{tl("bw.if.portal.timeSync")}</td>
      <td>
        {aktionen.timeSyncMin} - {aktionen.timeSyncMax}
      </td>
      <td>
        <select defaultValue={aktionen.timeUntil} onChange={handleChange('until')}>
          <option value={aktionen.timeUntil}>{aktionen.timeUntil}</option>
          <option value="1 month">1 month</option>
          <option value="2 month">2 month</option>
          <option value="3 month">3 month</option>
          <option value="4 month">4 month</option>
          <option value="5 month">5 month</option>
          <option value="6 month">6 month</option>
          <option value="7 month">7 month</option>
          <option value="8 month">8 month</option>
          <option value="9 month">9 month</option>
          <option value="10 month">10 month</option>
          <option value="11 month">11 month</option>
          <option value="12 month">12 month</option>
          <option value="13 month">13 month</option>
        </select>&nbsp;
        <ActionBu text="bw.if.portal.timeSet" onClick={handleAktion('SetSync')} />
      </td>
    </tr>
  )
  
  if (!startDate) return null;
  
  var configfields = [];
  if (aktionen.aktionConfig_fields) {
    var firstadm = true;
    const cf = aktionen.aktionConfig_fields;
    for (var i=0;i<=cf.length;i++) {
      var field = cf[i];

      if (firstadm && (i===cf.length || (field && field.admin)) && (!(i===0 && !isReseller() && field && field.admin))) {
        configfields.push(<tr><td></td><td><ActionBu text="bw.if.portal.save_config_fields" onClick={handleAktion('saveConfigFields')} /></td></tr>);
        if (i<cf.length && isReseller()) {
          configfields.push(<tr><td></td><td><br/><b>Admin only</b></td></tr>);
        }
        firstadm = false;
      }
      if (i===cf.length) break;
      if (field.admin && !isReseller()) break;
      
      var row = [];
      row.push(<td align="right">{tl('bw.if.portal.'+field.name.replace('.', ''))}</td>)
      if (field.opt === 'edit') {
        row.push(<input name={field.name} type="text" onChange={handleChange(field.name)} defaultValue={field.value} />)
      }
      else if (field.opt) {
        row.push(
          <select name={field.name} defaultValue={field.value} onChange={handleChange(field.name)}>
          {field.opt.map((el)=>(<option key={el.value} value={el.value}>{el.text}</option>))}
          </select>
        )
      } 
      configfields.push(<tr>{row}</tr>);
    }
  }

  var mri = null;
  
  if (aktionen.optionManualRoomInformation && portalinfo.length) {
    var mrirows = [];

    var mrihead = [];
    for (var j=0; j<aktionen.optionManualRoomInformation.length; j++) {
      mrihead.push(<td>{tl('bw.if.portal.'+aktionen.optionManualRoomInformation[j])}</td>);
    }
    mrirows.push(<tr><td><h3>{tl('bw.if.portal.roomdetails')}</h3></td>{mrihead}</tr>);

    for (i=0; i<portalinfo.length; i++) {
      var mrirow = [];
      if (portalinfo[i].schluessel.substr(0,3)==='rt_') {
        const rt = portalinfo[i].schluessel.substr(3);
        mrirow.push(<td key="name">{portalinfo[i].wert}</td>);
        for (j=0; j<aktionen.optionManualRoomInformation.length; j++) {
          const typ = aktionen.optionManualRoomInformation[j];
          var v = '';
          if (rmiList[rt]) {
            try {
              const rmi = JSON.parse(rmiList[rt]);
              v = rmi[typ];
            }
            catch(e) {
              
            }
          }
          if (typ==='gender') {
            mrirow.push(<td key={'field'+j}>
              <select name={'gender'+rt} defaultValue={v} onChange={handleChange('mri_gender_'+rt)}>
                <option value="0">{tl('bw.if.portal.gender0')}</option>
                <option value="1">{tl('bw.if.portal.gender1')}</option>
                <option value="2">{tl('bw.if.portal.gender2')}</option>
                <option value="4">{tl('bw.if.portal.gender4')}</option>
              </select>
            </td>);
          }
          else if (typ==='roomsize' || typ==='roomcnt' || typ==='defaultsize') {
            mrirow.push(<td key={'field'+j}>
              <input name={typ+rt} defaultValue={v} onChange={handleChange('mri_'+typ+'_'+rt)} type="number" style={{width:'4em'}} />
            </td>);
          }
        }
        
        mrirows.push(<tr key={'row'+i}>{mrirow}</tr>);
      }
    }
    
    mri = (<table>
            {mrirows}
            <tr><td></td><td colspan="3"><Button variant="contained" color="primary" onClick={handleAktion('RoomInfo')}>{tl('bw.if.portal.saveroomdetails')}</Button></td></tr>
           </table>);
  }
  
  
  return (
    <Card>
      <CardContent>
        <div style={{float:'left',width:'50%'}}>
         <h2>{aktionen.lang} <img src={icon} style={{width:'1em'}} alt="" /></h2>
         
    {aktionen.status==='corrupt' && 
        <><b>{tl("bw.if.portal.corruptTitel")}</b> {tl("bw.if.portal.corruptText")}</>
    }
        </div>
      {aktionen.aktionLoginURL?
        <div style={{float:'left',width:'44%', background:'#eee',padding:'1em 3%'}}>
          Log-in {aktionen.lang} backend<br />
          <a href={aktionen.aktionLoginURL} target="_blank" rel="noreferrer">{aktionen.aktionLoginURL}</a>
        </div>
      :null}
        <div style={{clear:'both'}} />
  {aktionen.status==='notconfigured' || aktionen.status==='corrupt'?
    <>
      <div dangerouslySetInnerHTML={{__html: aktionen.ActivationText}} />
      {isReseller() && <PortalInfo />}
    </>
        
  :      
        <>
        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label={tl("bw.if.portal.tab1")} />
          <Tab label={tl("bw.if.portal.tab2")}  />
          {isReseller() && <Tab label={tl("bw.if.portal.tab3")} />}
          <DoRefresh onClick={Reload} />
        </Tabs>      
{/* Availabilities --------------------------------------------------------        */}
        <TabPanel value={value} index={0} variant="scrollable">
    {aktionen.kurz !== 'hi' && overview.portrbt &&
          <div>
            <Button variant="contained" color="primary" value="-" onClick={handleScroll(-1)} > &lt;&lt; </Button>
            <TextField id="date" type="date" value={startDate.toISOString().substr(0,10)} onChange={handleDate} style={{marginLeft:'1em', width:'9em'}}/>
            <Button variant="contained" color="primary" value="+" onClick={handleScroll(1)} style={{marginLeft:'1em'}}> &gt;&gt; </Button>
            <Select native value={anzTage} onChange={handleAnzTage} style={{marginLeft:'14px'}} >
              <option value={5}>5 {tl('bw.if.portal.days')}</option>
              <option value={8}>8 {tl('bw.if.portal.days')}</option>
              <option value={12}>12 {tl('bw.if.portal.days')}</option>
              <option value={15}>15 {tl('bw.if.portal.days')}</option>
              <option value={29}>29 {tl('bw.if.portal.days')}</option>
              <option value={31}>31 {tl('bw.if.portal.days')}</option>
            </Select>
      {isReseller() &&
            <FormControlLabel control={<Switch checked={lnr} onChange={handleLnR} name="LnR" color="primary" />} label="Last new res" style={{marginLeft:'1em'}}/>
      }
            <table>
              <tbody>
                <tr><td align="right">{tage[0] && tage[0].substr(0,4)}</td><td></td>
        {tage.map((tag, i)=>{
          if (i>=tage.length-1 || tag.substr(4,2)!==tage[i+1].substr(4,2)) {
            var d = new Date(tag.substr(0,4)+'-'+tag.substr(4,2)+'-'+tag.substr(6,2));
            return (
                  <td key={tag} colSpan={spancnt+1} align="center" style={{borderLeft: '1px solid black'}}>{formatter.format(d)}</td>
            )
          }
          else {
            spancnt++;
          }
          return null;
        })}
                </tr>
                <tr><td></td><td></td>
        {tage.map(tag=>{
          var d = new Date(tag.substr(0,4)+'-'+tag.substr(4,2)+'-'+tag.substr(6,2));
          d = d.getDay();
          return (<td key={tag} align="center" style={{backgroundColor:(d===0 || d===6)?'#eee':'transparent'}}>{tag.substr(6)}</td>)
        })}
        
                </tr>
                <tr><td align="right">Price group</td><td></td>
        {tage.map((tag, i)=>{
          const gruppe = overview.gruppen[tag];
          const gruppe2 = overview.gruppen[tage[i+1]];
          if (i>=tage.length-1 || gruppe.preisgr!==gruppe2.preisgr) {
            var spn = spancnt2;
            spancnt2 = 1;
            return (
                  <td colSpan={spn} align="center" key={tag} style={{color:gruppe.preistext, background:gruppe.preisfarbe}}>
                    <div>{gruppe.preisgr}</div>
                  </td>
            )
          }
          else {
            spancnt2++;
          }
          return null;
        })}
                </tr>
        {hasYield &&
                <tr><td align="right">Price changed in yield manager</td><td></td>
        {tage.map((tag, i)=>(
          <td key={tag} style={{textAlign:'center',backgroundColor:overview.gruppen[tag].id_parent>0?'#ccc':'transparent'}}>{overview.gruppen[tag].id_parent>0?'*':''}</td>
        ))}
                </tr>
        }
                <tr><td align="right">Online group</td><td></td>
        {tage.map((tag, i)=>{
          const gruppe = overview.gruppen[tag];
          const gruppe2 = overview.gruppen[tage[i+1]];
          if (i>=tage.length-1 || gruppe.onlinegr!==gruppe2.onlinegr) {
            var spn = spancnt3;
            spancnt3 = 1;
            return (
                  <td colSpan={spn} align="center" key={tag} style={{color:gruppe.onlinetext, background:gruppe.onlinefarbe}}>{gruppe.onlinegr}</td>
            )
          }
          else {
            spancnt3++;
          }
          return null;
        })}
                </tr>
                <tr><td style={{textAlign:'right', borderBottom:"3px solid"}} >{tl('bw.if.portal.ratelegende')} [{overview.currency}]</td>
                <td>{Object.keys(details).length > 0?<ReduceAllIcon onClick={handleAllDetailsClick(false)} />:<ExpandAllIcon onClick={handleAllDetailsClick(true)} />}</td>
                
                  <td colSpan={tage.length} style={{textAlign:'center', borderBottom:"3px solid"}}>
        {overview.ratelist.map((rl, i)=>(
          <div onClick={handleRateClick(i)} key={i} 
            style={{backgroundColor:rl.color, position:'relative', cursor:'pointer', border:'1px solid black', fontSize:'0.8em', padding:'0 5px', float:'left', width:'33%', boxSizing: 'border-box'}}>
            {rl.ratename} 
            {aktiveRate===i?<CheckIcon color="primary" style={{position: 'absolute', left: 0, bottom: 0, width: '0.8em', height: '0.8em'}} />:null}
          </div>  
        ))}
                  
                  </td>
                </tr>
        {overview.portrbt.map((info, k)=>{
          var privat = info.geschlecht==="4";
          const border = privat && overview.portrbt[k-1] && overview.portrbt[k-1].geschlecht!=="4"?{borderTop:"3px solid"}:{}; 
          border.verticalAlign = 'top';

          if (!detailData) return null;
          return (
               <React.Fragment key={k}> 
                <tr key={info.id}>
                  <td style={border}>{info.name}
                    <div style={{textAlign:'right', fontSize:'0.8em'}}>
            {detailData[info.id] && detailData[info.id].roomlist.length>1?
              detailData[info.id].roomlist.map((rl, i) => (
                <div key={i}>({detailData[info.id].roomoglist[i]}) {detailData[info.id].roomlist[i]?detailData[info.id].roomlist[i]:'no rooms assigned'}</div>
              ))
            :
              <div>{detailData[info.id] && detailData[info.id].roomlist[0]}</div>
            }      
                  </div></td>
                  <td style={{verticalAlign: 'top'}}>
                    {details[info.id]?<ExpandLessIcon onClick={handleDetailsClick(info.id)} />:<ExpandMoreIcon onClick={handleDetailsClick(info.id)} />}
                  </td>
          {tage.map((tag)=>{
            var avail = overview.availRate.avail[tag]
            var beds = avail[info.id];
            var anz = privat?Math.round(beds / Math.max(1, info.size)):beds;
            var rbt = overview.availRate.pogr_datum[tag];
            var pogrinfo = overview.availRate.pogr_info[rbt];
            return (
                  <td key={tag} style={border}>
                    <div style={{textAlign:'center', backgroundColor:(beds>0?'#E2F9CB':'#F9D8CB'), paddingBottom: '3px'}}>
                      {anz} {tl('bw.if.portal.'+(privat?'room':'bed')+(anz!==1?'s':''))}
                    </div>
                    <div>
            {overview.ratelist.map(rl=>{
              var rateid = rl.ids[info.id];
              var preis = null;
              if (!pogrinfo.preis[info.id]) return null;
              if (pogrinfo) {
                preis = pogrinfo.preis[info.id][rateid];
              }
              var open = preis && preis.open;
              return (
                        <div key={rl.ratename} style={{width:'33%', float:'left', backgroundColor:(open?'#E2F9CB':'#F9D8CB'), color:(open?'#000':'#F9D8CB'), border:'1px solid black', boxSizing: 'border-box', fontSize:'0.8em'}}>
                          {preis && preis.default?Math.round(preis.default):'.'}
                        </div>
              )
            })}
                    </div>
                  </td>
            )
          })}    
                </tr>
          {details[info.id] && 
            <tr>
            <td style={{textAlign:'right', verticalAlign: 'top'}}>
              {detailData[info.id] && detailData[info.id].headlist.map((head)=>(
                <div key={head}>{head}</div>
              ))}
            </td>
            <td></td>
            {detailData[info.id] && detailData[info.id].tage.map((detlist)=>{
              var det = [];
              for(var i=0;i<detailData[info.id].headlist.length;i++) {
                var k = detailData[info.id].headlist[i];
                if (detlist[k] !== undefined) {
                  det.push(<div key={k}>{detlist[k]===true?1:detlist[k]}</div>);
                }
                else {
                  det.push(<div key={i}>&nbsp;</div>);
                }
              }
              
              
              return ( <td style={{textAlign:'right', verticalAlign: 'top'}}>{det}</td>);
            })}    
            </tr>
          }
        </React.Fragment>  
        )})}    

              </tbody>
            </table>
          </div>
      }
      {aktionen.kurz === 'hi' &&  
          <h2>Not available for this interface</h2>
      }
        </TabPanel>
{/* Actions --------------------------------------------------------        */}
        <TabPanel value={value} index={1}>
          <table>
            <tbody>
    {aktionen.status==='up' && 
      <>
      {aktionen.aktionChangeDown &&
              <tr style={{verticalAlign:"top"}} >
                <td align="right" style={{padding:"0.5em 0 4em 0"}}>{tl("bw.if.portal.InterfaceUp")}</td>
                <td><Switch checked={true} onChange={handleAktion('ChangeDown')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></td>
              </tr>
      }
              <Synctime />
      {aktionen.result && aktionen.result.text &&
              <tr>
                  <td></td>
                  <td><div dangerouslySetInnerHTML={{__html: aktionen.result.text}} /></td>
              </tr>
      
      }
      {isReseller() && aktionen.aktionImportRes &&
              <tr>
                <td align="right">{aktionen.kurz} ID: <input name="portal_id" type="text" onChange={handleChange('portal_id')} /></td>
                <td><ActionBu text="bw.if.portal.ReservationHolen" onClick={handleAktion('ImportRes')} /></td>
              </tr>
      }
      {aktionen.aktionImportRoomCat &&
              <tr>
                <td align="right">{tl("bw.if.portal.UpdateRoomtypesText")}</td>
                <td><ActionBu text="bw.if.portal.UpdateRoomtypesButton" onClick={handleAktion('ImportRoomCat')} /></td>
        {progAktion==='ImportRoomCat' &&        
                <td>{progress===false?<DoneIcon />:<HourglassEmptyIcon />}</td>
        }        
              </tr>
      }
      {isReseller() && aktionen.aktionRoomCatHTML &&
              <tr>
                <td align="right">{tl("bw.if.portal.UpdateRoomtypesText")}</td>
                <td><TextField onChange={handleChange('room_html')} /><ActionBu text="bw.if.portal.UpdateRoomtypesButton" onClick={handleAktion('ImportRoomCatHTML')} /></td>
              </tr>
      }
      {aktionen.aktionUpdateAvail &&
              <tr>
                <td align="right">{tl("bw.if.portal.updateAvailText")}</td>
                <td><ActionBu text="bw.if.portal.updateAvailButton" onClick={handleAktion('UpdateAvail')} /></td>
      {progAktion==='UpdateAvail' &&        
                <td>{progress===false?<DoneIcon />:<HourglassEmptyIcon />}</td>
      }        
              </tr>
      }
      {isHauptadmin() && aktionen.aktionResetCache &&
              <tr>
                <td align="right">{tl("bw.if.portal.resetCacheText")}</td>
                <td><ActionBu text="bw.if.portal.resetCacheButton" onClick={handleAktion('ResetCache')} /></td>
      {progAktion==='ResetCache' && <td><DoneIcon /></td>}        
              </tr>
      }
      {isHauptadmin() && aktionen.aktionFirstErrorReset &&
              <tr>
                <td align="right">{tl("bw.if.portal.FirstErrorResetText")}</td>
                <td><ActionBu text="bw.if.portal.FirstErrorResetButton" onClick={handleAktion('FirstErrorReset')} /></td>
      {progAktion==='FirstErrorReset' &&        
                <td>{progress===false?<DoneIcon />:<HourglassEmptyIcon />}</td>
      }        
              </tr>
      }
      {aktionen.aktionConfig_fields && 
        <>
          <tr><td><br />&nbsp;</td></tr>
          {configfields}
        </>
      }
              <tr>
                <td align="right">{tl("bw.if.portal.timeNow")}</td>
                <td>{aktionen.timeNow}</td>
              </tr>
              <tr >
                <td align="right">{tl("bw.if.portal.timeLast")}</td>
                <td>{aktionen.timeLastNewToday1}</td>
                {isHauptadmin() && <td>{aktionen.timeLastNewToday2}</td>}
              </tr>
      </>
    }
    {aktionen.status==='down' && 
      <>
      {aktionen.aktionChangeUp &&
              <tr>
                <td align="right">{tl("bw.if.portal.InterfaceDown")}</td>
                <td><Switch checked={false} onChange={handleAktion('ChangeUp')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></td>
              </tr>
      }
              <Synctime />
      </>
    }
    {aktionen.status==='corrupt' && 
      <>
              <tr>
                <td><h2>{tl("bw.if.portal.corruptTitel")}</h2>{tl("bw.if.portal.corruptText")}</td>
              </tr>
      </>
    }
            </tbody>
          </table>
          {mri}
        </TabPanel>
{/* Settings (PortailInfo) --------------------------------------------------------        */}
    {isReseller() &&
        <TabPanel key="tab2" value={value} index={2} variant="scrollable">
          <PortalInfo />
        </TabPanel>
    }
        </>
  }               
      </CardContent>
    </Card>
  )
};

export default IfPortal;

