import React from 'react';
import { SaveButton, Toolbar, DeleteButton, useRedirect, useEditContext, useCreateContext, Button } from 'react-admin';
import BackspaceIcon from '@mui/icons-material/Backspace';

const CloseToolbar = ({redir, noDelete, noBack}) => {
  const redirect = useRedirect();
  let { resource } = useEditContext();
  const createcontext = useCreateContext();
  if(!resource)resource = createcontext.resource;

  const clickBack = () => {
    redirect('list', redir?redir:resource);
  }

  return(
    <Toolbar>
        <SaveButton label="Save" mutationOptions={{
          onSuccess: () => {
            redirect('list', redir?redir:resource);
          }}
        }/>
      {!noBack &&  
        <Button label="ra.action.back" onClick={clickBack} variant="contained" size="medium" sx={{marginLeft:'1em'}}>
          <BackspaceIcon />
        </Button>
      }
      {!noDelete &&
        <DeleteButton style={{marginLeft: 'auto'}} mutationMode="pessimistic" />
      }
    </Toolbar>
  );
};

export default CloseToolbar;
