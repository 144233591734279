import React from 'react';
import { useRecordContext, TextField } from 'react-admin';

const ConditionalTextField = ({ dispSource, dispLabel, condition, ...rest }) => {
  const record = useRecordContext();
  return record && record[condition] ? <TextField {...rest} source={dispSource} label={dispLabel} /> :null;
};

export default ConditionalTextField; // decorate with redux-form's <Field>

