import React from 'react';
import { SelectInput } from 'react-admin';

const GenderInput = ({ source }) => (
  <SelectInput label='glob.gender.label' source={ source } optionValue="id" choices={[
    { id: 0, name: 'glob.gender.woman' },
    { id: 1, name: 'glob.gender.man' },
    { id: 2, name: 'glob.gender.mixed' },
    { id: 3, name: 'glob.gender.empty' },
  ]} />
);
export default GenderInput; // decorate with redux-form's <Field>

