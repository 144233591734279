import React from 'react';
import { useRecordContext, BooleanField } from 'react-admin';
const BooleanRedField = (props) => {
  const record = useRecordContext();
  const v = record && record[props.source];
  return (
  <BooleanField {...props} style={{color:v?"#888":"#FB4049"}} />
  )
}

export default BooleanRedField;