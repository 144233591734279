import React from 'react';
import { isHauptadmin, getInfoValue } from '../../lib/globalData';
import { List, Datagrid, TextField, 
  TextInput, ReferenceInput, SelectInput, SelectArrayInput, AutocompleteInput, DateInput, Filter } from 'react-admin';

const PostPanel = ({ id, record, resource }) => (
  <div>
    <div>Old Data: {record && record.old_data}</div>
    <div>New Data: {record && record.new_data}</div>
  </div>
);

const meth_choices=[
  { id: 'get' , name: 'GET' },
  { id: 'put' , name: 'PUT' },
  { id: 'post' , name: 'POST' },
  { id: 'delete' , name: 'DELETE' },
];

const offset_choices=[
  { id: '1 hour', name: '1 Stunde' },
  { id: '2 hours', name: '2 Stunden' },
  { id: '4 hours', name: '4 Stunden' },
  { id: '6 hours', name: '6 Stunden' },
  { id: '12 hours', name: '12 Stunden' },
  { id: '18 hours', name: '18 Stunden' },
];

const ListFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <SelectArrayInput source="methode" choices={meth_choices} alwaysOn/>
      <ReferenceInput source="id_hostel" reference="globHostel" alwaysOn>
        <AutocompleteInput optionText="hostel" label="hostel" />
      </ReferenceInput>
      <TextInput source="id_benutzer" resettable alwaysOn/>
      <TextInput source="q" label="Search" resettable alwaysOn/>
      <SelectInput source="offset" choices={offset_choices} />
      <DateInput source="date" label="Datum" resettable />
      <TextInput source="endpoint" resettable/>
      <TextInput source="id_db_benutzer" resettable/>
    </Filter>
  )
};

const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
    <List {...rest} filters={<ListFilter />} filterDefaultValues={{ id_hostel: getInfoValue('id_hostel'), methode: ['put','post'] }} title="bw.set.paymenttype.title" sort={{ field: 'zeit', order: 'DESC' }}
      exporter={isHauptadmin() && props.exporter} perPage={25} >
      <Datagrid style={{ width: 'auto' }} rowClick='expand' expand={<PostPanel />} bulkActionButtons={false}>
        <TextField source="zeit" />
        <TextField source="id_hostel" />
        <TextField source="user" />
        <TextField source="id_benutzer" />
        <TextField source="id_db_benutzer" />
        <TextField source="id_rootuser" />
        <TextField source="id_fw_gruppe" />
        <TextField source="parent_token" />
        <TextField source="methode" />
        <TextField source="endpoint" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
      </Datagrid>
    </List>
  )
}


const globtlogapi = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

}
export default globtlogapi
