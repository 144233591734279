import React from 'react';
import { isHauptadmin, getRecht } from '../../lib/globalData';
import { usePermissions, List, Create, Edit, Datagrid, SimpleForm, TextField, ReferenceField,
  EditButton, TextInput, PasswordInput, ReferenceInput, AutocompleteInput, SelectInput, Filter } from 'react-admin';
import BulkActionButtons from '../../lib/BulkActionButtons';
import CloseToolbar from '../../lib/CloseToolbar';
import Grid from '@mui/material/Grid';

const ListForm = (props) => {
  const {classes, ...rest} = props;
  return (
    <List {...rest} filters={<ListFilter />} title="bw.set.paymenttype.title" sort={{ field: 'benutzername', order: 'ASC' }}
      exporter={isHauptadmin() && props.exporter} perPage={20} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={<BulkActionButtons />} >
        <TextField source="benutzername" />
        <ReferenceField source="id_hostel" reference="globHostel" link={false}>
          <TextField source="hostel" />
        </ReferenceField>
        <ReferenceField source="id_fw_gruppe" reference="globUsergroup" link={false}>
          <TextField source="bezeichnung" />
        </ReferenceField>
        <TextField source="sprache" />
        <TextField source="yubico" />
        <TextField source="last_connect" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const validateUser = (values) => {
  const errors = {};
  if ((values.passwort || values.passwort2) && values.passwort!==values.passwort2) {
    errors.passwort = ['passwords dont match'];
    errors.passwort2 = ['passwords dont match'];
  }
  return errors
};

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const { permissions } = usePermissions();
  return (
    <SimpleForm toolbar={<CloseToolbar />} validate={validateUser} {...props}>
      <Grid container style={{width:'100%'}}>
        <Grid item xs={12} md={4}>
          <TextInput source="benutzername" />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput source="id_hostel" reference="globHostel" perPage={1000} disabled={getRecht(permissions, 'globUser')<=9}>
            <SelectInput optionText="hostel" label="Hostel" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput source="id_fw_gruppe" reference="globUsergroup" perPage={1000} sort={{ field: 'pos', order: 'ASC' }} >
            <SelectInput optionText="bezeichnung" label="User group" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="vorname" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="name" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="fw_email" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput source="yubico" />
        </Grid>
        <Grid item xs={12} md={4}>
          <PasswordInput source="passwort" />
        </Grid>
        <Grid item xs={12} md={4}>
          <PasswordInput source="passwort2" />
        </Grid>
      </Grid>
    </SimpleForm>
  )
};

const ListFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="benutzername" resettable alwaysOn/>
      <ReferenceInput source="id_hostel" reference="globHostel" alwaysOn>
        <AutocompleteInput optionText="hostel" label="hostel" />
      </ReferenceInput>
      <ReferenceInput source="id_fw_gruppe" reference="globUsergroup" sort={{ field: 'bezeichnung', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText="bezeichnung" />
      </ReferenceInput>
    </Filter>
  )
};

const globuser = {
  list: ({ classes, ...props }) => (
    <ListForm {...props} classes={classes} />
  ),

  create: ({classes, ...props }) => (
    <Create {...props} title="bw.set.paymenttype.titleCreate" redirect="list">
      <CEForm {...props} classes={classes} />
    </Create>
  ),

  edit: ({ classes, ...props }) => (
    <Edit title="bw.set.paymenttype.titleEdit" mutationMode="pessimistic" {...props}>
      <CEForm {...props} classes={classes} />
    </Edit>
  )
}
export default globuser
