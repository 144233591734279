import React, { useState, useEffect } from 'react';
import { Edit, useTranslate, useRecordContext, SimpleForm, BooleanInput,
        TextInput } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';
import CloseToolbar from '../../lib/CloseToolbar';
import Button from '@mui/material/Button';
import dataProvider from '../../lib/dataProvider';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';

const CEForm = ({hasList, hasEdit, hasCreate, hasShow, permissions, ...props}) => {
  const record = useRecordContext();
  
  const [filter, setFilter] = useState(0);
  const [preisRate, setPreisRate] = useState(0);
  const [update, setUpdate] = useState(0);
  const translate = useTranslate();

  useEffect(() => {
    dataProvider.getList('calRate', { filter: { rateprice: filter, pgid: record.id }, sort: {  }, pagination: { page: 1, perPage: 999 }, })
      .then(res  => {
        setPreisRate(res.data)
      })
  }, [filter, record.id]);
  
  const SimpleButton = ({title, i, key, ...props}) => {
    if(!title) return null;
    return (
      <Button variant="contained" color="primary" target={key} style={{width:'130px'}}>{translate(title)}</Button>
    )
  }
  
  const RateField = ({name, i, ...props}) => {
    const { setValue } = useFormContext();
    const inp1 = useController({ name, defaultValue: '' });
    
    const riChange = (ev) => {
      const v = ev.target.type==='number'
        ?ev.target.value + '|' + ev.target.nextElementSibling.value
        :ev.target.previousElementSibling.value + '|' + ev.target.value;
      setValue(name, v, { shouldDirty: true });
    }
    
    const [vals, typs] = inp1.field.value.split('|'); 
    const val = parseFloat(vals);
    const typ = parseInt(typs);
    const disabled = typ < 2;
    const undef = false;
    var rp = false;
    if (typ===2) {
      rp = props.roompreis * (100+val) / 100;
    }
    if (typ===3) {
      rp = parseFloat(props.roompreis) + val;
    }
    rp = rp?parseFloat(rp).toFixed(2):'';
    return (
      <div style={{minWidth:'200px'}}>
        <input type="number" value={val} disabled={disabled} style={{border: 'none', padding:'5px', width:'60px',textAlign:'right', backgroundColor:disabled?'#FFF':'#EEE' }} onChange={riChange} />
        <select type="text" value={typ} style={{border: 'none', padding:'5px', width:'70px',textAlign:'right', backgroundColor:undef?'#F88':'#EEE'}} onChange={riChange} >
      {props.choices.map(it=>{
        const bez = it.id===-2?'undef.':it.bezeichnung;
        return (
          <option key={it.id} value={it.id} style={{backgroundColor:'#FFF'}}>{bez}</option>
      )})}
        </select>
        <span style={{width:'70px', textAlign:'right', display:'inline-block'}}>{rp}</span>
      </div>
    );       
  }
  const Cell = ({ saving, undoable, save, type, ...props}) => {
    const {translate, label, optionText, validate, ...rest} = props;
    switch(type) {
      case 'value': return (<td style={{padding: '1px 0.2em', borderBottom:'1px solid #ccc', textDecoration:props.disabled?'line-through':'none'}}>{props.value}</td>);
      case 'rate': return (<td style={{padding: '1px 0.2em', borderBottom:'1px solid #ccc', width:'40px', borderLeft:'1px solid #CCC'}} {...rest} ><RateField {...props} label="" helperText={false} /></td>);
      case 'button': return (<td style={{padding: '0px 0.2em', borderBottom:'1px solid #ccc'}} {...rest} ><SimpleButton {...props} /></td>);
      default: return null;
    }
  }

  const Tbl = ({ idx, data, ...props }) => {
    const { getValues, setValue } = useFormContext();

    const CopyToAll = (data, idx, rt_id) => {
      var value_type='0|0';
      for(var rate_id=0; rate_id<data.rateinfo.length; rate_id++){
        if(data.roomtype[rt_id].rate['r'+rate_id]) {
          value_type=getValues('tpreis_rate.'+idx+'.roomtype.'+rt_id+'.rate.r'+rate_id+'.value_type');
          for(var rti=0; rti<data.roomtype_anz; rti++){
            if(data.roomtype[rti].rate['r'+rate_id]){
              setValue('tpreis_rate.'+idx+'.roomtype.'+rti+'.rate.r'+rate_id+'.value_type', value_type);
            }
          }
        }
      }
      setUpdate(update+1);
      return true;
    }
    
    const f = getValues("tpreis_rate."+idx+".multirate.rate_value");
    const active = (f > 0);

    if(!data || !data.active) return null;
    var header = []
    
    header.push(<BooleanInput key={idx+"cb"} source={"tpreis_rate."+idx+".multirate.rate_value"} label={data.agent_name+(active?'':' (Zum aktiveren: Anklicken, speichern und dann wieder bearbeiten.)')} />);
    const enabled = getValues("tpreis_rate."+idx+".multirate.rate_value");
    if(!data.roomtype_anz || !enabled) return header;
    const blank = {id: -2, bezeichnung: translate('bw.cal.rate.empty')};
    var rows = [];
    var c = 0;
    var col_header = [];
    var choices = [];
    var disabled = false;
    for(c=0; c<data.rateinfo_anz; c++){
      col_header.push(<th style={{ maxWidth:'300px' }} key={idx+c+"th"}>{data.rateinfo[c].name}</th>);
    }
    for(var i=0; i<data.roomtype_anz; i++){
      var cols = [];
      const rt_id = i;    
      const roomid = preisRate && preisRate[idx].roomtype[i]?preisRate[idx].roomtype[i].id:0;
      const roompreis = preisRate && preisRate[idx].price?preisRate[idx].price[roomid]:'';
      for(c=0; c<data.rateinfo_anz; c++){
        if(data.roomtype[i].rate && data.roomtype[i].rate['r'+c]){
          disabled = false;
          choices = data.rateinfo[c].rate_typen.slice();
          if(data.roomtype[i].rate['r'+c].rate_typ === -2){
            choices.unshift(blank);
          }                                                 
          cols.push(<Cell type="rate" name={"tpreis_rate."+idx+".roomtype."+i+".rate.r"+c+".value_type"} roompreis={roompreis} choices={choices} key={i+"c4"+c} disabled={disabled} i={i} />);
        }
        else {
          cols.push(<td style={{padding: '1px 0.5em', borderBottom:'1px solid #ccc'}} key={i+"c5"+c} ></td>);
        }
      }
      rows.push(
        <tr key={i}>
          <Cell type="value" value={data.roomtype[i].bezeichnung} key={i+"c1"} />                      
          <Cell type="value" value={preisRate?preisRate[idx].rooms[roomid]:''} key={i+"c2"} />                      
          <Cell type="value" value={roompreis} key={i+"c3"} />                      
          {cols}
          <Cell type="button" title={'bw.cal.rate.copy'} onClick={(e) => {CopyToAll(data, idx, rt_id );}} key={i+"c7"} />
        </tr>
      )
    }
    return (
      <div>
        <div>
          {header}
        </div>
        <Table style={{width:'100%', borderCollapse:'collapse', padding:0}} >
          <tbody>
            <tr align="left" >
              <th style={{ width:'250px' }}>{translate('bw.cal.rate.name')}</th>
              <th>{translate('bw.cal.rate.rooms')}</th>
              <th style={{ width:'50px' }}>{translate('bw.cal.rate.price')}</th>
              {col_header}
              <th style={{ width:'140px' }}></th>
            </tr>
            {rows}
          </tbody>
        </Table>
      </div>
    )
  }

  var table = [];
  if(record.tpreis_rate_anz){
    for(var i=0; i<record.tpreis_rate_anz; i++) {
      table.push( <Tbl data={record.tpreis_rate[i]} idx={i} key={i} /> )
    }
  }
  var o_choices = [];
  if(record.tonline_gr_anz > 0){
    o_choices = record.tonline_gr;
    if (filter===0) {
      setFilter(o_choices[0].id);
    }
  }
  return (
    <SimpleForm toolbar={<CloseToolbar redir={"/calPricegr/"} />} {...props}>
      <div style={{ width: 'auto' }} >
        <TextInput source="bezeichnung" label="bw.cal.rate.preisgr" disabled helperText={false} />
        <p style={{ fontSize: 'normal', fontWeight: 'bold' }}>
          {translate('bw.cal.rate.title')+': '+record.bezeichnung}
        </p>
        <p style={{ fontSize: 'smaller' }}>
          {translate('bw.cal.rate.info')}
        </p>
        <hr style={{ marginBottom: '0' }} />
        <div>
          <span style={{ float: 'left', marginTop: '1em', paddingRight: '0.9em' }}>{translate('bw.cal.rate.filterInfo')}</span>
          <Select native value={filter} style={{width:'290px'}} onChange={e=> { setFilter(e.target.value) }} >
          {o_choices.map(it=>(
            <option value={it.id} key={it.id}>{it.bezeichnung}</option>
          ))} }
          </Select>
        </div>
        <hr style={{ margin: '1em 0' }} />
      </div>
      {table}
    </SimpleForm>
  )
};

const rate = {
  edit: ({ ...props }) => (
    <Edit title="bw.cal.rate.titleEdit" mutationMode="pessimistic" {...props} redirect="/calPricegr/">
      <CEForm {...props} />
    </Edit>
  )
}
export default rate;
