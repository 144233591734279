const isTest = window.location.hostname !== 'admin.book.world';
var apiPath = '';
var oldDomain = '';
var mapApi = '';
var testsystemId = '';
switch(window.location.hostname) {
  case 'admin.book.world':
    apiPath = 'https://restapi.book.world/';
    oldDomain = 'https://wrap.bookdotworld.com'
    mapApi = 'AIzaSyC50OiKWQUCgtyYcvTfreMst17wn-vHpQ0'
    break;
  
  case 'localhost':
    const systno = window.location.port - 3000;
    apiPath = 'https://restapi.dev'+systno+'.book.world/';
    oldDomain = 'https://dormdev'+systno+'.alixon.ch'
    mapApi = 'AIzaSyAqxA-cpTjwJkNIqxEQzFTL5AI5XZ-5L5U'
    testsystemId = 'dev'+systno
    break;
  
  default:
    apiPath = 'https://'+window.location.hostname.replace('admin.', 'restapi.')+'/'; 
    oldDomain = 'https://'+window.location.hostname.replace('admin.', 'dorm').replace('.book.world', '.alixon.ch');
    mapApi = 'AIzaSyAqxA-cpTjwJkNIqxEQzFTL5AI5XZ-5L5U'
    testsystemId = window.location.hostname.split('.')[1];
  break;
  
}

export { apiPath, oldDomain, mapApi, testsystemId };

export default isTest;
