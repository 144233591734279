import React, { useState, useEffect} from 'react';
import dataProvider from '../../lib/dataProvider';
import { useTranslate, Link } from 'react-admin';
import { isHauptadmin } from '../../lib/globalData';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SyncIcon from '@mui/icons-material/Sync';
//import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddIcon2 from '@mui/icons-material/Add';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import OkIcon from '../../img/if/ok.svg';
import OffIcon from '../../img/if/off.svg';
import ErrorIcon from '../../img/if/error.svg';
import AddIcon from '../../img/if/add.svg';

const icons = {a5:'svg', bc:'svg', bwo:'svg', cbw:'svg', cnx:'svg', ctp:'png', desk:'png', ex:'svg', gfl:'svg', hero:'png', hc:'png', hi:'svg', HW2:'svg', mA:'png', rpg:'svg', sm:'svg', STC:'svg', tomas:'svg', wst:'svg'};

const IfList = (props) => {
  const translate = useTranslate();
  const [interfaces, setInterfaces] = useState([]);
  
  useEffect(() => {
    dataProvider.getList('IfList', { pagination: { page: 1, perPage: 1 }, sort: { field: 'date', order: 'DESC' },})
      .then(res  => { 
        setInterfaces(res.data);
      })
  }, []);

  const handleClick = (aktion) => (event) => {
    dataProvider.update('ifOverview', { id: 1, data: {aktion: aktion} })
      .then(res  => { 
      })
  };
  
  // style für Icon ={{fontSize:"1.2em",position:"relative",top:"0.2em"}}
  const InfoDisplay = ({icon, text})=>{
    //if (text==="") return null;
    //if (text===0) return null;
    if (!text) return null;
    return (
      <div className="InfoDisplay" style={{marginRight:"0.5em",display:"inline-block"}}>
        {icon}{text}
      </div> 
    )
  }
  
  const IfBox = (props)=>{
    const stat = props.inf.status;
    const icon = stat==='up'?OkIcon:(stat==='down'?OffIcon:(stat==='notconfigured' || !isHauptadmin()?AddIcon:ErrorIcon)); 
    return (
    <Grid item xs={12} md={3}  onClick={handleClick}
    style={{ position:"relative", padding: "3em", margin:"1em 2em 1em 0", background:'#ddd url('+icon+') right 10px bottom 10px no-repeat', backgroundSize:'10%'}}>
      <Link to={'/ifPortal/'+ props.inf.lang}>
    {icons[props.inf.kurz]?
      <div style={{background:'url("/if/'+props.inf.kurz+'.'+icons[props.inf.kurz]+'") center no-repeat', backgroundSize:'contain', padding:'14%'}} />
    :    
    <h1 style={{color:'#FFF'}}>{props.inf.lang}</h1>
    }   
      </Link>   
  {isHauptadmin() && 
      <div style={{position:"absolute", left:"1em", top:"1em", width:"80%"}}>
        <InfoDisplay icon=<HourglassEmptyIcon /> text={props.inf.piLastNewRes} />
        <InfoDisplay icon=<AddIcon2 /> text={props.inf.ServiceKuerzel} />
        <InfoDisplay icon=<PriorityHighIcon /> text={props.inf.piImportant} />
        <InfoDisplay icon=<NotificationsActiveIcon /> text={props.inf.piReminder} />
        <InfoDisplay icon=<LocalOfferIcon /> text={props.inf.piStatus} />
      </div>
  }
      <div style={{position:"absolute", left:"1em", bottom:"1em", width:"80%"}}>
        <InfoDisplay  icon=<ArrowUpwardIcon /> text={props.inf.onTop} />
        <InfoDisplay  icon=<SyncIcon /> text={props.inf.piUntil} />
        <InfoDisplay  icon=<AttachMoneyIcon /> text={props.inf.CurrencyDisp} />
      </div>
    </Grid>
  )}
  
  if (!interfaces.length) return null;
  
  var firstadd = false;
  return (
    <Card>
      <CardContent>
        <Grid container>
    {interfaces.map((inf, k)=>{
      if (!inf || !inf.kurz) return null;
      if (!firstadd && (inf.status==='notconfigured' || inf.status==='corrupt')) {
        firstadd = true;
        return (
          <React.Fragment key={k}>
            <Grid item xs={12} md={12} key={k}><h1>{translate('bw.if.list.titleAdditional')}</h1></Grid>
            <IfBox key={inf.kurz} inf={inf} />
          </React.Fragment>
        );
      }
       return(
          <IfBox key={inf.kurz} inf={inf} />
    )})}
        </Grid>
      </CardContent>
    </Card>
  )
};

export default IfList;
