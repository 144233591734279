import React, { useState } from 'react';
import { isHauptadmin, getInfoValue } from '../../lib/globalData';
import {  useRecordContext, List, Create, Edit, Datagrid, SimpleForm, TextField, 
        ReferenceField, SelectField, Filter, ReferenceManyField, DeleteButton, 
        EditButton, Button, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Link } from 'react-router-dom';
import { ColorInput } from 'react-admin-color-picker';
import AddIcon from '@mui/icons-material/Add';
import Move from '../../lib/Move';
import CloseToolbar from '../../lib/CloseToolbar';
import BooleanRedField from '../../fields/BooleanRedField';
import GenderField from '../../fields/GenderField';
import ColorField from '../../fields/ColorField';
import GenderInput from '../../fields/GenderInput';
import NoPagination from '../../lib/NoPagination';
import Stack from '@mui/material/Stack';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

/*
const useStyles = makeStyles({
  inline: {
    '& > div:nth-child(1) > div': {
      clear: 'left', 
      marginRight: 32, 
    },
    '& > div:nth-child(1) > div:nth-child(-n+3)': {
      float: 'left', 
      clear: 'none', 
    },
  }, 
});
      */
const AddBedButton = () => {
  const record = useRecordContext();
  return (
    <Button
      variant="contained"
      component={Link}
      to={`/setBed/create?id_zimmer=${record.id}`}
      label='bw.set.bed.titleCreate'
      title='bw.set.bed.title'
    >
      <AddIcon />
    </Button>
  );
}

const DeleteBedButton = ( props ) => {
  const record = useRecordContext();
  return (
    <DeleteButton redirect={"/setRoom/"+record.id_zimmer} mutationMode="pessimistic" {...props} />
  );
}

const onlinechoices=[
  { id:  0, name: 'glob.onlinechoices.not_online' }, 
  { id:  1, name: 'glob.onlinechoices.cons_book_woman' },
  { id:  2, name: 'glob.onlinechoices.cons_book_man' },
  { id:  3, name: 'glob.onlinechoices.cons_book_woman_man' },
  { id:  4, name: 'glob.onlinechoices.cons_book_mixed' },
  { id:  5, name: 'glob.onlinechoices.cons_book_woman_mixed' },
  { id:  6, name: 'glob.onlinechoices.cons_book_man_mixed' },
  { id:  7, name: 'glob.onlinechoices.cons_book_all' },
  { id:  8, name: 'glob.onlinechoices.ign_book_woman' },
  { id:  9, name: 'glob.onlinechoices.ign_book_man' },
  { id: 10, name: 'glob.onlinechoices.ign_book_mixed' },
];

const bedcount=[
  { id:  0, name: '0' }, 
  { id:  1, name: '1' },
  { id:  2, name: '2' },
  { id:  3, name: '3' },
  { id:  4, name: '4' },
  { id:  5, name: '5' },
  { id:  6, name: '6' },
  { id:  7, name: '7' },
  { id:  8, name: '8' },
  { id:  9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
];

const MatrazeAdmin = (props) => {
  const record = useRecordContext();
  const v = record && record[props.source];
  if (v===1) {
    return (<CheckIcon />)
  }
  if (v===2) {
    return (<DeleteIcon />)
  }
  return null;
}

const ListForm = (props) => {
  const [moveStart, setMoveStart] = useState(0);
  const getMoveStart = () => {
    return moveStart;
  }
  const { ...rest} = props;
  return (
    <List {...rest} title="bw.set.room.title" perPage={999} pagination={<NoPagination />} sort={{ field: 'pos', order: 'ASC' }} exporter={isHauptadmin() && props.exporter} >
      <Datagrid style={{ width: 'auto' }} bulkActionButtons={false}>
        <Move getMoveStart={getMoveStart} setMoveStart={setMoveStart} endpoint="setRoom" />
        <ColorField source="bezeichnung" sortable={false} />
        <ReferenceField source="id_preiskat" reference="setRoomcategory" sortable={false} link={false}>
          <TextField source="bezeichnung" />
        </ReferenceField>
        <GenderField source="geschlecht" sortable={false} />
        <TextField source="bett" sortable={false} />
        <TextField source="zusatz" sortable={false} />
        <SelectField source="online" choices={onlinechoices} sortable={false}/>
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" sortable={false} />
        }
        {isHauptadmin() &&
          <TextField source="pos" textAlign="right" />
        }
        <EditButton label="" />
      </Datagrid>
    </List>
  )
}

const EForm = ({ hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const classes = {} //useStyles();
  const docUrl = <a href={"https://docs.dormproject.ch/"+(getInfoValue('id_sprache')===1?"de":"en")+"/konfiguration---dormonline/zimmer/zimmer-online.html"} target="_blank" rel="noopener noreferrer" >Dokumentation</a>;
  return (
    <SimpleForm toolbar={<CloseToolbar />} className={classes.inline} {...props}> 
      <Stack direction="row" spacing={2}>
        <TextInput source="bezeichnung" />
        <ReferenceInput source="id_preiskat" reference="setRoomcategory" >
          <AutocompleteInput optionText="bezeichnung" />
        </ReferenceInput>
        <GenderInput source="geschlecht" />
      </Stack>
      <AutocompleteInput source="online" choices={onlinechoices} helperText={false} />
      <p style={{ marginTop:'0em', marginBottom: '1em', fontSize: 'smaller' }} >{docUrl}</p>
      <ColorInput source="farbe" disableAlpha={true} />
      <ReferenceManyField
        addLabel={false}
        reference="setBed"
        target="id_zimmer"
        sort={{ field: 'buchstabe', order: 'ASC' }}
        redirect="edit"
        fullWidth
      >
        <Datagrid style={{width: 'auto'}}>
          <TextField source="buchstabe" />
      {isHauptadmin()
      ?   <MatrazeAdmin source="matratze" />
      :   <BooleanRedField source="matratze" />
      }
          <TextField source="bemerkung" />
        {isHauptadmin() &&
          <TextField source="id" textAlign="right" sortable={false} />
        }
          <EditButton />
          <DeleteBedButton />
        </Datagrid>
      </ReferenceManyField>
      <AddBedButton />
    </SimpleForm>
  )
};

const CForm = ({ hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const docUrl = <a href={"https://docs.dormproject.ch/"+(getInfoValue('id_sprache')===1?"de":"en")+"/konfiguration---dormonline/zimmer/zimmer-online.html"} target="_blank" rel="noopener noreferrer" >Dokumentation</a>;
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <TextInput multiline source="bezeichnung" helperText='bw.set.room.bezeichnungInfo' />
      <ReferenceInput source="id_preiskat" reference="setRoomcategory">
        <AutocompleteInput optionText="bezeichnung" />
      </ReferenceInput>
      <GenderInput source="geschlecht" />
      <AutocompleteInput source="online" choices={onlinechoices} helperText={false} />
      <p style={{ marginTop:'0em', marginBottom: '1em', fontSize: 'smaller' }} >{docUrl}</p>
      <AutocompleteInput source="bett" label='bw.set.room.bedcount' choices={bedcount} />
      <ColorInput source="farbe" disableAlpha={true} />
    </SimpleForm>
  )
};

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="bezeichnung" alwaysOn />
    <ReferenceInput source="id_preiskat" reference="setRoomcategory" alwaysOn >
      <AutocompleteInput optionText="bezeichnung" />
    </ReferenceInput>
    <GenderInput source="geschlecht" />
    <TextInput source="bett" />
    <TextInput source="zusatz" />
    <AutocompleteInput source="online" choices={onlinechoices} />
  </Filter>
);

const Room = {
  list: ({ ...props }) => (
    <ListForm {...props} filters={<ListFilter />} />
  ),
  
  create: ({ ...props }) => (
    <Create {...props} title="bw.set.room.titleCreate" redirect="list">
      <CForm {...props} />
    </Create>
  ),
  
  edit: ({ ...props }) => (
    <Edit title="bw.set.room.titleEdit" mutationMode="pessimistic" {...props}>
      <EForm {...props} />
    </Edit>
  )
}   
export default Room