import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

const Profil = () => (
    <Card>
        <Title title="My Page" />
        <CardContent>
          Hallo
        </CardContent>
    </Card>
);

export default Profil;